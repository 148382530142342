import emailSent from '../../../assets/images/email-sent-arrow.svg';
import ROUTE_NAME from 'apps/sage/src/utils/url-constants';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

interface PWDREmailSentProps {
  onChangeStep: (data: string) => void;
  data: any; //todo
}
function PWDREmailSent({ onChangeStep, data }: PWDREmailSentProps) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <img src={emailSent} alt={'emailSent'} className="mx-auto py-5"></img>
      <p className="text-base font-semibold">
        Check your email for a link to reset your password
      </p>
      <p className="pt-2.5 text-sm font-medium">
        If the email address {location?.state?.data ?? ''} has an account, it will have
        received a link to reset the password.
      </p>
      <div className="font-nunito mt-7 text-sm">
        Didn't receive an email? &nbsp;
        <button
          className="text-primary-600 underline"
          onClick={() => navigate(-1)}
        >
          Send again
        </button>
      </div>
      {/* <div className="text-sm">
        If you need help, please go to our&nbsp;
        <NavLink
          to={ROUTE_NAME.SIGNUP}
          className="text-primary-600"
          reloadDocument
        >
          Help center
        </NavLink>
      </div> */}
    </>
  );
}

export default PWDREmailSent;
