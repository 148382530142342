import { BookOpenIcon } from '@heroicons/react/24/solid';
import {
  CardWrapperWithHeader,
  ButtonIcon,
  Heading,
  Button,
} from '@rabbit/elements/shared-components';
import ROUTE_NAME from '../../../utils/url-constants';

export default function LegalTab() {
  const buttons = [
    {
      kind: 'bgLightGreen' as const,
      label: 'Privacy Policy',
      onClick: () => window.open(`${ROUTE_NAME.PRIVACY_POLICY}`, '_blank'),
      Icon: BookOpenIcon,
    },
    {
      kind: 'bgLightGreen' as const,
      label: 'Terms & Conditions',
      onClick: () =>
        window.open(`${ROUTE_NAME.TERMS_AND_CONDITIONS}`, '_blank'),
      Icon: BookOpenIcon,
    },
    // {
    //   kind: 'bgLightGreen' as const,
    //   label: 'FAQs',
    //   onClick: () => navigate('/'),
    //   Icon: BookOpenIcon,
    // },
    // {
    //   kind: 'bgLightGreen' as const,
    //   label: 'Documentation',
    //   onClick: () => navigate('/'),
    //   Icon: BookOpenIcon,
    // },
  ];
  return (
    <>
      <div className="flex flex-col items-center gap-2">
        <Heading kind="h2">Legal</Heading>
        <p className="font-nunito text-lg text-gray-500">
          See privacy policy and terms & conditions
        </p>
      </div>
      <div className="mx-auto mt-8 lg:w-[860px]">
        <CardWrapperWithHeader title="Legal">
          <div className="flex gap-5">
            {buttons.map((button, i) => (
              <Button
                key={i}
                icon={<button.Icon />}
                kind="primary"
                onClick={button.onClick}
                className="!bg-primary-600 !hover:bg-primary-900 h-14"
              >
                {button.label}
              </Button>
            ))}
          </div>
        </CardWrapperWithHeader>
      </div>
    </>
  );
}
