import { Form, Formik } from 'formik';
import {
  Button,
  ButtonIcon,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  OptionShape,
  useGetRepairerPeers,
  useSendEmail,
} from '@rabbit/bizproc/react';
import { OUTCOME_OPTIONS } from 'apps/sage/src/utils/consts';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
//import PartsElement from '../../../molecules/PartsElement/PartsElement'; //OUTDATED
import { PartValuesShape } from 'apps/sage/types';
import { toast } from 'react-toastify';
import PartItem from '@rabbit/sage/components/molecules/PartItem/PartItem';
import { useAppInfo } from '@rabbit/sage/utils/helpers';

export interface LogRepairWorkModalProps {
  //repair_work_registry: RepairWorkRegistryProps[];
  //claim_outcome: string;
}
interface FormValuesShape {
  delegate_repairer_id: string;
  total_repairing_time: string;
  internal_comment: string;
  parts_used_for_repair: PartValuesShape[];
  claim_outcome: string;
}

const validationSchema = Yup.object().shape({
  delegate_repairer_id: Yup.string()
    .trim()
    .required('Please select a rapairer'),
  total_repairing_time: Yup.string()
    .trim()
    .required('Please enter a repair time'),
  parts_used_for_repair: Yup.array().required(
    'Please select parts used for repair'
  ),
  claim_outcome: Yup.string().trim().required('Please select an outcome'),
  internal_comment: Yup.string().trim(),
});

export function LogRepairWorkModal({}: LogRepairWorkModalProps) {
  const appInfo = useAppInfo();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ids, setIds] = useState<any[]>([]); // todo type
  const [partsValues, setPartsValues] = useState<PartValuesShape[]>([]);
  const { repairerPeer, repairerPeerLoading } = useGetRepairerPeers();
  const [showInternalComment, setShowInternalComment] = useState(false);
  const repairerUsers: OptionShape[] = repairerPeerLoading
    ? [
        {
          id: '-1',
          label: 'Loading...',
          value: 'Loading...',
        },
      ]
    : repairerPeer.map((repairer, index) => ({
        id: `${index}`,
        label: repairer?.name || '',
        value: repairer?.docid || '',
      }));
  const [usersForAssignment, setUsersForAssignment] = useState(repairerUsers);

  const {
    caseFacts,
    alterCaseFacts,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    operatingPersonaSingleKey,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !moveSpotlight ||
    !operatingPersona ||
    !operatingPersonaSingleKey ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const { repair_work_registry, claim_outcome } = caseFacts;

  const initialValues = {
    delegate_repairer_id: '',
    total_repairing_time: '',
    internal_comment: '',
    parts_used_for_repair: [],
    claim_outcome: '',
  };

  const handleAddElement = () => {
    const newId = uuidv4();
    //setIds((ids) => [...ids, newId]);
    setPartsValues((partsValues) => [
      ...partsValues,
      {
        id: newId,
        part_name: '',
        model_id: '',
        parts_quantity: '',
        parts_cost: {
          amount: '',
          currency: appInfo.currency,
        },
      },
    ]);
  };

  const handleDeleteElement = (removeId: string) =>
    setPartsValues((partsValues) =>
      partsValues.filter((part) => part.id !== removeId)
    );

  const compareValueWithId = (array: any[], value: string) => {
    const idToCompare = array.filter((item) => item.value === value);
    return idToCompare[0].label;
  };

  const onSubmit = async (values: FormValuesShape) => {
    const {
      delegate_repairer_id,
      total_repairing_time,
      claim_outcome,
      internal_comment,
    } = values;

    setIsSubmitting(true);

    const newArray = repair_work_registry
      ? JSON.parse(JSON.stringify(repair_work_registry))
      : [];
    newArray.push({
      delegate_repairer_id: values.delegate_repairer_id,
      delegate_repairer_name: compareValueWithId(
        usersForAssignment,
        values.delegate_repairer_id
      ),
      total_repairing_time: values.total_repairing_time,
      internal_comment: values.internal_comment,
      parts_used_for_repair: partsValues,
      timeStamp: Date.now(),
    });

    await moveSpotlight(operatingPersonaSingleKey);
    executeAction('log_repair_work');

    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
        delegate_repairer_id: delegate_repairer_id,
        delegate_repairer_name: compareValueWithId(
          usersForAssignment,
          delegate_repairer_id
        ),
        total_repairing_time: total_repairing_time,
        claim_outcome: claim_outcome,
        repair_work_registry: JSON.parse(JSON.stringify(newArray)),
      };

      console.log('factsToAlter', factsToAlter);
      if (Object.keys(factsToAlter).length > 0)
        await alterCaseFacts(factsToAlter);

      //TODO: mail

      setShowModal(false);
      toast.success('Claim updated successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  };

  useEffect(() => {
    if (usersForAssignment.length === 0 || usersForAssignment[0]?.id === '-1')
      setUsersForAssignment(repairerUsers);
  }, [repairerUsers]);

  // useEffect(() => {
  //   // Filter the partsValues array to only include the parts with ids that are in the ids array
  //   const filteredPartsValues = partsValues.filter((part) => ids.includes(part.id));
  //   setPartsValues(filteredPartsValues);
  // }, [ids]);

  // useEffect(() => {
  //   // Filter the partsValues array to only include the parts with ids that are in the ids array
  //   if(repair_work_registry && repair_work_registry[0]?.parts_used_for_repair){
  //     const filteredPartsValues = repair_work_registry[0].parts_used_for_repair; //.filter((part: any) => ids.includes(part.id));

  //     setPartsValues(filteredPartsValues);

  //     // const existingIds = [...ids];
  //     // for (let i = 0; i < repair_work_registry[0]?.parts_used_for_repair.length; i++) {
  //     //   const id = repair_work_registry[0]?.parts_used_for_repair[i].id;
  //     //   existingIds.push(id);
  //     // }
  //     // setIds(existingIds);
  //   }

  // }, [repair_work_registry]);

  const onUpdatePartItemValues = (values: PartValuesShape) => {
    const updatedPartsValues = partsValues.map((part) => {
      if (values.id === part.id) {
        return values;
      }
      return part;
    });
    setPartsValues(updatedPartsValues);
  };

  return (
    <Modal
      settings={{
        title: 'Log repair',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      isLoading={isSubmitting}
      kind="generic"
      className="max-h-[768px] w-full max-w-[1024px]"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, values }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <div className="flex gap-3">
              <Input
                type="select"
                label="Outcome*"
                name="claim_outcome"
                settings={{
                  options: OUTCOME_OPTIONS,
                  id: 'claim_outcome',
                  placeholder: 'Please select an option',
                  hint: '*required',
                }}
              />
            </div>
            <div className="mb-3 flex gap-3">
              <Input
                type="select"
                label="Repairer*"
                name="delegate_repairer_id"
                settings={{
                  options: usersForAssignment,
                  id: 'delegate_repairer_id',
                  placeholder: 'Please select an option',
                  hint: '*required',
                }}
              />
              <Input
                type="time"
                label="Log time spent repairing*"
                name="total_repairing_time"
                settings={{
                  id: 'total_repairing_time',
                  hint: '*required',
                  errors: errors.total_repairing_time,
                }}
              />
            </div>
            {partsValues.map((part) => (
              <PartItem
                key={part?.id ?? ''}
                id={part?.id ?? ''}
                handleDelete={() => handleDeleteElement(part.id)}
                // Todo review
                //@ts-ignore
                setPartsValues={setPartsValues}
                onChange={onUpdatePartItemValues}
                data={part}
              />
            ))}
            <div className="py-auto ml-0 mr-auto max-w-[223px]">
              <ButtonIcon
                iconLeft={false}
                label="Add part"
                onClick={handleAddElement}
              />
            </div>
            {!showInternalComment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setShowInternalComment(true)}
                >
                  Add internal comment
                </Button>
              </div>
            )}
            {showInternalComment && (
              <div className="mt-3">
                <Input
                  type="rich-text"
                  label="Internal comment"
                  name="internal_comment"
                  settings={{
                    id: 'internal_comment',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            <div className="mt-8 flex gap-8">
              <Button
                kind="primary"
                type="submit"
                //loading={isSubmitting}
              >
                Log repair work
              </Button>
              <Button
                kind="outline_red"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default LogRepairWorkModal;
