import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { MANDRILL_TEMPLATES, useSendEmail } from '@rabbit/bizproc/react';
import { getConsumerURL, useAppInfo } from 'apps/sage/src/utils/helpers';
import { toast } from 'react-toastify';

export interface ApproveCaseForRepairModalProps {}

interface FormValuesShape {
  internal_comment: string;
  comment_to_customer: string;
}

const initialValues = {
  internal_comment: '',
  comment_to_customer: '',
};

const validationSchema = Yup.object().shape({
  internal_comment: Yup.string().trim(),
  comment_to_customer: Yup.string().trim(),
});

export function ApproveCaseForRepairModal({}: ApproveCaseForRepairModalProps) {
  const appInfo = useAppInfo();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);

  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    operatingPersonaSingleKey,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersona ||
    !operatingPersonaSingleKey ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const { SE_Sage_ApprovedForRepair } = useSendEmail();

  const onSubmit = async (values: FormValuesShape) => {
    const { comment_to_customer, internal_comment } = values;

    setIsSubmitting(true);
    await moveSpotlight(operatingPersonaSingleKey);

    executeAction('approve_case_for_repair');

    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
        comment_to_customer: comment_to_customer,
      };
      const to = caseFacts?.consumer_email ?? '',
        from = appInfo.email_sender,
        template = MANDRILL_TEMPLATES.BODY_APPROVED_FOR_REPAIR,
        business_name = appInfo.name ?? '',
        claim_id = caseId,
        first_name = caseFacts?.consumer_name ?? '',
        product_name = caseFacts.consumer_holding_name ?? '',
        subject =
          'Your ' +
          business_name +
          ' claim has been approved for repair - ' +
          product_name,
        link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;

      if (Object.keys(factsToAlter).length > 0) {
        await alterCaseFacts(factsToAlter);
        await alterCasePublicEmail({
          context: 'approved_for_repair',
          to,
          from,
          subject,
          template,
          substitutions: {
            subject,
            business_name,
            claim_id,
            first_name,
            product_name,
            comment_to_customer,
            link_to_claim,
          },
        });
      }

      await SE_Sage_ApprovedForRepair(
        to,
        from,
        subject,
        appInfo.email_main_template,
        business_name,
        claim_id,
        first_name,
        product_name,
        comment_to_customer,
        link_to_claim
      );

      setShowModal(false);
      toast.success('Claim updated successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  };

  return (
    <Modal
      settings={{
        title: 'Approve case for repair',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      isLoading={isSubmitting}
      kind="generic"
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <Input
              type="rich-text"
              label="Comments to customer"
              name="comment_to_customer"
              settings={{
                id: 'comment_to_customer',
                placeholder: '',
              }}
            />
            {!showInternalComment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setShowInternalComment(true)}
                >
                  Add internal comment
                </Button>
              </div>
            )}
            {showInternalComment && (
              <div>
                <Input
                  type="rich-text"
                  label="Internal comment"
                  name="internal_comment"
                  settings={{
                    id: 'internal_comment',
                    placeholder: '',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            <div className="mt-8 flex gap-8">
              <Button kind="primary" type="submit" loading={isSubmitting}>
                Approve for repair
              </Button>
              <Button
                kind="outline_red"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ApproveCaseForRepairModal;
