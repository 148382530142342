import {
  ButtonIcon,
  CardWrapperWithHeader,
  FilterValueShape,
  FiltersModal,
  Table,
  chartProducts,
} from '@rabbit/elements/shared-components';
import { useContext, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { formatDataChart, getUserPermissions, titleCase } from '../utils/helpers';
import { DTHoldingProxy, VehicleInfo } from '@rabbit/data/types';
import { useGetHoldingList } from '@rabbit/bizproc/react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../context/UserContext';
import { WarrantyTableColumns } from '../utils/RegistrationTableColumns';
import { FunnelIcon } from '@heroicons/react/24/outline';
import ROUTE_NAME from '../utils/url-constants';
import { useNavigate } from 'react-router-dom';
import { Permissions } from '@rabbit/data/types';

export default function WarrantyOverviewView() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const tenantLink = t('tenantLink');
  const userPermissions = getUserPermissions(tenantLink, userContext);
  const navigate = useNavigate();
  const { accessToken, repairerPersonaId, retailerPersonaId } =
    userContext ?? ({} as any);
  // TODO: In the future it would be nice to get this directly from the context - DC
  const activePersonaIds = {
    repairer: repairerPersonaId,
    retailer: retailerPersonaId,
  };
  const [formatTableData, setFormatTableData] = useState<any[]>();

  //Set the filters default date range to this month -VP
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const beginDate = new Date(currentYear, currentMonth, 1);
  const endDate = today;
  const [filters, setFilters] = useState<FilterValueShape>(
    t('tenantLink') === 'WARRANTYIRELAND'
      ? {
          dateType: 'This month',
          beginDate: beginDate,
          endDate: endDate,
        }
      : {}
  );
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [chartStatusData, setChartStatusData] = useState<any[]>([]);
  const [chartTemplateData, setChartTemplateData] = useState<any[]>([]);

  const {
    holdingList,
    // getNextPage,
    //totalHoldingCount,
    isLoading,
  } = useGetHoldingList(
    //pageSize,
    t('tenantLink'),
    accessToken,
    activePersonaIds,
    filters
  );

  const onChangeFilters = (value: FilterValueShape) => {
    setFilters(value);
    setIsFilterModalOpen(false);
  };

  useEffect(() => {
    const expiredDate = new Date().setHours(-1); // at 23:00 last day
    const upcomingDate = Date.now() + 2 * 30.14 * 24 * 3600 * 1000;
    const warrantyFilters = (holding: DTHoldingProxy) => {
      return (
        holding.warranties.filter((warrantyInfo) => {
          if (!warrantyInfo?.endDate) return holding;
          const expires = warrantyInfo?.endDate < expiredDate;
          const upcoming =
            warrantyInfo?.endDate > Date.now() &&
            warrantyInfo?.endDate < upcomingDate;
          const status = upcoming
            ? 'upcoming'
            : expires
            ? 'expired'
            : warrantyInfo?.status;
          return filters.warrantyStatus
            ? filters.warrantyStatus?.indexOf(status) > -1
            : true;
        }).length > 0
      );
    };
    const formatTableData = (holdings: DTHoldingProxy[]): any[] => {
      const records: any[] = [];
      holdings.filter(warrantyFilters).forEach((holding, i) => {
        holding.warranties.forEach((warrantyInfo) => {
          const vehicleInfo = holding.self_registration?.srvInfo
            ?.productInfo as VehicleInfo;
          const duration =
            (warrantyInfo?.decider?.stipulated?.duration as any)?.label || '-';
          const claimLimit =
            (warrantyInfo?.decider?.stipulated?.claimLimit as any)?.label ||
            '-';
          const expires = warrantyInfo?.endDate
            ? warrantyInfo?.endDate < expiredDate
            : Date.now();
          const upcoming =
            warrantyInfo?.endDate &&
            warrantyInfo?.endDate > Date.now() &&
            warrantyInfo?.endDate < upcomingDate;
          records.push({
            warrantyId: warrantyInfo?.warrantyLink,
            customerName: holding.consumer_name || '-',
            registrationPlate: vehicleInfo?.registrationNo || '-',
            registered: warrantyInfo?.registeredTime
              ? warrantyInfo?.registeredTime
              : '-',
            warrantyPlan: warrantyInfo?.templateTitle || '-',
            claimLimit: claimLimit,
            start: warrantyInfo?.startDate,
            term: duration,
            expires: warrantyInfo?.endDate,
            status: upcoming
              ? 'upcoming'
              : expires
              ? 'expired'
              : warrantyInfo?.status,
            link: holding.docid,
          });
        });
      });
      return records;
    };

    const templateData = (holdings: DTHoldingProxy[]) => {
      const totalLabel: any = {};
      holdings.filter(warrantyFilters).forEach((holding) => {
        holding.warranties.forEach((warranty) => {
          totalLabel[warranty?.templateTitle || '-'] =
            (totalLabel[warranty?.templateTitle || '-'] || 0) + 1;
        });
      });
      return Object.keys(totalLabel).map((item, index) => ({
        id: index,
        count: totalLabel[item],
        label: titleCase(item),
      }));
    };

    setChartTemplateData(
      formatDataChart(
        templateData(holdingList?.data || []),
        'series',
        'Warranty plan',
        5
      )
    );

    const statusData = (holdings: DTHoldingProxy[]) => {
      const totalStatus: any = {};
      holdings.filter(warrantyFilters).forEach((holding, index) => {
        holding.warranties.forEach((warranty) => {
          const expiredDate = new Date().setHours(-1); // at 23:00 last day
          const upcomingDate = Date.now() + 2 * 30.14 * 24 * 3600 * 1000;
          const expires = warranty?.endDate && warranty?.endDate < expiredDate;
          const upcoming =
            warranty?.endDate &&
            warranty?.endDate > Date.now() &&
            warranty?.endDate < upcomingDate;
          const status = upcoming
            ? 'upcoming'
            : expires
            ? 'expired'
            : warranty?.status;
          if (status) totalStatus[status] = (totalStatus[status] || 0) + 1;
        });
      });
      return Object.keys(totalStatus).map((item, index) => ({
        id: index,
        count: totalStatus[item],
        label: titleCase(item),
      }));
    };

    setChartStatusData(
      formatDataChart(
        statusData(holdingList?.data || []),
        'series',
        'Warranty status',
        5
      )
    );

    setFormatTableData(formatTableData(holdingList?.data || []));
  }, [holdingList?.data, isLoading, filters]);


  //Check if user has permissions to view this page
  if (!userPermissions?.includes(Permissions.Owner)) navigate('/');

  return (
    <>
      <div className="mb-5 grid grid-cols-2 gap-5">
        <CardWrapperWithHeader
          title="Total warranties"
          headerRight={
            <div className="text-xl font-bold">
              Total: {formatTableData?.length || 0}
            </div>
          }
        >
          <Chart
            options={chartProducts.options}
            series={chartTemplateData}
            type="bar"
            height={'250px'}
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader
          title="Warranty status"
          headerRight={
            <div className="text-xl font-bold">
              Total: {formatTableData?.length || 0}
            </div>
          }
        >
          <Chart
            options={chartProducts.options}
            series={chartStatusData}
            type="bar"
            height={'250px'}
          />
        </CardWrapperWithHeader>
      </div>
      <div className="relative z-10 flex w-full items-center justify-between">
        <div className="absolute top-[25px] right-5">
          {!isLoading && (
            <ButtonIcon
              label={'Filters'}
              iconLeft={true}
              Icon={FunnelIcon}
              onClick={() => setIsFilterModalOpen(true)}
              kind={'bgLightGreen'}
              count={
                (filters && filters.beginDate) || (filters && filters.endDate)
                  ? 1
                  : 0
              }
            />
          )}
        </div>
        {isFilterModalOpen && (
          <div className="absolute right-0 top-16 z-10">
            <FiltersModal
              handleClose={() => setIsFilterModalOpen(false)}
              data={filters}
              onChange={onChangeFilters}
              option={{ showWarrantyStatus: true }}
            />
          </div>
        )}
      </div>
      <div className="relative z-0 w-full items-center justify-between py-4">
        <Table
          columns={WarrantyTableColumns}
          data={formatTableData || []}
          initialState={{
            showGlobalFilter: true,
          }}
          muiSearchTextFieldProps={{
            placeholder: 'Search by warranty ID or customer name',
          }}
          state={{
            isLoading,
          }}
          useCallbackClickRow={(data: any) => {
            navigate(ROUTE_NAME.WARRANTY_OVERVIEW + '/' + data.link, {
              state: {
                title: data.link,
                link: data.link,
              },
            });
          }}
        />
      </div>
    </>
  );
}
