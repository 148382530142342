import { useContext } from 'react';
import {
  CardWrapperWithHeader,
  Chat,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { CaseflowContext } from '../../../../context/CaseflowContext';
import { CaseFlowCaseEditingID } from '@rabbit/bizproc/react';
import { getRootPersonaFromLexicon } from '@rabbit/bizproc/client';
import { t } from 'i18next';
import {
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
  PrincipalsFieldName,
} from '@rabbit/data/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClaimChatLogProps {}

export function ClaimChatLog(props: ClaimChatLogProps) {
  const context = useContext(CaseflowContext);
  const tenantLink = t('tenantLink');
  const rootTenantRepairer = getRootPersonaFromLexicon(
    t(PrincipalsFieldName),
    PersonaTypeSingleLetter.Repairer
  );
  // @TEST_REMOVE_LATER, as the principals field name wasn't updated locally even after running mixmaster...
  const rootTenantWarrantor =
    PersonaTypeSingleLetter.Warrantor + PersonaIdTypeSplitter + tenantLink;
  // const rootTenantWarrantor = getRootPersonaFromLexicon(
  //   t(PrincipalsFieldName),
  //   PersonaTypeSingleLetter.Warrantor
  // );

  const {
    caseFlowCase,
    chatLog,
    caseId,
    operatingPersona,
    operatingPersonaSingleKey,
    sendChatMessage,
    getMyChatLastSeen,
    updateChatLastSeen,
  } = context || {};

  if (
    !rootTenantRepairer ||
    !context ||
    !caseFlowCase ||
    !chatLog ||
    !caseId ||
    !operatingPersona ||
    !sendChatMessage ||
    !getMyChatLastSeen ||
    !updateChatLastSeen
  )
    return <LoadingSpinner size="xs" />;

  const id: CaseFlowCaseEditingID = {
    type: 'cfc' as const,
    case: caseId,
    operating_persona: operatingPersona,
    principal_persona:
      tenantLink === 'SHELTA' ? rootTenantRepairer : rootTenantWarrantor,
    isNewCase: false,
  };

  // Yes this is awful - todo improve
  const currentPersonaType =
    operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer
      ? 'repairer'
      : operatingPersonaSingleKey === PersonaTypeSingleLetter.Warrantor
      ? 'warrantor'
      : null;

  const [lastMessage] = chatLog.slice(-1);
  const operatorTimeLastSeenChat = getMyChatLastSeen() ?? 0;

  const onSubmitMessage = async (message: string) => {
    if (currentPersonaType) sendChatMessage(currentPersonaType, message);
  };

  return (
    <CardWrapperWithHeader
      title="Chat log"
      extraStyles="!grow flex flex-col"
      noPadding
      hasUpdate={lastMessage?.t > operatorTimeLastSeenChat}
    >
      <Chat
        id={id}
        messageHistory={chatLog}
        activePersona={operatingPersona}
        onSubmitMessage={onSubmitMessage}
        updateChatLastSeen={() => {
          if (currentPersonaType) updateChatLastSeen(currentPersonaType);
        }}
      />
    </CardWrapperWithHeader>
  );
}

export default ClaimChatLog;
