import * as Yup from 'yup';
import {
  Button,
  formatUnixTime,
  getCurrencyFormat,
  Heading,
  Input,
} from '@rabbit/elements/shared-components';
import { getUnixTime } from 'date-fns';
import {
  formatDate,
  getVehicleCategoryByValue,
  titleCase,
  useAppInfo,
} from '@rabbit/sage/utils/helpers';
import { useTranslation } from 'react-i18next';
import { MileageUnit } from '@rabbit/data/types';
import { CarRegistrationShape } from './PWCreateNewRegistration';

export interface NewRegistrationDetailsFormProps {
  handleClose: () => void;
  onChange: any; //TODO
  data: CarRegistrationShape & {
    mileage: string;
    mileageUnit: string;
    body: string;
    regDate: string;
    colour: string;
    engineNo: string;
    purchasePrice: {
      amount: string;
      currency: string;
    };
  };
}

export function PWNewRegistrationDetailsForm(
  props: NewRegistrationDetailsFormProps
) {
  const { onChange, handleClose, data } = props;
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const defaultMileageUnit = t('CFG_COBRAND_MILEAGE_UNIT', 'km') as MileageUnit;

  const carRecord = [
    {
      label: 'Registration Plate',
      value: String(data.registrationNo).toUpperCase(),
    },
    { label: 'Make', value: data.make },
    { label: 'Model', value: data.model },
    { label: 'Version', value: data.version },
    { label: 'Body', value: data.body || '-' },
    { label: 'Registration date', value: data.regDate ? formatDate(new Date(data.regDate)) : '-' },
    { label: 'Engine cc', value: data.engineCc || '-' },
    { label: 'Colour', value: data.colour || '-' },
    { label: 'Fuel', value: data.fuel || '-' },
    { label: 'Transmission', value: data.transmission || '-' },
    { label: 'Drive type', value: data.driveType || '-' },
    { label: 'Year of manufacture', value: data.yearOfManufacture || '-' },
    { label: 'VIN', value: data.chassisNo || '-' },
    { label: 'Engine number', value: data.engineNo || '-' },
    {
      label: 'Current mileage',
      value: data.mileage
        ? Number(data.mileage).toLocaleString(appInfo.country) +
            data.mileageUnit || defaultMileageUnit
        : '-',
    },
    {
      label: 'Last service date',
      value: data.last_service_date
        ? formatUnixTime(
            getUnixTime(new Date(data.last_service_date)),
            'dd/MM/yyyy'
          )
        : '-',
    },
    {
      label: 'MOT date expiration',
      value: data.tech_check_date
        ? formatUnixTime(
            getUnixTime(new Date(data.tech_check_date)),
            'dd/MM/yyyy'
          )
        : '-',
    },
    {
      label: 'Commercial vehicle',
      value: data.commercialVehicle ? titleCase(data.commercialVehicle) : '-',
    },
    {
      label: 'Vehicle Category',
      value: data.vehicleCategory
        ? getVehicleCategoryByValue(data.vehicleCategory)?.label ?? '-'
        : '-',
    },
    {
      label: 'Purchase price',
      value: data.purchasePrice
        ? getCurrencyFormat(
            data.purchasePrice.amount,
            data.purchasePrice.currency
          )
        : '-',
    },
  ];

  return (
    <div className="p-4">
      <div className="mb-4">
        <Heading kind="h4" className="mb-2">
          Selected car
        </Heading>
        <div className="font-nunito rounded-md border border-gray-300 p-4">
          <div className="font-bold text-black">
            {data.make} {data.model} {data.version}
          </div>
          <div className="mb-6 text-black">{data.registrationNo}</div>
          <div className="grid grid-cols-3 gap-4">
            {carRecord.map((record, index) => (
              <div key={'record' + index} className="flex flex-col">
                <span className="font-sm text-gray-500">{record.label}</span>
                <div className="font-medium text-black">{record.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-5 flex w-full gap-4">
        <Button kind="primary" type="submit" onClick={() => onChange(3, data)}>
          Continue
        </Button>
        <Button kind="red" type="submit" onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default PWNewRegistrationDetailsForm;
