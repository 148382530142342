import { Modal } from '@rabbit/elements/shared-components';
import { Dispatch, SetStateAction, useState } from 'react';
import { ModalAddEditFault } from './ModalTypes/ModalAddEditFault';
import { ModalAddEditFaultCategory } from './ModalTypes/ModalAddEditFaultCategory';
import { ModalAddEditIssueType } from './ModalTypes/ModalAddEditIssueType';
import { ModalAddEditPart } from './ModalTypes/ModalAddEditPart';
import { ModalAddEditVendable } from './ModalTypes/ModalAddEditVendable';

export interface ModalAddEditItem {
  handleClose: () => void;
  handleDelete?: () => void;
  // This actually probably doesn't need to exist, review - dc
  handleSubmit?: () => void;
  itemDetails?: any; // todo: type this
  kind?: 'products' | 'parts' | 'faults' | 'faults categories' | 'issueTypes'; // todo create enum for this
  title?: string;
  mode?: 'edit' | 'new';
  itemId?: string;
}

function renderChildren({
  handleClose,
  handleDelete,
  handleSubmit,
  setLoading,
  itemDetails,
  kind,
  mode,
  itemId,
}: ModalAddEditItem & { setLoading: Dispatch<SetStateAction<boolean>> }) {
  if (kind === 'products')
    return (
      <ModalAddEditVendable
        handleClose={handleClose}
        handleDelete={handleDelete}
        vendableId={itemDetails?.docid}
        setLoading={setLoading}
        // handleSubmit={() => void 0}
      />
    );

  if (kind === 'parts')
    return (
      <ModalAddEditPart
        handleClose={handleClose}
        itemDetails={itemDetails && itemDetails}
        handleDelete={handleDelete}
        setLoading={setLoading}
        // handleSubmit={() => void 0}
      />
    );

  if (kind === 'faults') {
    return (
      <ModalAddEditFault
        handleDelete={handleDelete}
        handleClose={handleClose}
        // handleSubmit={handleSubmit}
        itemDetails={itemDetails && itemDetails}
        mode={mode || 'new'}
        setLoading={setLoading}
      />
    );
  }

  if (kind === 'faults categories')
    return (
      <ModalAddEditFaultCategory
        handleClose={handleClose}
        itemDetails={itemDetails && itemDetails}
        mode={mode || 'new'}
        setLoading={setLoading}
        // handleSubmit={() => void 0}
      />
    );

  if (kind === 'issueTypes') {
    return (
      <ModalAddEditIssueType
        handleDelete={handleDelete}
        handleClose={handleClose}
        // handleSubmit={handleSubmit}
        itemDetails={itemDetails && itemDetails}
        mode={mode || 'new'}
        setLoading={setLoading}
      />
    );
  }

  return null;
}

export function ModalAddEditItem({
  handleClose,
  handleDelete,
  handleSubmit,
  itemDetails,
  kind,
  title,
  mode,
}: ModalAddEditItem) {
  const modalSettings = {
    title: title,
    handleClose: () => handleClose(),
  };
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <Modal
        className="h-fit rounded-md bg-white lg:w-[724px]"
        kind="generic"
        children={renderChildren({
          handleClose,
          handleDelete,
          itemDetails,
          kind,
          mode,
          handleSubmit,
          setLoading,
        })}
        settings={modalSettings}
        isLoading={loading}
      />
    </div>
  );
}

export default ModalAddEditItem;
