import { Form, Formik, FormikProps } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import { ReactElement, useContext, useRef, useState } from 'react';
import {
  CLAIM_COST_OPTIONS,
  LIST_CURRENCIES,
  OptionShape,
  useGetRepairerPeers,
} from '@rabbit/bizproc/react';
import * as Yup from 'yup';
import { SelectOptionShape } from '@rabbit/elements/shared-types';
import { useAppInfo } from '@rabbit/sage/utils/helpers';
import {
  CaseflowContext,
  CaseflowInterface,
} from '@rabbit/sage/context/CaseflowContext';
import PartUsedForm from './forms/PartUsedForm';
import AdministrativeTimeForm from './forms/AdministrativeTimeForm';
import RepairTimeForm from './forms/RepairTimeForm';
import ShippingForm from './forms/ShippingForm';
import TravelForm from './forms/TravelForm';
import OtherForm from './forms/OtherForm';
import { useGetMySagePersonas } from '@rabbit/data/portal';

export const inputTypeCurrencyOverrideOptions: Array<SelectOptionShape> =
  LIST_CURRENCIES.map((currency, index) => ({
    id: `${index}`,
    label: currency.code + '/h',
    value: currency.code,
  }));

export interface LogForms<T> {
  schema: Yup.AnySchema;
  initialValues: Record<string, any>;
  onDelete: (index: number, onComplete: () => void) => void;
  onSubmit: (
    values: T & {
      internal_comment: string;
    },
    onComplete: () => void
  ) => void;
  Form: (args: FormikProps<Record<string, any>>) => ReactElement<any, any>;
  name:
    | 'administrative-time'
    | 'parts-used'
    | 'repair-time'
    | 'shipping'
    | 'travel'
    | 'other';
}

export type AlterCaseFacts = (facts: { [key: string]: any }) => Promise<void>;

export default function ClaimCostModal({
  handleClose,
}: {
  handleClose: () => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedForm, setSelectedForm] = useState<LogForms<any>>();
  const [showInternalComment, setShowInternalComment] = useState(false);
  const appInfo = useAppInfo();
  const caseFlow = useContext(CaseflowContext) || ({} as CaseflowInterface);
  const { repairerPeer, repairerPeerLoading } = useGetRepairerPeers();
  const { warrantorPersona } = useGetMySagePersonas();
  const formikRef = useRef<FormikProps<any>>(null);
  const costSelectRef = useRef<any>(null);

  if (!warrantorPersona?.personaId) return <LoadingSpinner size="md" />;

  const repairerUsers: OptionShape[] = repairerPeerLoading
    ? [
        {
          id: '-1',
          label: 'Loading...',
          value: 'Loading...',
        },
      ]
    : repairerPeer.map((repairer, index) => ({
        id: `${index}`,
        label: repairer?.name || '',
        value: repairer?.docid || '',
      }));

  const handleFormSelected = (selected: SelectOptionShape) => {
    const { caseFacts, alterCaseFacts } = caseFlow;
    if (!caseFacts || !alterCaseFacts) return;
    const valueMap: Record<string, LogForms<any>> = {
      'administrative-time': AdministrativeTimeForm(
        appInfo,
        caseFlow,
        repairerUsers,
        warrantorPersona.personaId
      ),
      'parts-used': PartUsedForm(appInfo, caseFlow, warrantorPersona.personaId),
      'repair-time': RepairTimeForm(
        appInfo,
        caseFlow,
        repairerUsers,
        warrantorPersona.personaId
      ),
      shipping: ShippingForm(appInfo, caseFlow, warrantorPersona.personaId),
      travel: TravelForm(appInfo, caseFlow, warrantorPersona.personaId),
      other: OtherForm(appInfo, caseFlow, warrantorPersona.personaId),
    };
    setSelectedForm(valueMap[selected.value as string]);
  };

  const onSubmit = (values: any) => {
    if (!selectedForm) return;
    setIsSubmitting(true);
    selectedForm.onSubmit(values, () => {
      setIsSubmitting(false);
      if (values.another) {
        setSelectedForm(undefined);
        costSelectRef.current?.setValue(undefined);
      } else handleClose();
    });
  };

  return (
    <Modal
      settings={{
        title: 'Log claim cost',
        handleClose,
      }}
      className="w-full max-w-[1024px]"
      kind="generic"
      isLoading={isSubmitting}
    >
      <Formik
        initialValues={selectedForm?.initialValues || {}}
        onSubmit={onSubmit}
        validationSchema={selectedForm?.schema}
        enableReinitialize
        innerRef={formikRef}
      >
        {(props) => {
          const { errors, values } = props;
          return (
            <Form className="flex flex-col px-4 mt-5 gap-3">
              <Input
                type="select"
                name="type_of_cost"
                label="Type of cost"
                settings={{
                  options: CLAIM_COST_OPTIONS,
                  id: 'type_of_cost',
                  onChange: (value: any) => value && handleFormSelected(value),
                  placeholder: 'Please select an option',
                  ref: costSelectRef,
                }}
              />
              {selectedForm && (
                <div className="flex flex-col p-4 border border-gray-300 rounded-lg gap-4">
                  {
                    // Selected form is displayed here
                    // Depending on the state change
                  }
                  {<selectedForm.Form {...props} />}

                  {!showInternalComment && (
                    <div className="mt-4">
                      <Button
                        kind="outline"
                        type="button"
                        className="w-full"
                        onClick={() => setShowInternalComment(true)}
                      >
                        Add internal comment
                      </Button>
                    </div>
                  )}
                  {showInternalComment && (
                    <div className="mt-3">
                      <Input
                        type="rich-text"
                        label="Internal comment"
                        name="internal_comment"
                        settings={{
                          id: 'internal_comment',
                          allowSpecialCharacter: true,
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="mt-4 grid grid-cols-3 gap-3">
                <div className="flex items-center">
                  <Input
                    type="checkbox"
                    name="another"
                    settings={{ checkboxLabel: 'Log another' }}
                  />
                </div>
                <Button
                  kind="primary"
                  type="submit"
                  disabled={
                    isSubmitting ||
                    !selectedForm ||
                    Object.keys(errors).length > 0
                  }
                >
                  Log cost
                </Button>
                <Button
                  kind="outline_red"
                  type="submit"
                  onClick={handleClose}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
