import {
  CardWrapperWithHeader,
  formatUnixTime,
  getClaimLimit,
  getCurrencyFormat,
  getIncreasedLabourRate,
  getWarrantyTerm,
  isWarrantyExpired,
} from '@rabbit/elements/shared-components';
import { DTHoldingProxy, WarrantyStatus } from '@rabbit/data/types';
import {
  formatDate,
  renderWarrantyRemaining,
  useAppInfo,
} from '@rabbit/sage/utils/helpers';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { BL_Warranty } from '@rabbit/bizproc/core';

interface RegistrationsHoldingSummarySectionProps {
  allHoldingsList?: DTHoldingProxy[];
  holding_proxy?: DTHoldingProxy;
  holdingId: string;
}

export default function CarRegistrationsWarrantyDetailsSection({
  allHoldingsList,
  holding_proxy,
  holdingId,
}: RegistrationsHoldingSummarySectionProps) {
  const { t } = useTranslation();
  const appInfo = useAppInfo();

  const warrantyInfo = BL_Warranty.getLatestWarranty(holding_proxy?.warranties);

  const labourRateValue = getIncreasedLabourRate(
    warrantyInfo,
    appInfo.currency
  );

  const showVAT = t('CFG_COBRAND_REGISTRATIONS_SHOW_VAT') === "true";
  const CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT = t('CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT') === "true";

  return (
    <CardWrapperWithHeader title={'Warranty details'}>
      {!allHoldingsList || !holdingId || !holding_proxy ? (
        <div>
          <div className="font-nunito mb-[30px] grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={4} />
            </div>
          </div>
        </div>
      ) : (
        <div className="font-nunito mb-[30px] grid grid-cols-6 gap-8">
          {renderData(
            'ID',
            warrantyInfo?.warrantyLink?.slice(0, 5).toUpperCase()
          )}
          {renderData(
            'Warranty plan',
            warrantyInfo?.templateTitle ? warrantyInfo?.templateTitle : '-'
          )}
          {holding_proxy.purchase_time
            ? renderData(
                'Registration date',
                holding_proxy.purchase_time > 0
                  ? formatUnixTime(holding_proxy.purchase_time, 'dd/MM/yyyy')
                  : '-'
              )
            : null}
          {renderData('Warranty term', getWarrantyTerm(holding_proxy) || '-')}
          {renderData(
            'Start date',
            warrantyInfo?.startDate
              ? formatDate(new Date(warrantyInfo.startDate))
              : '-'
          )}
          {renderData(
            'Expiry date',
            warrantyInfo?.status === WarrantyStatus.VOIDED
              ? 'Voided'
              : warrantyInfo?.endDate
              ? formatDate(new Date(warrantyInfo.endDate))
              : '-'
          )}
          {renderData(
            'Warranty remaining',
            holding_proxy ? renderWarrantyRemaining(holding_proxy, false) : '-'
          )}
          {renderData(
            'Warranty expired',
            warrantyInfo?.endDate
              ? isWarrantyExpired(warrantyInfo?.endDate)
              : '-'
          )}
          {renderData(
            'Claim limit',
            warrantyInfo
              ? getClaimLimit(warrantyInfo, appInfo.currency, CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT)
              : '-'
          )}
          {renderData('Extended warranty', 'Yes')}
          {t('tenantLink') !== 'WARRANTYIRELAND' &&
            Array.isArray(labourRateValue) &&
            labourRateValue.length > 0 &&
            renderData(
              'Labour rate',
              getIncreasedLabourRate(warrantyInfo, appInfo.currency)
            )}
          {renderData('Partner', warrantyInfo?.retailerName || '-')}
          {renderData('Salesperson', warrantyInfo?.salesRep || '-')}
          {t('tenantConfigType') === 'vehicle' &&
            renderData(
              'Price',
              warrantyInfo?.price?.amount
                ? getCurrencyFormat(
                    warrantyInfo?.price?.amount,
                    warrantyInfo?.price?.currency
                  ) + (showVAT ?  
                    ` (${getCurrencyFormat(
                      warrantyInfo?.price?.amount * 1.2,
                      warrantyInfo?.price?.currency
                    )} incl. VAT)` : '')
                : '-'
            )}
          {/* // Price has been temporarily removed per DEV-1128 request -VP */}
          {/* {t('tenantLink') !== 'PINNACLEWARRANTIES' &&
            renderData(
              'Price',
              warrantyInfo?.price?.amount
                ? getCurrencyFormat(
                    warrantyInfo?.price.amount,
                    warrantyInfo?.price.currency
                  )
                : '-'
            )} */}
        </div>
      )}
    </CardWrapperWithHeader>
  );
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

/* ----------------------------- Render helpers ----------------------------- */

const renderData = (label: string, value: any) => {
  let renderValue = value;
  if (value === 'undefined') renderValue = '-';
  else if (value && value !== 'undefined' && label === 'Phone number')
    renderValue = `+${value}`;
  return (
    <div>
      <div className="mb-[10px] text-sm text-gray-500">{label}</div>
      <div className="overflow-hidden text-ellipsis font-medium">
        {renderValue ?? '-'}{' '}
      </div>
    </div>
  );
};
