import { CFC_GetVechicleInfo } from '@rabbit/bizproc/core';
import { VehicleInfo } from '@rabbit/data/types';
import {
  APICallParamsBase,
  APICallResultBase,
  GetVehicleInfoParams, 
} from '@rabbit/elements/shared-types';
 
export async function Holding_GetVehicleInfo({
  requestId,
  apiVersion,
  registrationNo,
  tenant
}: GetVehicleInfoParams & APICallParamsBase): Promise<
  {data: VehicleInfo} & APICallResultBase  
> {
  const result = await CFC_GetVechicleInfo.call({
    requestId,
    apiVersion,
    registrationNo,
    tenant,
  });
  if (!result.ok) throw new Error(result.error);
  return result as {data: VehicleInfo} & APICallResultBase;
}
