import { useContext, useEffect, useState } from 'react';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  formatUnixTime,
  getCurrencyFormat,
  Modal,
  ModalSettingsShape,
} from '@rabbit/elements/shared-components';
import {
  ArrowTopRightOnSquareIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import {
  CLAIM_STATUS_OPTIONS,
  FileStorageContext,
  useConsumerHoldingEditor,
} from '@rabbit/bizproc/react';
import ModalEditClaimDetails from '../../../organisms/ModalEditClaimDetails/ModalEditClaimDetails';
import { CaseflowContext } from '../../../../context/CaseflowContext';
import {
  calculateTotalValue,
  getFaultLabels,
  multiply,
  stripTags,
  useAppInfo,
} from '../../../../utils/helpers';
import Skeleton from 'react-loading-skeleton';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { OurDateTime } from '@rabbit/utils/ts';
import { useTranslation } from 'react-i18next';
import ClaimPoPAndEvidenceUpload from '@rabbit/sage/components/organisms/ClaimPoPAndEvidenceUpload/ClaimPoPAndEvidenceUpload';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClaimDetailsSectionProps {}

export function ClaimDetailsSection(props: ClaimDetailsSectionProps) {
  const context = useContext(CaseflowContext);
  const {
    caseFacts,
    caseFlowCase,
    holdingData,
    holdingProxyData,
    caseActors,
    caseId,
    caseTimeCreated,
    caseTimeUpdated,
  } = context || {};
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const isShopifyTenant = t('CFG_SHOPIFY_URL');
  const claimsFlowLite = t('CFG_CLAIMS_FLOW') === 'lite';
  const latestWarranty = BL_Warranty.getLatestWarranty(
    holdingProxyData?.warranties
  );

  const { moveCompletedUploadsToAttached } =
    useContext(FileStorageContext) || {};

  const [readMore, setReadMore] = useState(false);
  const [claimFilesModal, setClaimFilesModal] = useState(false);
  const [partsReplacedModal, setPartsReplacedModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [faultLabels, setFaultLabels] = useState<string[]>([]);

  const { body } = useConsumerHoldingEditor(
    caseFacts?.consumer_persona_id || '',
    caseFacts?.consumer_holding || ''
  );

  useEffect(() => {
    const { holding_faults } = caseFacts || {};
    if (holding_faults && holding_faults?.length > 0) {
      (async () => {
        const res = await getFaultLabels(holding_faults);
        if (res) setFaultLabels(res);
      })().catch((err) => console.log(err));
    }
  }, [caseFacts?.holding_faults]);

  // if (
  //   !context ||
  //   !caseFacts ||
  //   !caseFlowCase ||
  //   !holdingData ||
  //   !caseActors ||
  //   !caseId ||
  //   !caseTimeCreated ||
  //   !caseTimeUpdated
  // )
  //   return <LoadingSpinner size="xs" />;

  const description = caseFacts?.consumer_issue_description
    ? caseFacts.consumer_issue_description
    : '';

  const modalSettingsClaimFiles: ModalSettingsShape = {
    title: 'Proof of purchase / supporting materials',
    handleClose: () => setClaimFilesModal(false),
  };

  const modalSettingsPartsReplaced: ModalSettingsShape = {
    title: 'Parts replaced',
    handleClose: () => setPartsReplacedModal(false),
  };

  const handleOpenProofOfPurchaseModal = () => {
    setClaimFilesModal(!claimFilesModal);
  };
  // const handleOpenPartsModal = () => {
  //   setPartsReplacedModal(!partsReplacedModal);
  // };

  const renderData = (label: string, value: any, key: string) => {
    return (
      <div key={key}>
        <p className="mb-[10px] text-sm text-gray-500">{label}</p>
        <div className="font-medium break-words">{value}</div>
      </div>
    );
  };

  const renderCaseFiles = () => {
    let totalFiles = caseFacts?.consumer_proof_of_purchase?.length ?? 0;
    totalFiles += caseFacts?.consumer_claim_evidence?.length ?? 0;
    return (
      <div
        className={
          'flex cursor-pointer items-center gap-2 text-sm ' +
          (totalFiles === 0 ? 'text-red-500' : 'text-black')
        }
        onClick={handleOpenProofOfPurchaseModal}
      >
        {totalFiles} file(s) uploaded{' '}
        <div>
          <ArrowTopRightOnSquareIcon
            className={
              'h-4 w-4' + (totalFiles === 0 ? 'text-red-500' : 'text-black')
            }
          />
        </div>
      </div>
    );
  };
  const renderShopifyOrderLink = () => {
    const orderUrl = `https://shopify.com/${holdingProxyData?.shopifyLinks?.shopId}/account/orders/${holdingProxyData?.shopifyLinks?.orderId}`;

    return (
      <a
        className="flex items-center text-black cursor-pointer"
        href={orderUrl}
        target="_blank"
        rel="noreferrer"
      >
        View
        <div>
          <ArrowTopRightOnSquareIcon className="w-5 h-5 pl-1 text-black" />
        </div>
      </a>
    );
  };

  const isWithinWarranty = () => {
    if (!holdingProxyData) return '-';

    if (!latestWarranty || !latestWarranty.endDate) return '-';

    return OurDateTime.nowUTCTimestamp() < latestWarranty?.endDate
      ? 'Yes'
      : 'No';
  };

  const getTotalValueOfParts = () => {
    let subtotalSum = 0;

    caseFacts?.parts_to_replace?.forEach((part) => {
      // Todo: review if Number necessary
      const subtotal = multiply(
        Number(part?.parts_quantity),
        Number(part?.parts_cost?.amount)
      );

      subtotalSum += subtotal;
    });

    return subtotalSum;
  };

  // todo: make this a single array with support for an item occupying multiple columns
  const FieldsToRender_Line1 = claimsFlowLite
    ? [
        {
          label: 'Claim status',
          value:
            CLAIM_STATUS_OPTIONS.find(
              (i) => i.value === caseFlowCase?.GetCaseState()
            )?.label || '-',
        },
        {
          label: 'Date of claim',
          value: caseTimeCreated
            ? formatUnixTime(Number(caseTimeCreated), 'dd/MM/yyyy')
            : '-',
        },
        {
          label: 'Purchase date',
          value:
            holdingData?.purchase_time && holdingData?.purchase_time > 0
              ? formatUnixTime(holdingData?.purchase_time, 'dd/MM/yyyy')
              : '-',
        },
        {
          label: 'Customer issue type',
          value: caseFacts?.consumer_issue_type,
        },
      ]
    : [
        {
          label: 'Date of claim',
          value: caseTimeCreated
            ? formatUnixTime(Number(caseTimeCreated), 'dd/MM/yyyy')
            : '-',
        },
        {
          label: 'Purchase date',
          value:
            holdingData?.purchase_time && holdingData?.purchase_time > 0
              ? formatUnixTime(holdingData?.purchase_time, 'dd/MM/yyyy')
              : '-',
        },
        {
          label: 'Warranty term',
          value: holdingData?.warranty_term?.amount
            ? `${holdingData?.warranty_term?.amount} ${holdingData?.warranty_term?.division}`
            : '-',
        },
        {
          label: 'Warranty expiry',
          value:
            latestWarranty?.endDate && latestWarranty?.endDate > 0
              ? formatUnixTime(latestWarranty?.endDate, 'dd/MM/yyyy')
              : '-',
        },
        {
          label: 'Within warranty period',
          value: isWithinWarranty(),
        },
      ];

  const adminTime: [number, number] = [0, 0];
  caseFacts?.administrative_cost_data?.forEach((i) => {
    const split = i.time_spent.split(':');
    adminTime[0] += Number(split[0]);
    adminTime[1] += Number(split[1]);
  });
  adminTime[0] += Math.floor(adminTime[1] / 60);
  adminTime[1] = adminTime[1] % 60;
  const adminTimeValue = `${adminTime[0]}:${('0' + adminTime[1]).slice(-2)} h`;

  const administrativeCost = caseFacts?.administrative_cost_data?.reduce(
    (a, b) => a + calculateTotalValue(b.time_spent, b.rate.amount),
    0
  );

  const repairTime: [number, number] = [0, 0];
  caseFacts?.repair_time_data?.forEach((i) => {
    const split = i.time_spent.split(':');
    repairTime[0] += Number(split[0]);
    repairTime[1] += Number(split[1]);
  });
  repairTime[0] += Math.floor(repairTime[1] / 60);
  repairTime[1] = repairTime[1] % 60;
  const repairTimeValue = `${repairTime[0]}:${('0' + repairTime[1]).slice(
    -2
  )} h`;

  const repairCost = caseFacts?.repair_time_data?.reduce(
    (a, b) => a + calculateTotalValue(b.time_spent, b.rate.amount),
    0
  );

  const totalPartsUsed = caseFacts?.parts_used_cost_data?.reduce((a, b) => {
    return a + Number(b.quantity);
  }, 0);

  const partUsedCost = caseFacts?.parts_used_cost_data?.reduce((a, b) => {
    return a + b.cost.amount;
  }, 0);

  const FieldsToRender_Line3 = claimsFlowLite
    ? [
        {
          label: 'Fault types',
          value: faultLabels.length > 0 ? faultLabels.join(', ') : '-',
        },
        {
          label: 'Warranty assessment',
          value:
            caseFacts?.preliminary_assessment ||
            caseFacts?.final_assessment ||
            '-',
        },
        {
          label: 'Goodwill',
          value:
            caseFacts?.goodwill_warranty_initial ||
            caseFacts?.goodwill_warranty_final ||
            '-',
        },
        {
          label: 'Proof of purchase / Supporting materials',
          value: renderCaseFiles(),
        },
        // {
        //   label: 'Administrative time spent',
        //   value: caseFacts?.administrative_cost_data ? adminTimeValue : '-',
        // },
        // {
        //   label: 'Administrative time cost',
        //   value: caseFacts?.administrative_cost_data
        //     ? getCurrencyFormat(
        //         administrativeCost || 0,
        //         caseFacts?.administrative_cost_data?.[0]?.rate.currency ||
        //           appInfo.currency
        //       )
        //     : '-',
        // },
        // {
        //   label: 'Repair time spent',
        //   value: caseFacts?.repair_time_data ? repairTimeValue : '-',
        // },
        // {
        //   label: 'Repair cost',
        //   value: caseFacts?.repair_time_data
        //     ? getCurrencyFormat(
        //         repairCost || 0,
        //         caseFacts?.repair_time_data?.[0]?.rate.currency ||
        //           appInfo.currency
        //       )
        //     : '-',
        // },
        // // {
        // //   label: 'Authorised repairer cost',
        // //   value: '200',
        // // },
        // {
        //   label: 'Parts used cost',
        //   value: caseFacts?.parts_used_cost_data
        //     ? getCurrencyFormat(
        //         partUsedCost || 0,
        //         caseFacts?.parts_used_cost_data?.[0]?.cost.currency ||
        //           appInfo.currency
        //       )
        //     : '-',
        // },
        // {
        //   label: 'Parts used',
        //   value: caseFacts?.parts_used_cost_data
        //     ? `${totalPartsUsed} parts used`
        //     : '-',
        // },
      ]
    : [
        {
          label: 'Customer issue type',
          value: caseFacts?.consumer_issue_type,
        },
        {
          label: 'Fault types',
          value: faultLabels.length > 0 ? faultLabels.join(', ') : '-',
        },
        {
          label: 'Preliminary assessment',
          value: caseFacts?.preliminary_assessment,
        },
        {
          label: 'Preliminary goodwill',
          value: caseFacts?.goodwill_warranty_initial
            ? caseFacts.goodwill_warranty_initial
            : '-',
        },
        {
          label: 'Proof of purchase / Supporting materials',
          value: renderCaseFiles(),
        },
      ];

  if (isShopifyTenant) {
    FieldsToRender_Line3.push({
      label: 'Shopify order',
      value: renderShopifyOrderLink(),
    });
  }
  const FieldsToRender_Line4 = claimsFlowLite
    ? []
    : [
        {
          label: 'Claim ID',
          value: caseId ?? '-',
        },
        {
          label: 'Serial number',
          value: caseFacts?.consumer_holding_serial_number
            ? caseFacts?.consumer_holding_serial_number
            : '-',
        },
        {
          label: 'Final assessment',
          value: caseFacts?.final_assessment,
        },
        {
          label: 'Final goodwill',
          value: caseFacts?.goodwill_warranty_final ?? '-',
        },
      ];

  return (
    <CardWrapperWithHeader
      title="Claim details"
      headerRight={
        <ButtonIcon
          type="primary"
          label={`Update claim details`}
          Icon={PencilIcon}
          iconLeft
          onClick={() => setShowModal(true)}
        />
      }
    >
      {!context ||
      !caseFacts ||
      !caseFlowCase ||
      !holdingData ||
      !caseActors ||
      !caseId ||
      !caseTimeCreated ||
      !caseTimeUpdated ? (
        <div className="font-nunito mb-[30px] grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
          <Skeleton count={3} className="mb-10" />
          <Skeleton count={3} className="mb-10" />
          <Skeleton count={3} className="mb-10" />
          <Skeleton count={3} className="mb-10" />
          <Skeleton count={2} className="mb-10" />
        </div>
      ) : (
        <div>
          {/* TODO: UPDATE DATE ON CLAIM DETAILS AND HOLDING SUMMARY - SHOULD UPDATE IN THE SAME PLACE AS ModalEditClaimDetails IS USING CLAIM DETAILS */}
          <div
            className={`font-nunito mb-[30px] grid grid-cols-2 gap-8 ${
              claimsFlowLite
                ? 'md:grid-cols-4 lg:grid-cols-4'
                : 'md:grid-cols-5 lg:grid-cols-5'
            }`}
          >
            {FieldsToRender_Line1.map((field) =>
              renderData(field.label, field.value ?? '-', field.label)
            )}
          </div>
          {description.length > 0 && (
            <div className="mb-[30px]">
              <div className="font-nunito">
                <p className="mb-[10px] text-sm text-gray-500">
                  Customer description of problem
                </p>
                <div>
                  <p className="font-medium">
                    {stripTags(
                      description.length > 110
                        ? readMore && description.length > 110
                          ? description
                          : `${description.substring(0, 110)}...`
                        : description
                    )}
                    {description.length > 100 ? (
                      <span
                        className="ml-2 font-medium cursor-pointer font-nunito text-primary-600"
                        children={readMore ? 'Show less' : 'Show more'}
                        onClick={() => setReadMore(!readMore)}
                      />
                    ) : null}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div
            className={`font-nunito grid grid-cols-2 gap-8 ${
              claimsFlowLite
                ? 'md:grid-cols-4 lg:grid-cols-4'
                : 'mb-[30px] md:grid-cols-5'
            }`}
          >
            {FieldsToRender_Line3.map((field) =>
              renderData(field.label, field.value ?? '-', field.label)
            )}
            {claimFilesModal && (
              <Modal
                kind="generic"
                settings={modalSettingsClaimFiles}
                className="m-auto w-[724px] rounded-md border bg-white"
              >
                <div className="px-5">
                  <ClaimPoPAndEvidenceUpload
                    handleClose={() => setClaimFilesModal(false)}
                    // this modal only does one thing, so we should cleanup on unmount directly on the component
                    shouldCleanupOnUnmount={true}
                  />
                </div>
              </Modal>
            )}
          </div>
          {!claimsFlowLite && (
            <>
              <div className="font-nunito mb-[30px] grid grid-cols-2 gap-10 md:grid-cols-5">
                {/* <div>
            <p className="mb-[10px] text-sm text-gray-500">
              Link to warranty details
            </p>
            <div className="flex gap-[13px]">
              <p className="font-medium">Link</p>
              <ArrowTopRightOnSquareIcon className="w-5 h-5 cursor-pointer text-primary-900" />
            </div>
          </div> */}
                {FieldsToRender_Line4.map((field) => {
                  return renderData(
                    field.label,
                    field.value ?? '-',
                    field.label
                  );
                })}

                {/* ------- TODO: check designs to show parts and confirm this -------- */}
                {partsReplacedModal && (
                  <Modal
                    kind="generic"
                    settings={modalSettingsPartsReplaced}
                    className="m-auto w-[724px] rounded-md border bg-white p-5"
                  >
                    <div className="flex flex-col justify-center">
                      <div className="p-5 mt-3 bg-gray-100 font-nunito grid grid-cols-5">
                        <p>Part name</p>
                        <p>Model id</p>
                        <p>Quantity</p>
                        <p>Cost</p>
                        <p>Subtotal</p>
                      </div>
                      {caseFacts?.parts_to_replace?.map(
                        (part: {
                          id: string;
                          part_name: string;
                          model_id: string;
                          parts_quantity: string;
                          parts_cost: { amount: string; currency: string };
                        }) => {
                          return (
                            <div
                              key={part.id}
                              className="p-5 font-nunito grid grid-cols-5"
                            >
                              <p>{part.part_name}</p>
                              <p>{part.model_id}</p>
                              <p>{part.parts_quantity}</p>
                              <p>
                                {part.parts_cost?.amount}{' '}
                                {part.parts_cost?.currency}
                              </p>
                              <p>
                                {/* Todo: review if number necessary */}
                                {multiply(
                                  Number(part.parts_quantity),
                                  Number(part.parts_cost?.amount)
                                )}
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="flex justify-start mt-5 font-medium font-nunito">
                      Total spent: {getTotalValueOfParts()}
                    </div>
                  </Modal>
                )}
              </div>
            </>
          )}
        </div>
      )}
      {showModal && (
        <ModalEditClaimDetails
          modalSettings={{
            title: 'Edit/ Update claim Details',
            handleClose: () => setShowModal(false),
          }}
          claimDetails={caseFacts}
          handleClose={() => setShowModal(false)}
        />
      )}
    </CardWrapperWithHeader>
  );
}

export default ClaimDetailsSection;

/* -------------------------------------------------------------------------- */
/*                                 Unused Code                                */
/* -------------------------------------------------------------------------- */

// const partsToReplace = caseFacts.parts_to_replace
//   ? caseFacts.parts_to_replace
//   : '';
// const partsUsedForRepair = caseFacts.parts_used_for_repair
//   ? caseFacts.parts_used_for_repair
//   : '';

// const warrantyTimeLeft = calcWarrantyTimeLeft(
//   holdingData?.warranty_expiry_date
// );
