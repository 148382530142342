import { useContext, useEffect, useState } from 'react';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  LoadingSpinner,
  Table,
  formatUnixTime,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import { CaseflowContext } from '../../../../context/CaseflowContext';
import {
  calculateTotalValue,
  formatAddress,
  useAppInfo,
} from '../../../../utils/helpers';
import { PartValuesShape } from 'apps/sage/types';
import RepairTimeModal from '@rabbit/sage/components/organisms/claim-costs-modals/RepairTimeModal/RepairTimeModal';
import PartsUsedModal from '@rabbit/sage/components/organisms/claim-costs-modals/PartsUsedModal/PartsUsedModal';
import AdministrativeTimeModal from '@rabbit/sage/components/organisms/claim-costs-modals/AdministrativeTimeModal/AdministrativeTimeModal';
import {
  PencilSquareIcon,
  TrashIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/solid';
import ClaimCostModal, {
  LogForms,
} from '@rabbit/sage/components/organisms/claim-costs-modals/ClaimCostModal/ClaimCostModal';
import {
  liteAdministrativeTimeColumns,
  liteOtherColumns,
  litePartsUsedColumns,
  litePostageColumns,
  liteRepairTimeColumns,
  liteTravelColumns,
} from './ClaimCostsSectionTableColumns';
import { LIST_CURRENCIES } from '@rabbit/bizproc/react';
import { UserUploadedDocument } from '@rabbit/data/types';
import DocumentsEditModal from '@rabbit/sage/components/organisms/claim-costs-modals/DocumentsEditModal/DocumentsEditModal';
import EditClaimCostModal from '@rabbit/sage/components/organisms/claim-costs-modals/EditClaimCostModal/EditClaimCostModal';
import { useTranslation } from 'react-i18next';
import {
  CFCF_AdministrativeCostLog,
  CFCF_OtherCostLog,
  CFCF_TravelCostLog,
  ClaimPartUsed,
} from '@rabbit/bizproc/core';

// eslint-disable-next-line @typescript-eslint/no-empty-interface

interface ModalDataShape {
  index?: number;
  data?: any;
  type?: string;
  remove?: boolean;
}

export interface LogClaimEditShape {
  data: object;
  name: LogForms<any>['name'];
  index: number;
  remove: boolean;
}
export interface DocumentModalShape {
  name: LogForms<any>['name'];
  data: object;
  index: number;
}

export interface ClaimCostsSectionProps {}

export function ClaimCostsSection(props: ClaimCostsSectionProps) {
  const appInfo = useAppInfo();

  const context = useContext(CaseflowContext);
  const {
    caseFacts,
    caseFlowCase,
    holdingData,
    showModal,
    setShowModal,
    setModalSettings,
  } = context || {};

  const [documentModal, setDocumentModal] = useState<DocumentModalShape>();
  const [editModal, setEditModal] = useState<LogClaimEditShape>();
  const [logCostModal, setLogCostModal] = useState(false);
  const [modalData, setModalData] = useState<ModalDataShape>({});
  const { t } = useTranslation();
  const claimsFlowLite = t('CFG_CLAIMS_FLOW') === 'lite';

  const modalSettingOptions = {
    repair_time: {
      kind: 'generic' as const,
      settings: {},
      children: (
        <RepairTimeModal
          data={modalData?.data ?? null}
          index={modalData?.index ?? null}
          remove={modalData?.remove ?? false}
        />
      ),
    },
    parts_used: {
      kind: 'generic' as const,
      settings: {},
      children: (
        <PartsUsedModal
          data={modalData?.data ?? null}
          index={modalData?.index ?? null}
          remove={modalData?.remove ?? false}
        />
      ),
    },
    administrative_time: {
      kind: 'generic' as const,
      settings: {},
      children: (
        <AdministrativeTimeModal
          data={modalData?.data ?? null}
          index={modalData?.index ?? null}
          remove={modalData?.remove ?? false}
        />
      ),
    },
  };

  useEffect(() => {
    if (modalData && modalData.type && setShowModal && setModalSettings) {
      const type = modalData.type as keyof typeof modalSettingOptions;
      setModalSettings(modalSettingOptions[type]);
      setShowModal(true);
    }
  }, [modalData]);

  if (
    !context ||
    !caseFacts ||
    !caseFlowCase ||
    !holdingData ||
    !setShowModal ||
    !setModalSettings
  )
    return (
      <CardWrapperWithHeader title="Claim costs">
        <LoadingSpinner size="xs" />
      </CardWrapperWithHeader>
    );

  const partsUsedColumns = [
    {
      header: 'part name',
      accessorKey: 'part_name',
      size: 30,
    },
    {
      header: 'model id',
      accessorKey: 'model_id',
      size: 20,
    },
    {
      header: 'quantity',
      accessorKey: 'parts_quantity',
      size: 20,
    },
    {
      header: 'cost',
      accessorKey: 'parts_cost',
      size: 25,
    },
    {
      header: 'subtotal',
      accessorKey: 'subtotal',
      size: 25,
    },
    {
      header: '',
      title: 'actions',
      accessorKey: 'actions',
      size: 15,
      Cell: ({ cell }: any) => {
        return (
          <div className="flex justify-end text-right cursor-pointer text-primary-900 gap-1">
            <PencilSquareIcon
              className="h-[20px] w-[20px]"
              onClick={() => {
                setModalData({
                  index: cell.row.index,
                  data: cell.row.original.data,
                  type: 'parts_used',
                });
              }}
            />
            <TrashIcon
              className="h-[20px] w-[20px]"
              onClick={() => {
                setModalData({
                  index: cell.row.index,
                  data: cell.row.original.data,
                  type: 'parts_used',
                  remove: true,
                });
              }}
            />
          </div>
        );
      },
    },
  ];

  const administrativeTimeColumns = [
    {
      header: 'person',
      accessorKey: 'repairer_id',
      size: 20,
    },
    {
      header: 'time',
      accessorKey: 'total_repairing_time',
      size: 20,
    },
    {
      header: 'date logged',
      accessorKey: 'timeStamp',
      size: 15,
    },
    {
      header: 'cost',
      accessorKey: 'cost',
      size: 15,
    },
    // {
    //   header: '',
    //   title: 'actions',
    //   accessorKey: 'actions',
    //   size: 15,
    //   Cell: ({ cell }: any) => {
    //     return (
    //       <div className="flex justify-end text-right cursor-pointer text-primary-900">
    //         <PencilSquareIcon
    //           className='w-[20px] h-[20px]'
    //           onClick={() => {
    //               setModalData({
    //                 index: cell.row.index,
    //                 data: cell.row.original.data,
    //                 type: 'administrative_time'
    //               });
    //             }}/>
    //       </div>
    //     );
    // },
    // },
  ];

  const repairTimeColumns = [
    {
      header: 'person',
      accessorKey: 'repairer_id',
      size: 20,
    },
    {
      header: 'time',
      accessorKey: 'total_repairing_time',
      size: 20,
    },
    {
      header: 'date logged',
      accessorKey: 'timeStamp',
      size: 15,
    },
    {
      header: 'cost',
      accessorKey: 'cost',
      size: 15,
    },
    {
      header: '',
      title: 'actions',
      accessorKey: 'actions',
      size: 15,
      Cell: ({ cell }: any) => {
        return (
          <div className="flex justify-end text-right cursor-pointer text-primary-900 gap-1">
            <PencilSquareIcon
              className="h-[20px] w-[20px]"
              onClick={() => {
                setModalData({
                  index: cell.row.index,
                  data: cell.row.original.data,
                  type: 'repair_time',
                });
              }}
            />
            <TrashIcon
              className="h-[20px] w-[20px]"
              onClick={() => {
                setModalData({
                  index: cell.row.index,
                  data: cell.row.original.data,
                  type: 'repair_time',
                  remove: true,
                });
              }}
            />
          </div>
        );
      },
    },
  ];

  const postageColumns = [
    {
      header: 'carrier',
      accessorKey: 'carrier',
      size: 20,
    },
    {
      header: 'tracking number',
      accessorKey: 'tracking_number',
      size: 20,
    },
    {
      header: 'address',
      accessorKey: 'address',
      size: 15,
    },
    {
      header: 'date',
      accessorKey: 'date',
      size: 15,
    },
    {
      header: 'cost',
      accessorKey: 'cost',
      size: 15,
    },
    {
      header: '',
      title: 'actions',
      accessorKey: 'actions',
    },
  ];

  //Parts used
  const getPartsUserArrayParsed = () => {
    if (claimsFlowLite) {
      const partsUsedArray: ClaimPartUsed[] =
        caseFacts?.parts_used_cost_data ?? [];

      const partsUserArrayParsed: {
        data: object;
        manufacturer: string;
        part_name: string;
        model_id: string;
        parts_quantity: string;
        parts_cost: string;
        parts_cost_vat: string;
        subtotal: string;
      }[] = [];
      partsUsedArray.forEach((result, index) => {
        partsUserArrayParsed.push({
          data: { ...result, index },
          part_name: result?.name,
          manufacturer: result?.manufacturer as any,
          model_id: result?.id,
          parts_quantity: String(result?.quantity),
          parts_cost: result?.cost
            ? getCurrencyFormat(result?.cost?.amount, result?.cost?.currency)
            : '-',
          parts_cost_vat: result?.VAT ? result?.VAT + '%' : '-',
          subtotal:
            getCurrencyFormat(
              Number(result?.quantity) * Number(result?.cost?.amount),
              result?.cost?.currency
            ) ?? '-',
        });
      });
      return partsUserArrayParsed;
    } else {
      const partsUsedArray: PartValuesShape[] = [];
      caseFacts?.repair_work_registry?.forEach((item) => {
        partsUsedArray.push(...item.parts_used_for_repair);
      });

      const partsUserArrayParsed: {
        data: object;
        repairer_id: string;
        part_name: string;
        model_id: string;
        parts_quantity: string;
        parts_cost: string;
        subtotal: string;
      }[] = [];
      partsUsedArray.forEach((result, index) => {
        if (result?.part_name)
          partsUserArrayParsed.push({
            data: result,
            repairer_id: '-',
            part_name: result?.part_name,
            model_id: result?.model_id,
            parts_quantity: result.parts_quantity,
            parts_cost: result?.parts_cost
              ? getCurrencyFormat(
                  result?.parts_cost?.amount,
                  result?.parts_cost?.currency
                )
              : '-',
            subtotal:
              getCurrencyFormat(
                Number(result.parts_quantity) *
                  Number(result?.parts_cost?.amount),
                result?.parts_cost?.currency
              ) ?? '-',
          });
      });
      return partsUserArrayParsed;
    }
  };

  const partsUserArrayParsed: any[] = getPartsUserArrayParsed();
  const partsUsedTotalCost = partsUserArrayParsed.reduce((acc, curr) => {
    return (
      acc +
      //@ts-ignore todo: review this
      (curr.subtotal ? parseFloat(curr.subtotal.replace(/[^\d.-]/g, '')) : 0)
    );
  }, 0);

  // Administrative time
  const administrativeTime: any[] = [];
  //@ts-ignore
  const RATE = import.meta.env.VITE_RATE_SHELTA_USER ?? 15;
  const getFormattedResultAdministrativeTime = () => {
    if (claimsFlowLite) {
      const formattedResultAdministrativeTime: {
        data: object;
        repairer_id: string;
        total_repairing_time: string;
        timeStamp: string;
        cost: string;
        vat: string;
      }[] = [];
      caseFacts.administrative_cost_data?.forEach(
        (result: CFCF_AdministrativeCostLog, i) => {
          formattedResultAdministrativeTime.push({
            data: { ...result, index: i },
            repairer_id: result?.team_member.name || '-',
            total_repairing_time: result?.time_spent || '-',
            timeStamp: result?.date
              ? formatUnixTime(Number(result?.date), 'dd/MM/yyyy')
              : '-',
            cost: result?.time_spent
              ? getCurrencyFormat(result?.rate.amount || 0, appInfo.currency)
              : '-',
            vat: result?.VAT ? result?.VAT + '%' : '-',
          });
        }
      );
      return formattedResultAdministrativeTime;
    } else {
      // Administrative time
      const newObjectForAdministrativeTime = {
        repairer: caseFacts?.delegate_repairer_name ?? '',
        administrative_time: caseFacts?.administrative_time ?? '',
        cost: caseFacts?.administrative_time ?? '',
        timeStamp: Date.now(),
      };
      administrativeTime.push(newObjectForAdministrativeTime);
      const formattedResultAdministrativeTime: {
        data: object;
        repairer_id: string;
        total_repairing_time: string;
        timeStamp: string;
        cost: string;
      }[] = [];
      administrativeTime.forEach((result) => {
        if (result?.administrative_time) {
          formattedResultAdministrativeTime.push({
            data: result,
            repairer_id: result?.repairer || '-',
            total_repairing_time: result?.administrative_time || '-',
            timeStamp: result?.timeStamp
              ? formatUnixTime(result?.timeStamp, 'dd/MM/yyyy')
              : '-',
            cost: result?.administrative_time
              ? getCurrencyFormat(
                  calculateTotalValue(result?.administrative_time, RATE),
                  appInfo.currency
                )
              : '-',
          });
        }
      });
      return formattedResultAdministrativeTime;
    }
  };

  const formattedResultAdministrativeTime =
    getFormattedResultAdministrativeTime();

  // Assessment time
  const assessmentTime = [
    {
      repairer: caseFacts?.delegate_repairer_name,
      time_spent_assessing: caseFacts?.time_spent_assessing,
      cost: caseFacts?.time_spent_assessing,
      timeStamp: Date.now(),
    },
  ];
  const formattedResultAssessmentTime: {
    data: object;
    repairer_id: string;
    total_repairing_time: string;
    timeStamp: string;
    cost: string;
  }[] = [];
  assessmentTime.forEach((result) => {
    if (result?.time_spent_assessing) {
      if (typeof result?.time_spent_assessing === 'string') {
        formattedResultAssessmentTime.push({
          data: result,
          repairer_id: result?.repairer || '-',
          total_repairing_time: result?.time_spent_assessing || '-',
          timeStamp: result?.timeStamp
            ? formatUnixTime(result?.timeStamp, 'dd/MM/yyyy')
            : '-',
          cost: result?.time_spent_assessing
            ? getCurrencyFormat(
                calculateTotalValue(result?.time_spent_assessing, RATE),
                appInfo.currency
              )
            : '-',
        });
      } else {
        result?.time_spent_assessing.forEach((timeSpent) => {
          formattedResultAssessmentTime.push({
            data: result,
            repairer_id: timeSpent.user || '-',
            total_repairing_time: timeSpent.time || '-',
            timeStamp: result?.timeStamp
              ? formatUnixTime(result?.timeStamp, 'dd/MM/yyyy')
              : '-',
            cost: timeSpent.time
              ? getCurrencyFormat(
                  calculateTotalValue(timeSpent.time, RATE),
                  appInfo.currency
                )
              : '-',
          });
        });
      }
    }
  });

  const finalArrayForAdministrativeTimeTable: any[] =
    formattedResultAssessmentTime.concat(formattedResultAdministrativeTime);

  const administratitveTotalCost = claimsFlowLite
    ? finalArrayForAdministrativeTimeTable.reduce((acc, cur) => {
        return (
          acc + calculateTotalValue(cur.data.time_spent, cur.data.rate.amount)
        );
      }, 0)
    : finalArrayForAdministrativeTimeTable.reduce((acc, curr) => {
        return (
          acc + (curr.cost ? parseFloat(curr.cost.replace(/[^\d.-]/g, '')) : 0)
        );
      }, 0);

  // Repair time
  const getFormattedResultRepairTime = () => {
    if (claimsFlowLite) {
      const repairTime = caseFacts?.repair_time_data || [];
      const formattedResultRepairTime: {
        data: object;
        repairer_id: string;
        total_repairing_time: string;
        timeStamp: string;
        cost: string;
        vat: string;
      }[] = [];
      repairTime.forEach((result, i) => {
        formattedResultRepairTime.push({
          data: { ...result, index: i },
          repairer_id: result?.team_member.name || '-',
          total_repairing_time: result?.time_spent || '-',
          timeStamp: result?.date
            ? formatUnixTime(Number(result?.date), 'dd/MM/yyyy')
            : '-',
          cost: result?.rate
            ? getCurrencyFormat(result?.rate.amount, result?.rate.currency)
            : '-',
          vat: result?.VAT ? result?.VAT + '%' : '-',
        });
      });
      return formattedResultRepairTime;
    } else {
      const repairTime: any[] = caseFacts?.repair_time_data || [];
      const formattedResultRepairTime: {
        data: object;
        repairer_id: string;
        total_repairing_time: string;
        timeStamp: string;
        cost: string;
      }[] = [];
      repairTime.forEach((result, i) => {
        if (result?.total_repairing_time)
          formattedResultRepairTime.push({
            data: result,
            repairer_id: result?.delegate_repairer_name
              ? result?.delegate_repairer_name
              : '-',
            total_repairing_time: result?.total_repairing_time
              ? result?.total_repairing_time
              : '-',
            timeStamp: result?.timeStamp
              ? formatUnixTime(result?.timeStamp, 'dd/MM/yyyy')
              : '-',
            cost: result?.total_repairing_time
              ? getCurrencyFormat(
                  calculateTotalValue(result?.total_repairing_time, RATE),
                  appInfo.currency
                )
              : '-',
          });
      });
      return formattedResultRepairTime;
    }
  };

  const formattedResultRepairTime: any[] = getFormattedResultRepairTime();

  const repairTotalCost = claimsFlowLite
    ? caseFacts?.repair_time_data?.reduce((acc, curr) => {
        return acc + calculateTotalValue(curr.time_spent, curr.rate.amount);
      }, 0) || 0
    : formattedResultRepairTime.reduce((acc, curr) => {
        return (
          //@ts-ignore
          acc + (curr.cost ? parseFloat(curr.cost.replace(/[^\d.-]/g, '')) : 0)
        );
      }, 0);

  // Postage
  const getFormattedResultPostage = () => {
    if (claimsFlowLite) {
      const postage = caseFacts?.shipping_cost_data || [];
      const formattedResultPostage: {
        data: object;
        carrier: string;
        tracking_number: string;
        date: string;
        cost: string;
        vat: string;
        attachments: UserUploadedDocument[];
      }[] = [];
      postage.forEach((result, i) => {
        formattedResultPostage.push({
          data: { ...result, index: i },
          carrier: (result?.carrier as any) || '-',
          tracking_number: result?.tracking_number || '-',
          date: result?.date
            ? formatUnixTime(Number(result?.date), 'dd/MM/yyyy')
            : '-',
          cost: result?.amount
            ? getCurrencyFormat(
                result?.amount?.amount,
                result?.amount?.currency
              )
            : '-',
          vat: String(result?.VAT || '-'),
          attachments: result?.documents || [],
        });
      });
      return formattedResultPostage;
    } else {
      const postage = caseFacts?.postage_registry || [];
      const formattedResultPostage: {
        carrier: string;
        tracking_number: string;
        address: string | JSX.Element;
        date: string;
        cost: string;
      }[] = [];
      postage.forEach((result, i) => {
        if (result?.outbound_carrier) {
          formattedResultPostage.push({
            carrier: result?.outbound_carrier ? result?.outbound_carrier : '-',
            tracking_number: result?.outbound_tracking_number
              ? result?.outbound_tracking_number
              : '-',
            address: result?.outbound_address ? (
              <div className="text-sm">
                {formatAddress(result?.outbound_address)}
              </div>
            ) : (
              '-'
            ),
            date: result?.timeStamp
              ? formatUnixTime(result?.timeStamp, 'dd/MM/yyyy')
              : '-',
            cost: result?.outbound_postage_cost
              ? getCurrencyFormat(
                  result?.outbound_postage_cost?.amount,
                  result?.outbound_postage_cost?.currency
                )
              : '-',
          });
        }
      });
      return formattedResultPostage;
    }
  };

  const formattedResultPostage: any[] = getFormattedResultPostage();
  const postageTotalCost = formattedResultPostage.reduce((acc, curr) => {
    return (
      acc + (curr.cost ? parseFloat(curr.cost.replace(/[^\d.-]/g, '')) : 0)
    );
  }, 0);

  // Travel
  const travel = caseFacts?.travel_cost_data;
  const formattedResultTravel: {
    data: object;
    description: string;
    date: string;
    cost: string;
    vat: string;
    attachments: UserUploadedDocument[];
  }[] = [];
  travel?.forEach((result: CFCF_TravelCostLog, i) => {
    formattedResultTravel.push({
      data: { ...result, index: i },
      description: result?.description || '-',
      date: result?.date
        ? formatUnixTime(Number(result?.date), 'dd/MM/yyyy')
        : '-',
      cost: result?.amount
        ? getCurrencyFormat(result?.amount?.amount, result?.amount?.currency)
        : '-',
      vat: result?.VAT ? String(result?.VAT) : '-',
      attachments: result?.documents || [],
    });
  });

  const travelTotalCost = formattedResultTravel.reduce((acc, curr) => {
    return (
      acc + (curr.cost ? parseFloat(curr.cost.replace(/[^\d.-]/g, '')) : 0)
    );
  }, 0);

  // Other claim costs
  const other = caseFacts?.other_cost_data || [];
  const formattedResultOther: {
    data: object;
    description: string;
    date: string;
    cost: string;
    vat: string;
    attachments: UserUploadedDocument[];
  }[] = [];
  other.forEach((result: CFCF_OtherCostLog, i) => {
    formattedResultOther.push({
      data: { ...result, index: i },
      description: result?.description || '-',
      date: result?.date
        ? formatUnixTime(Number(result?.date), 'dd/MM/yyyy')
        : '-',
      cost: result?.amount
        ? getCurrencyFormat(result?.amount?.amount, result?.amount?.currency)
        : '-',
      vat: result?.VAT ? String(result?.VAT) : '-',
      attachments: result?.documents || [],
    });
  });

  const otherTotalCost = formattedResultOther.reduce((acc, curr) => {
    return (
      acc + (curr.cost ? parseFloat(curr.cost.replace(/[^\d.-]/g, '')) : 0)
    );
  }, 0);

  const totalCost = [
    formattedResultAdministrativeTime,
    formattedResultAssessmentTime,
    formattedResultRepairTime,
    formattedResultPostage,
    formattedResultTravel,
    formattedResultOther,
  ].reduce((acc, curr) => {
    return (
      acc +
      //@ts-ignore todo review this to remove ts-ignore
      curr.reduce((subtotalAcc: number, item: { cost: string }) => {
        return (
          subtotalAcc +
          (item.cost ? parseFloat(item.cost.replace(/[^\d.-]/g, '')) : 0)
        );
      }, 0)
    );
  }, 0);

  const totalCostFromClaim = claimsFlowLite
    ? [
        administratitveTotalCost,
        repairTotalCost,
        partsUsedTotalCost,
        postageTotalCost,
        travelTotalCost,
        otherTotalCost,
      ].reduce((a, b) => a + b, 0)
    : partsUsedTotalCost + totalCost ?? 0;

  const renderTableCost = (label: string, columns: any, data: any) => {
    return (
      <div>
        <CardWrapperWithHeader
          title={label}
          canCollapse={true}
          collapsedByDefault={true}
          smaller={true}
          noPadding={true}
        >
          <div className="max-w-[757px] rounded-lg border border-gray-100">
            <Table
              columns={columns}
              data={data}
              inner={true}
              enablePagination={data.length > 10}
              initialState={{
                showGlobalFilter: true,
              }}
              muiSearchTextFieldProps={{
                sx: {
                  display: 'none',
                },
              }}
              muiTopToolbarProps={{
                sx: {
                  display: 'none',
                },
              }}
              muiTableHeadCellProps={{
                className: 'relative bg-gray-200 uppercase font-light',
              }}
              muiTableBodyCellProps={{
                className: 'px-4 py-0',
              }}
            />
          </div>
        </CardWrapperWithHeader>
      </div>
    );
  };

  const currencySymbol =
    LIST_CURRENCIES.find((i) => i.code === appInfo.currency)?.symbolNative ||
    '';

  return (
    <CardWrapperWithHeader
      title={`Claim costs - Total: ${getCurrencyFormat(
        totalCostFromClaim || 0,
        appInfo.currency
      )}`}
      collapsedByDefault={claimsFlowLite ? false : totalCostFromClaim === 0}
      headerRight={
        claimsFlowLite && (
          <ButtonIcon
            type="primary"
            label={`Log claim cost`}
            Icon={WrenchScrewdriverIcon}
            iconLeft
            onClick={() => {
              setLogCostModal(true);
            }}
          />
        )
      }
    >
      <div className="flex flex-col font-nunito gap-6">
        {administratitveTotalCost > 0 &&
          renderTableCost(
            `Administrative time - Total: ${getCurrencyFormat(
              administratitveTotalCost || 0,
              appInfo.currency
            )}`,
            claimsFlowLite
              ? liteAdministrativeTimeColumns(setEditModal, currencySymbol)
              : administrativeTimeColumns,
            finalArrayForAdministrativeTimeTable
          )}
        {repairTotalCost > 0 &&
          renderTableCost(
            `Repair time - Total: ${getCurrencyFormat(
              repairTotalCost || 0,
              appInfo.currency
            )}`,
            claimsFlowLite
              ? liteRepairTimeColumns(setEditModal, currencySymbol)
              : repairTimeColumns,
            formattedResultRepairTime
          )}
        {partsUsedTotalCost > 0 &&
          renderTableCost(
            `Parts used - Total: ${getCurrencyFormat(
              partsUsedTotalCost || 0,
              appInfo.currency
            )}`,
            claimsFlowLite
              ? litePartsUsedColumns(setEditModal)
              : partsUsedColumns,
            partsUserArrayParsed
          )}
        {postageTotalCost > 0 &&
          renderTableCost(
            `${
              claimsFlowLite ? 'Postage' : 'Shipping'
            } - Total: ${getCurrencyFormat(
              postageTotalCost || 0,
              appInfo.currency
            )}`,
            claimsFlowLite
              ? litePostageColumns(setEditModal, setDocumentModal)
              : postageColumns,
            formattedResultPostage
          )}
        {claimsFlowLite && (
          <>
            {travelTotalCost > 0 &&
              renderTableCost(
                `Travel - Total: ${getCurrencyFormat(
                  travelTotalCost || 0,
                  appInfo.currency
                )}`,
                liteTravelColumns(setEditModal, setDocumentModal),
                formattedResultTravel
              )}
            {otherTotalCost > 0 &&
              renderTableCost(
                `Other - Total: ${getCurrencyFormat(
                  otherTotalCost || 0,
                  appInfo.currency
                )}`,
                liteOtherColumns(setEditModal, setDocumentModal),
                formattedResultOther
              )}
            {logCostModal && (
              <ClaimCostModal handleClose={() => setLogCostModal(false)} />
            )}
            {editModal && (
              <EditClaimCostModal
                {...editModal}
                handleClose={() => setEditModal(undefined)}
              />
            )}
            {documentModal && (
              <DocumentsEditModal
                {...documentModal}
                handleClose={() => setDocumentModal(undefined)}
              />
            )}
          </>
        )}
      </div>
    </CardWrapperWithHeader>
  );
}

export default ClaimCostsSection;
