import {
  FileStorageContext,
  getCompletedUploadsOfCategory,
} from '@rabbit/bizproc/react';
import { UserUploadedDocument } from '@rabbit/data/types';
import { UploadedFileCategories } from '@rabbit/elements/shared-types';
import { useContext, useEffect } from 'react';

export interface SageFileUploadAutoUpdaterProps {
  fileCategory: UploadedFileCategories;
}

export function SageFileUploadAutoUpdater({
  fileCategory,
}: SageFileUploadAutoUpdaterProps) {
  const fileStorageContext = useContext(FileStorageContext);
  const {
    uploadQueueState,
    moveCompletedUploadsToAttached,
    updateHoldingWithFiles,
  } = fileStorageContext || {};

  useEffect(() => {
    if (
      !moveCompletedUploadsToAttached ||
      !updateHoldingWithFiles ||
      !uploadQueueState?.ongoing ||
      !uploadQueueState?.completed
    )
      return;

    /* -------------------------------------------------------------------------- */
    /*                     Autosave consumer proof of purchase                    */
    /* -------------------------------------------------------------------------- */

    // Wait until all uploads are complete before we do anything
    const { ongoing, completed } = uploadQueueState;
    if (ongoing.length === 0 && completed.length !== 0) {
      if (fileCategory === UploadedFileCategories.ConsumerProofPurchase) {
        // Consumer proofs of purchase
        const proofOfPurchaseUploads = getCompletedUploadsOfCategory(
          completed,
          UploadedFileCategories.ConsumerProofPurchase
        );

        if (proofOfPurchaseUploads.length === 0) return;

        const uploadedFiles = proofOfPurchaseUploads.map(
          (item) => item.uploadedFile
        );

        // We know that all the files are for the same holding, so we can just take the first docid
        updateHoldingWithFiles(
          proofOfPurchaseUploads?.[0].docType?.docid ?? '',
          uploadedFiles as UserUploadedDocument[]
        )
          .then(() => {
            moveCompletedUploadsToAttached(proofOfPurchaseUploads);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [uploadQueueState?.ongoing, uploadQueueState?.completed]);

  return null;
}
