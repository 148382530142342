import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Input,
  getClaimLimit,
  getFormattedDate,
  getIncreasedLabourRate,
} from '@rabbit/elements/shared-components';
import { useEffect, useState } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { useGetHoldingFromRegistration } from '@rabbit/bizproc/react';
import { useAppInfo } from '@rabbit/sage/utils/helpers';

export const validationSchema = Yup.object().shape({
  registrationNo: Yup.string()
    .trim()
    .min(5, 'Enter a valid registration plate')
    .required('Enter a registration plate'),
});

export interface NewClaimVehicleLicencePlateProps {
  handleClose: () => void;
  onChange: any; //TODO
  setIsLoading: (loading: boolean) => void;
}

interface NewClaimVehicleDataShape {
  registrationNo: string;
}

export function NewClaimVehicleLicencePlate(
  props: NewClaimVehicleLicencePlateProps
) {
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  const CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT = t('CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT') === "true";

  const { onChange, handleClose, setIsLoading } = props;

  const initialValues: NewClaimVehicleDataShape = {} as any;

  const [registrationNo, setRegistrationNo] = useState('');

  const activeTenantLink = t('tenantLink');
  const warrantyDetails = useGetHoldingFromRegistration(
    registrationNo,
    activeTenantLink
  );

  //If voided should be considered as not found -VP
  const notFoundError = warrantyDetails && (warrantyDetails?.warranties === null || warrantyDetails?.warranties?.[0].status === 'voided');

  const onSubmit = async (values: NewClaimVehicleDataShape) => {
    const { registrationNo } = values;
    setRegistrationNo(registrationNo);
    if (warrantyDetails && !notFoundError) {
      onChange(1, warrantyDetails);
      return;
    }
  };

  let expiredWarning = false;
  const currentWarranty = warrantyDetails?.warranties?.[0] as any;

  if (warrantyDetails && currentWarranty && currentWarranty?.endDate) {
    const expiryDate = new Date(currentWarranty?.endDate);
    const today = new Date();
    if (expiryDate < today) {
      expiredWarning = true;
    }
  };

  useEffect(() => {
    if (warrantyDetails && !notFoundError && !expiredWarning) {
      onChange(1, warrantyDetails);
    }
  }
  , [warrantyDetails]);

  const labourRate = currentWarranty ? getIncreasedLabourRate(currentWarranty, appInfo.currency) : null;

  return (
    <Formik
      initialValues={initialValues}
      //validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(props) => (
        <Form className="flex-col gap-3 p-5">
          {currentWarranty && !notFoundError ? (
            <>
              {expiredWarning && (
                <div className="font-nunito mb-4 flex flex-col gap-2 rounded-md bg-yellow-100 p-4 text-yellow-700">
                  <div className="flex items-center gap-2">
                    <ExclamationTriangleIcon className="h-4 w-4" />
                    <span className="font-semibold">Warranty expired</span>
                  </div>
                  <div>
                    The warranty for this vehicle has expired. You can still
                    register a claim by pressing continue.
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-2">
                <div className="font-caudex text-lg">Warranty plan</div>
                <div className="font-nunito flex flex-col gap-2 rounded border border-gray-200 p-2 text-gray-500">
                  <div className="flex gap-2">
                    <span className="font-semibold text-black">
                      {currentWarranty?.templateTitle}
                    </span>
                    {expiredWarning && (
                      <span className="flex items-center rounded bg-red-100 py-1 px-2 text-xs capitalize text-red-700">
                        Expired
                      </span>
                    )}
                  </div>
                  <div>
                    Start date: {getFormattedDate(currentWarranty?.startDate)}
                  </div>
                  <div>
                    Term:{' '}
                    {currentWarranty?.decider?.stipulated?.duration?.label}
                  </div>
                  <div>
                    Claim limit:{' '}
                    {getClaimLimit(currentWarranty, appInfo.currency, CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT)}
                  </div>
                  {labourRate?.length ? (
                    <div>
                      Labour rate: {labourRate}
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : (
            <>
              {notFoundError && (
                <div className="font-nunito mb-4 flex flex-col gap-2 rounded-md bg-red-100 p-4 text-red-700">
                  <div className="flex items-center gap-2">
                    <ExclamationTriangleIcon className="h-4 w-4" />
                    <span className="font-semibold">Car not found</span>
                  </div>
                  <div>
                    Please enter a registration plate of vehicle that exists in
                    the system.
                  </div>
                </div>
              )}
              <div className="mb-4 flex">
                <Input
                  type="text"
                  label="Registration plate*"
                  name="registrationNo"
                  settings={{
                    id: 'plate',
                    placeholder: 'Enter registration plate',
                  }}
                />
              </div>
            </>
          )}
          <div className="mt-5 flex w-full gap-4">
            <Button
              kind="primary"
              type="submit"
              // disabled={
              //   props.errors.registrationNo ||
              //   Object.keys(props.values).length === 0
              //     ? true
              //     : false
              // }
            >
              Continue
            </Button>
            <Button kind="red" type="submit" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default NewClaimVehicleLicencePlate;
