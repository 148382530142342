import {
  Button,
  LoadingSpinner,
  Modal,
  TabsGeneric,
} from '@rabbit/elements/shared-components';
//import { ClaimDetailsData } from '../../molecules/claims/ClaimDetailsSection/ClaimDetailsSection';
//import UpdateCustomerDetailsForm from '../forms/UpdateCustomerDetailsForm/UpdateCustomerDetailsForm';
import UpdateClaimDetailsForm from '../forms/UpdateClaimDetailsForm/UpdateClaimDetailsForm';
import { ModalSettingsShape } from '@rabbit/elements/shared-components';
import { CaseflowContext } from '../../../context/CaseflowContext';
import { useContext, useEffect, useState } from 'react';
import { SageFileUploader } from '../upload-wrapper/SageFileUploader';
import { UploadedFileCategories } from '@rabbit/elements/shared-types';
import { useTranslation } from 'react-i18next';
import UpdateCustomerDetailsForm from '../forms/UpdateCustomerDetailsForm/UpdateCustomerDetailsForm';
import { FileStorageContext } from '@rabbit/bizproc/react';
import ClaimPoPAndEvidenceUpload from '../ClaimPoPAndEvidenceUpload/ClaimPoPAndEvidenceUpload';

export interface ModalEditClaimDetailsProps {
  modalSettings: ModalSettingsShape;
  // todo: review below as type is missing
  //claimDetails: ClaimDetailsData;
  claimDetails: any;
  handleClose: () => void;
}

function ModalEditClaimDetails({
  modalSettings,
  claimDetails,
  handleClose,
}: ModalEditClaimDetailsProps) {
  const { caseFlowCase, caseActors, alterCaseFacts } =
    useContext(CaseflowContext) || {};

  const {
    uploadQueueState,
    moveCompletedUploadsToAttached,
    updateHoldingWithFiles,
    unusedCompletedCleanup,
    setShouldRefetch,
  } = useContext(FileStorageContext) || {};

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const claimsFlowLite = t('CFG_CLAIMS_FLOW') === 'lite';

  // Remove uploads that haven't been saved. We do this here instead of directly in ClaimPoPAndEvidenceUpload because this is a multi-tab modal
  useEffect(() => {
    return () => {
      unusedCompletedCleanup?.();
    };
  }, []);

  if (!caseFlowCase) return <LoadingSpinner size="xs" />;

  const renderCustomerDetails = () => {
    return (
      <UpdateCustomerDetailsForm
        claimDetails={claimDetails}
        handleClose={handleClose}
        caseFlowCase={caseFlowCase}
        setLoading={setLoading}
      />
    );
  };

  const renderClaimDetails = () => {
    return (
      <UpdateClaimDetailsForm
        claimDetails={claimDetails}
        handleClose={handleClose}
        caseFlowCase={caseFlowCase}
        setLoading={setLoading}
      />
    );
  };

  const renderAttachements = () => {
    return <ClaimPoPAndEvidenceUpload handleClose={handleClose} />;
  };

  const tabs = claimsFlowLite
    ? [
        {
          label: 'Claim details',
          children: renderClaimDetails(),
        },
        {
          label: 'Attachments',
          children: renderAttachements(),
        },
      ]
    : [
        {
          label: 'Customer details',
          children: renderCustomerDetails(),
        },
        {
          label: 'Claim details',
          children: renderClaimDetails(),
        },
        {
          label: 'Attachments',
          children: renderAttachements(),
        },
      ];

  return (
    <Modal
      kind="generic"
      settings={{
        title: 'Edit / Update claim details',
        handleClose: handleClose,
      }}
      isLoading={loading}
      className="m-auto w-[724px] rounded-md border bg-white"
    >
      <div className="p-5">
        <TabsGeneric items={tabs} query={false}></TabsGeneric>
      </div>
    </Modal>
  );
}

export default ModalEditClaimDetails;
