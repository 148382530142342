import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@rabbit/elements/shared-components';
import {
  CAR_FUEL_OPTIONS,
  CAR_TRANSMISSION_OPTIONS,
  DRIVE_TYPE_OPTIONS,
  VEHICLE_CATEGORY_OPTIONS,
  YES_NO_OPTIONS,
} from '@rabbit/bizproc/react';
import { isValidDate, useAppInfo } from '@rabbit/sage/utils/helpers';
import { useTranslation } from 'react-i18next';

export const validationSchema = Yup.object().shape({
  registrationNo: Yup.string()
    .trim()
    .required('Please enter registration plate'),
  make: Yup.string().trim().required('Please enter make name'),
  model: Yup.string().trim().required('Please enter model name'),
  version: Yup.string(),
  engineCc: Yup.string().trim().required('Please enter engine cc'),
  fuel: Yup.string().trim().required('Please select fuel option'),
  transmission: Yup.string()
    .trim()
    .required('Please select transmission option'),
  driveType: Yup.string().trim().required('Please select drive type'),
  yearOfManufacture: Yup.string()
    .trim()
    .required('Please enter year of manufacture'),
  regDate: Yup.string()
    .trim()
    .nullable()
    .required('Please enter registration date'),
  chassisNo: Yup.string().trim(),
  mileage: Yup.object({
    addon: Yup.string().required('Please select the mileage unit'),
    value: Yup.string().trim().required('Please enter mileage'),
  }).required('Please enter mileage'),
  last_service_date: Yup.string().trim().nullable(),
  tech_check_date: Yup.string().trim().nullable(),
  commercialVehicle: Yup.string()
    .trim()
    .required('Please select commercial vehicle option'),
  vehicleCategory: Yup.string()
    .trim()
    .required('Please select vehicle category'),
  purchasePrice: Yup.object({
    currency: Yup.string().required('Please select currency'),
    amount: Yup.string().required('Please enter purchase price'),
  }),
});

export interface CreateNewRegistrationFormProps {
  handleClose: () => void;
  onChange: any; //TODO
  data: CarRegistrationShape & { mileageUnit: string; tech_check_date: string };
}

export interface CarRegistrationShape {
  registrationNo: string;
  make: string;
  model: string;
  version: string;
  engineCc: string;
  fuel: string;
  transmission: string;
  driveType: string;
  yearOfManufacture: string;
  regDate: string;
  chassisNo: string;
  mileage: {
    addon: string;
    value: string;
  };
  last_service_date: string;
  tech_check_date: string;
  commercialVehicle: string;
  vehicleCategory: string;
  purchasePrice: {
    amount: string;
    currency: string;
  };
  manualInput: boolean;
}

export function PWCreateNewRegistration(props: CreateNewRegistrationFormProps) {
  const { onChange, handleClose, data } = props;

  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const mileageUnit = t('CFG_COBRAND_MILEAGE_UNIT');
  const onSubmit = (values: any) => {
    const result = {
      ...values,
      mileageUnit: values.mileage.addon,
      mileage: values.mileage.value,
    };
    onChange(2, result);
  };

  const initialValues: CarRegistrationShape = {
    ...data,
    fuel:
      CAR_FUEL_OPTIONS.find((i) => data.fuel?.match(i.value))?.value ??
      (data.fuel || ''),
    purchasePrice: {
      currency: appInfo.currency,
      amount: data.purchasePrice?.amount || '',
    },
    mileage: {
      addon: data.mileageUnit || mileageUnit,
      value: (data.mileage as any) || '',
    },
    last_service_date: isValidDate(new Date(data.last_service_date))
      ? data.last_service_date
      : '',
    tech_check_date: isValidDate(new Date(data.tech_check_date))
      ? data.tech_check_date
      : '',
    vehicleCategory: data.vehicleCategory || 'standard',
  };

  const yearOptions: any[] = Array(new Date().getFullYear() - 1940)
    .fill('')
    .map((_: any, i: number) => ({
      id: `${i + 1}`,
      label: `${1940 + i + 1}`,
      value: 1940 + i + 1,
    }))
    .reverse();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(props) => {
        return (
          <Form className="flex-col gap-3 px-5 pt-5">
            <div className="flex">
              <Input
                type="text"
                name="registrationNo"
                label="Registration plate*"
                settings={{
                  id: 'registrationNo',
                  placeholder: 'Enter registration plate',
                  disabled:
                    data.registrationNo !== undefined &&
                    !data.manualInput &&
                    data.registrationNo !== '',
                }}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <Input
                type="text"
                name="make"
                label="Make*"
                settings={{
                  id: 'make',
                  placeholder: 'Enter make name here',
                  disabled:
                    data.make !== undefined &&
                    !data.manualInput &&
                    data.make !== '',
                }}
              />
              <Input
                type="text"
                name="model"
                label="Model*"
                settings={{
                  id: 'model',
                  placeholder: 'Enter model name here',
                  disabled:
                    data.model !== undefined &&
                    data.model !== '' &&
                    !data.manualInput,
                }}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <Input
                type="text"
                name="version"
                label="Version"
                settings={{
                  id: 'version',
                  placeholder: 'Enter version name here',
                  disabled:
                    data.version !== undefined &&
                    data.version !== '' &&
                    !data.manualInput,
                  allowSpecialCharacter: true,
                }}
              />
              <Input
                type="text"
                name="engineCc"
                label="Engine cc*"
                settings={{
                  id: 'engineCc',
                  placeholder: 'Enter engine cc here',
                  disabled:
                    data.engineCc !== undefined &&
                    data.engineCc !== '' &&
                    !data.manualInput,
                  type: 'number',
                }}
              />
            </div>
            <div className="mt-4 flex items-start gap-4">
              <Input
                type="select"
                name="fuel"
                label="Fuel*"
                settings={{
                  id: 'fuel',
                  placeholder: 'Please select an option',
                  options: CAR_FUEL_OPTIONS,
                  errors: props.errors,
                  disabled:
                    data.fuel !== undefined &&
                    data.fuel !== '' &&
                    !data.manualInput,
                }}
              />
              <Input
                type="select"
                name="transmission"
                label="Transmission*"
                settings={{
                  id: 'transmission',
                  placeholder: 'Please select an option',
                  options: CAR_TRANSMISSION_OPTIONS,
                  errors: props.errors,
                  disabled:
                    data.transmission !== undefined &&
                    data.transmission !== '' &&
                    !data.manualInput,
                }}
              />
            </div>

            <div className="mt-4 flex gap-4">
              <Input
                type="select"
                name="driveType"
                label="Drive type*"
                settings={{
                  id: 'driveType',
                  placeholder: 'Please select drive type',
                  options: DRIVE_TYPE_OPTIONS,
                  disabled:
                    data.driveType !== undefined &&
                    data.driveType !== '' &&
                    !data.manualInput,
                }}
              />
              <Input
                type="select"
                name="yearOfManufacture"
                label="Year of manufacture*"
                settings={{
                  id: 'yearOfManufacture',
                  placeholder: 'YYYY',
                  options: yearOptions,
                  disabled:
                    data.yearOfManufacture !== undefined &&
                    data.yearOfManufacture !== '' &&
                    !data.manualInput,
                }}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <Input
                type="datepicker"
                name="regDate"
                label="Registration date*"
                settings={{
                  id: 'regDate',
                  placeholder: 'DD/MM/YYYY',
                  maxDate: new Date(),
                  disabled:
                    data.regDate !== undefined &&
                    data.regDate !== '' &&
                    !data.manualInput,
                }}
              />
              <Input
                type="text"
                name="chassisNo"
                label="VIN"
                settings={{
                  id: 'chassisNo',
                  placeholder: 'Enter vin here',
                  disabled:
                    data.chassisNo !== undefined &&
                    data.chassisNo !== '' &&
                    !data.manualInput,
                }}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <Input
                type="selectAddonText"
                name="mileage"
                label="Car mileage*"
                settings={{
                  id: 'mileage',
                  addonOptions: [
                    { id: '0', label: 'km', value: 'km' },
                    { id: '1', label: 'mi', value: 'mi' },
                  ],
                  placeholder: 'Enter make car mileage',
                  type: 'number',
                }}
              />
              <Input
                type="datepicker"
                name="last_service_date"
                label="Last service date"
                settings={{
                  id: 'last_service_date',
                  placeholder: 'DD/MM/YYYY',
                  maxDate: new Date(),
                }}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <Input
                type="datepicker"
                name="tech_check_date"
                label="MOT date expiration"
                settings={{
                  id: 'MOTDateEpiration',
                  placeholder: 'Enter MOT date expiration here',
                  disabled:
                    data.tech_check_date && !data.manualInput ? true : false,
                }}
              />
              <Input
                type="select"
                name="commercialVehicle"
                label="Commercial vehicle*"
                settings={{
                  id: 'commercialVehicle',
                  placeholder: 'Please select commercial vehicle',
                  options: YES_NO_OPTIONS,
                }}
              />
            </div>
            <div className="mt-4 grid grid-cols-2 gap-4">
              <Input
                type="select"
                name="vehicleCategory"
                label="Vehicle category*"
                settings={{
                  id: 'vehicleCategory',
                  placeholder: 'Please select vehicle category',
                  options: VEHICLE_CATEGORY_OPTIONS,
                }}
              />
              <Input
                type="currency"
                name="purchasePrice"
                label="Purchase price*"
                settings={{
                  id: 'purchasePrice',
                  placeholder: 'Enter purchase price here',
                  currency:
                    props.values?.purchasePrice?.currency ?? appInfo.currency,
                }}
              />
            </div>

            <div className="sticky bottom-0 flex w-full gap-4 bg-white pt-5">
              <Button
                kind="primary"
                type="submit"
                disabled={Object.keys(props.errors).length > 0}
              >
                Continue
              </Button>
              <Button kind="red" type="submit" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default PWCreateNewRegistration;
