import { useContext } from 'react';
import {
  Button,
  CardWrapperWithHeader,
  getStationByKey,
  LoadingSpinner,
  StepsProgress,
} from '@rabbit/elements/shared-components';
import AssignCaseInternalModal from '../../../organisms/case-flow-modals/AssignCaseInternalModal/AssignCaseInternalModal';
import {
  DocumentCheckIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import { CaseflowContext } from '../../../../context/CaseflowContext';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClaimStatusSectionProps {}

export function ClaimStatusSection(props: ClaimStatusSectionProps) {
  const context = useContext(CaseflowContext);
  const { t } = useTranslation();
  const tenantLink = t('tenantLink');

  const {
    caseState,
    caseFacts,
    setModalSettings,
    setShowModal,
    alterCaseFacts,
    executeAction,
  } = context || {};

  const currentStation = getStationByKey(caseState);

  const modalSettingsOptions = {
    assign_case: {
      kind: 'generic' as const,
      settings: {
        title: 'Assign case',
      },
      children: <AssignCaseInternalModal />,
    },
  };

  const steps = [
    {
      Icon: InformationCircleIcon,
      completed: currentStation.id >= 1 ? true : false,
      title: 'Waiting for Review',
      // content: (
      //   <div className="font-nunito mt-2 text-xs text-gray-400">
      //     18/04/2023
      //     <br />
      //     9:30 am
      //   </div>
      // ),
    },
    {
      Icon: DocumentCheckIcon,
      completed: currentStation.id >= 2 ? true : false,
      title: 'Assessment',
      // content: (
      //   <div className="font-nunito mt-2 text-xs text-gray-400">
      //     19/04/2023
      //     <br />
      //     01:27 pm
      //   </div>
      // ),
    },
    {
      Icon: InformationCircleIcon,
      completed: currentStation.id >= 6 ? true : false,
      title: 'Repair/Replace',
      //content: '',
    },
    {
      Icon: DocumentCheckIcon,
      completed: currentStation.id >= 10 ? true : false,
      title: 'Complete',
      //content: '',
    },
  ];

  if (
    !context ||
    !setModalSettings ||
    !setShowModal ||
    !alterCaseFacts ||
    !executeAction
  )
    return <LoadingSpinner size="xs" />;

  return (
    <CardWrapperWithHeader
      title="Claim status"
      headerRight={
        tenantLink === 'SHELTA' && (
          <div className="flex items-center gap-5">
            {/* {context.caseFacts.case_last_updated && (
            <PillTime
              label={timestampToTimeAgo(context.caseFacts.case_last_updated)}
              className={'bg-gray-300 text-gray-900'}
              icon={true}
            />
          )} */}
            <Button
              size="sm"
              kind="primary"
              children={`${
                caseFacts?.delegate_repairer_name !== 'Unassigned'
                  ? 'Assigned to: ' + caseFacts?.delegate_repairer_name
                  : 'Unassigned'
              }`}
              onClick={() => {
                setModalSettings(modalSettingsOptions.assign_case);
                setShowModal(true);
              }}
            />
          </div>
        )
      }
    >
      <div className="px-10">
        <StepsProgress steps={steps} />
      </div>
    </CardWrapperWithHeader>
  );
}

export default ClaimStatusSection;
