import { FunnelIcon } from '@heroicons/react/24/outline';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { AppInfoShape, SELF_REGISTERED_VENDABLE, WarrantyStatus } from '@rabbit/data/types';
import {
  ButtonIcon,
  FiltersModal,
  PillTime,
  Table,
  getClaimLimit,
  getCurrencyFormat,
  getWarrantyTerm,
  timestampToTimeAgo,
} from '@rabbit/elements/shared-components';
import { RegistrationTableColumns } from '@rabbit/sage/utils/RegistrationTableColumns';
import {
  getProductTitleFromHoldingData,
  isValidDate,
  useAppInfo,
} from '@rabbit/sage/utils/helpers';
import ROUTE_NAME from '@rabbit/sage/utils/url-constants';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface TableRegistrationsProps {
  columns?: any;
  isLoading?: boolean;
  tenantType?: string;
  holdingList?: any;
  filters: FormValues;
  onFilter?: (value: FormValues) => void;
  isRegularTenant?: boolean;
}

export interface FormValues {
  voided?: boolean;
  dateType?: string;
  beginDate?: Date | undefined;
  endDate?: Date | undefined;
}

function TableRegistrations({
  isLoading,
  tenantType,
  holdingList,
  filters,
  onFilter,
  isRegularTenant = false,
}: TableRegistrationsProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);

  const tenantLink = t('tenantLink');

  const columns = useMemo(() => RegistrationTableColumns.Default, []);
  const carColumns = useMemo(() => RegistrationTableColumns.CarColumns, []);
  const tableTopColumns = useMemo(() => RegistrationTableColumns.TableTop, []);

  const getColumns = () => {
    switch (tenantType) {
      case 'carTenants':
        {
          /* // Price has been temporarily removed per DEV-1128 request -VP */
        }
        if (tenantLink === 'WARRANTYIRELAND') {
          return isRegularTenant
            ? carColumns.filter(
                (column: any) =>
                  column.accessorKey !== 'partner' &&
                  column.accessorKey !== 'price'
              )
            : carColumns.filter(
                (column: any) =>
                  column.accessorKey !== 'salesPerson' &&
                  column.accessorKey !== 'price'
              );
        } else {
          return isRegularTenant
            ? carColumns.filter(
                (column: any) => column.accessorKey !== 'partner'
              )
            : carColumns.filter(
                (column: any) => column.accessorKey !== 'salesPerson'
              );
        }
      case 'tableTenants':
        return isRegularTenant
          ? tableTopColumns.filter(
              (column: any) => column.accessorKey !== 'partner'
            )
          : tableTopColumns;
      default:
        return isRegularTenant
          ? columns.filter((column: any) => column.accessorKey !== 'partner')
          : columns;
    }
  };

  const onChangeFilters = (value: {
    beginDate?: Date | undefined;
    endDate?: Date | undefined;
  }) => {
    setIsFilterModalOpen(false);
    if (onFilter) {
      onFilter(value);
    }
  };

  const appInfo = useAppInfo();
  const CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT = t('CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT') === "true";

  useEffect(() => {
    if (!isLoading && holdingList.data) {
      const getTableData = () => {
        switch (tenantType) {
          case 'carTenants':
            return formatCarResults(holdingList?.data, appInfo, CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT).filter(
              (i) => i !== null
            );
          case 'tableTenants':
            return formatTableCoverResults(holdingList?.data).filter(
              (i) => i !== null
            );
          default:
            return formatResults(holdingList?.data).filter((i) => i !== null);
        }
      };
      setTableData(getTableData());
    }
  }, [holdingList.data, isLoading]);

  return (
    <div className="w-full">
      <div className="relative z-10 flex items-center justify-between w-full">
        <div className="absolute top-[25px] right-5">
          {!isLoading && (
            <ButtonIcon
              label={'Filters'}
              iconLeft={true}
              Icon={FunnelIcon}
              onClick={() => setIsFilterModalOpen(true)}
              kind={'bgLightGreen'}
              count={
                (filters && filters.beginDate) || (filters && filters.endDate)
                  ? 1
                  : 0
              }
            />
          )}
        </div>
        {isFilterModalOpen && (
          <div className="absolute right-0 z-10 top-16">
            <FiltersModal
              page={'registrations'}
              handleClose={() => setIsFilterModalOpen(false)}
              data={filters}
              onChange={onChangeFilters}
            />
          </div>
        )}
      </div>
      <div className="relative z-0">
        <Table
          columns={getColumns()}
          data={tableData}
          initialState={{
            columnPinning: { right: ['actions'] },
            showGlobalFilter: true,
            sorting: [
              {
                id: 'timeStamp',
                desc: false,
              },
            ],
          }}
          muiSearchTextFieldProps={{
            placeholder: 'Search by registration ID',
          }}
          useCallbackClickRow={(data: {
            warrantyId: string;
            product: string;
            registrationPlate: string;
            make: string;
            link: string;
            id: string;
          }) =>
            navigate(ROUTE_NAME.REGISTRATIONS + '/' + data.link, {
              state: {
                warrantyId:
                  tenantType === 'tableTenants' ? data.id : data.warrantyId,
                title:
                  tenantType === 'carTenants'
                    ? data.make + ' ' + data.registrationPlate
                    : data.product,
                link: data.link,
              },
            })
          }
          state={{
            isLoading: isLoading,
          }}
        />
      </div>
    </div>
  );
}

export default TableRegistrations;

/** HELPERS **/

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const formatResults = (results: any[]) => {
  const formattedResults = results.map((result, i) => {
    if (result.vendable === SELF_REGISTERED_VENDABLE) return null;
    return {
      timeStamp: (
        <PillTime
          label={
            result.register_time
              ? timestampToTimeAgo(result?.register_time)
              : ''
          }
          className={'text-gray-900 bg-gray-300'}
        />
      ),
      warrantyId: result.docid.slice(0, 5).toUpperCase(),
      registered: isValidDate(new Date(result?.register_time))
        ? result.register_time
        : undefined,
      expiring:
        result?.purchase_time && result?.purchase_time > 0
          ? result.purchase_time
          : undefined,
      term: getWarrantyTerm(result),
      product: getProductTitleFromHoldingData(result),
      modelNum: result?.mpn && result?.mpn !== 'n/a' ? result.mpn : '-',
      serialNum: result.serial ? result.serial : '-',
      purchaseDate:
        result?.purchase_time && result?.purchase_time > 0
          ? result?.purchase_time
          : undefined,
      extended: 'No', //TODO
      // actions: (
      //   <div className="flex items-center justify-center">
      //     <TrashIcon
      //       className="w-5 h-5 cursor-pointer"
      //       onClick={() => {
      //         setdeleteRegistration(!deleteRegistration);
      //         // onDeleteRegistration(result.docid);
      //       }}
      //     />
      //   </div>
      // ),
      link: result.docid,
    };
  });
  return formattedResults;
};

const formatCarResults = (results: any[], appInfo: AppInfoShape, CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT: boolean) => {
  return results.map((result, i) => {
    const vehicleInfo =
      result.self_registration?.srvInfo?.productInfo ??
      result.self_registration?.vehicleInfo; // TODO: make it more flexible for other tenants as part of DEV-528
    const warrantyInfo = BL_Warranty.getLatestWarranty(result.warranties);
    const voided = warrantyInfo?.status === WarrantyStatus.VOIDED;
    return {
      timeStamp: (
        <PillTime
          label={
            result.tcreate
              ? timestampToTimeAgo(new Date(result?.tcreate).getTime())
              : ''
          }
          className={'bg-gray-300 text-gray-900'}
        />
      ),
      warrantyId: result.docid.slice(0, 5).toUpperCase(),
      registrationPlate: vehicleInfo?.registrationNo || '-',
      make: vehicleInfo.brand || '-',
      registered: result.tcreate,
      start: warrantyInfo?.startDate,
      expiring: warrantyInfo?.endDate,
      warrantyPlan: voided ? 'voided' : warrantyInfo?.templateTitle || '-',
      term: voided ? '-' : getWarrantyTerm(result),
      claimLimit: voided ? '-' : getClaimLimit(warrantyInfo, appInfo.currency, CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT) || '-',
      partner: warrantyInfo?.retailerName,
      salesPerson: warrantyInfo?.salesRep || '',
      price:
        result.purchase_price && warrantyInfo?.price
          ? getCurrencyFormat(
              warrantyInfo?.price?.amount,
              warrantyInfo?.price?.currency
            ) +
            ` (${getCurrencyFormat(
              warrantyInfo?.price?.amount * 1.2,
              warrantyInfo?.price?.currency
            )} incl. VAT)`
          : '-',
      link: result.docid,
      customerName: result.consumer_name || '-',
    };
  });
}

const formatTableCoverResults = (results: any[]) =>
  results.map((result, i) => {
    const warrantyInfo = BL_Warranty.getLatestWarranty(result.warranties);
    const productInfo = result.self_registration.srvInfo.productInfo;
    const voided = warrantyInfo?.status === WarrantyStatus.VOIDED;
    return {
      timeStamp: (
        <PillTime
          label={
            result.created_time
              ? timestampToTimeAgo(new Date(result?.created_time).getTime())
              : ''
          }
          className={'bg-gray-300 text-gray-900'}
        />
      ),
      id: result.docid.slice(0, 5).toUpperCase(),
      product: productInfo.typeofStone,
      area: productInfo.surfaceAreaValue
        ? productInfo.surfaceAreaValue + 'm²'
        : '-',
      registered: result.tcreate,
      start: warrantyInfo?.startDate,
      expiring: warrantyInfo?.endDate,
      warrantyPlan: voided
        ? 'voided'
        : warrantyInfo?.templateTitle?.replace(' warranty', '') || '-',
      term: voided ? '-' : getWarrantyTerm(result),
      partner: warrantyInfo?.retailerName,
      salesperson: result?.self_registration?.srvInfo?.salesRep || '-',
      price: result.purchase_price
        ? getCurrencyFormat(
            result.purchase_price?.amount,
            result.purchase_price?.currency
          )
        : '-',
      link: result.docid,
      customerName: result.consumer_name || '-',
    };
  });
