import {
  LoadingSpinner,
  AuthContainer,
} from '@rabbit/elements/shared-components';
import { NavLink, useNavigate } from 'react-router-dom';
import ROUTE_NAME from '../utils/url-constants';
import { useState } from 'react';
import ForgotPasswordForm from '../components/templates/ForgotPasswordForm/ForgotPasswordForm';
import { useAppInfo } from '../utils/helpers';

function ForgotPasswordView() {
  const appInfo = useAppInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [authErrorMsg, setAuthErrorMsg] = useState('');

  const navigate = useNavigate();
  if (isLoading) return <LoadingSpinner extraClasses={'mt-2'} size={'lg'} />;

  return (
    <div className="flex flex-grow flex-col justify-around">
      <AuthContainer
        title="Reset password"
        goBack={() => navigate(ROUTE_NAME.LOGIN)}
        appInfo={appInfo}
      >
        <div>
          <ForgotPasswordForm
            authErrorMsg={authErrorMsg}
            setAuthErrorMsg={setAuthErrorMsg}
          />
          {/* <div className="font-nunito my-4 text-base">
            Don't have an account? &nbsp;
            <NavLink
              to={ROUTE_NAME.SIGNUP}
              className="text-primary-600"
              reloadDocument
            >
              Create one
            </NavLink>
          </div> */}
        </div>
      </AuthContainer>
    </div>
  );
}

export default ForgotPasswordView;
