import {
  LoadingSpinner,
  TabsGeneric,
} from '@rabbit/elements/shared-components';
import InformationTab from '../components/molecules/account-management/InformationTab';
import LegalTab from '../components/molecules/account-management/LegalTab';
import { useManageSageProfile } from '@rabbit/bizproc/react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AccountManagementViewProps {}

export function AccountManagementView(props: AccountManagementViewProps) {
  const { currentData, userActivePersonas } = useManageSageProfile();

  if (
    !currentData.identity ||
    (!currentData.repairer_private && !currentData.retailer_private)
  ) {
    return <LoadingSpinner size="sm" />;
  }

  let currentUser = currentData[`${userActivePersonas[0]}_public`] || undefined;
  if (!currentUser.email) {
    currentUser.email = currentData.identity_private.email;
  }

  const accountTabs = [
    {
      label: 'Information',
      children: <InformationTab user={currentUser} kind="user" />,
    },
    {
      label: 'Legal',
      children: <LegalTab />,
    },
  ];
  return (
    <div className="px-2">
      <div className="pb-4">
        <TabsGeneric items={accountTabs} hugTabs={true} />
      </div>
    </div>
  );
}

export default AccountManagementView;
