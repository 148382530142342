import { useState } from 'react';
import {
  ButtonIcon,
  Modal,
  Table,
  formatPhoneNumber,
  formatUnixTime,
} from '@rabbit/elements/shared-components';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAME from '../../../utils/url-constants';
import { useGetSagePartners } from '@rabbit/bizproc/react';
import { DTBusiness_User_Invite, DTTenant_Public } from '@rabbit/data/types';
import {
  formatAddress,
  getDefaultAddress,
  getPartnerLabelByValue,
  getUserTypeFromPrincipals,
  useAppInfo,
} from '../../../utils/helpers';
import { ModalNewEditPartner } from '../../organisms/ModalNewEditPartner/ModalNewEditPartner';
import { useTranslation } from 'react-i18next';

export interface FormValues {
  beginDate: Date | null;
  endDate: Date | null;
}

export const tableheaders = [
  {
    header: 'name',
    accessorKey: 'name',
    size: 150,
  },
  {
    header: 'email',
    accessorKey: 'email',
    size: 15,
  },
  {
    header: 'phone',
    accessorKey: 'phone',
    size: 150,
  },
  {
    header: 'address',
    accessorKey: `address`,
    size: 150,
    Cell: ({ cell }: any) => {
      if (
        cell.getValue() &&
        Array.isArray(cell.getValue()) &&
        Array(cell.getValue().length)
      ) {
        return (
          <div className="text-sm">{formatAddress(cell.getValue()[0])}</div>
        );
      } else return '-';
    },
    // Cell: ({
    //   row,
    // }: {
    //   row: {
    //     original: {
    //       shippingAddress: {
    //         street: string;
    //         city: string;
    //         postcode: string;
    //         country: string;
    //       };
    //     };
    //   };
    // }) => (
    //   <>
    //     <p>{row.original.shippingAddress.street}</p>
    //     <p>{row.original.shippingAddress.city}</p>
    //     <p>
    //       {row.original.shippingAddress.postcode}
    //       {row.original.shippingAddress.country}
    //     </p>
    //   </>
    // ),
  },
  {
    header: 'type',
    accessorKey: 'type',
    size: 100,
  },
  {
    header: 'status',
    accessorKey: 'status',
    size: 20,
  },
  {
    header: 'date modified',
    accessorKey: 'date_modified',
    size: 20,
  },
  {
    header: '',
    title: 'actions',
    accessorKey: 'edit',
    size: 20,
  },
];

export function PartnersTab() {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isNewEditModalOpen, setIsNewEditModalOpen] = useState<boolean>(false);
  const [partnerDetails, setPartnerDetails] = useState<
    DTBusiness_User_Invite | DTTenant_Public
  >();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>();

  const { partnersList } = useGetSagePartners(t('tenantLink')) || [];

  const handleEditModal = (
    title: string,
    partnerDetails?: DTBusiness_User_Invite | DTTenant_Public
  ) => {
    setModalTitle(title);
    setPartnerDetails(partnerDetails);
    setIsNewEditModalOpen(true);
  };

  const addEditModalSettings = {
    title: modalTitle,
    handleClose: () => setIsNewEditModalOpen(false),
  };

  const confirmationModalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: 'You are about to delete this partner. You will lose all information. Are you sure you want to continue?',
      primaryButtonText: 'Yes',
      outlineButtonText: 'No, cancel',
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () =>
        console.log('delete --> selectedRowToDelete'),
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  const sortData = (data: DTBusiness_User_Invite[] | DTTenant_Public[]) => {
    return data.sort(
      (
        a: DTBusiness_User_Invite | DTTenant_Public,
        b: DTBusiness_User_Invite | DTTenant_Public
      ) => {
        return a.orgName && b.orgName && a.orgName > b.orgName ? 1 : -1;
      }
    );
  };

  const formatResultsForPartnersTable = () => {
    let dataList = partnersList ? sortData(partnersList) : [];

    if (appInfo.environment === 'LIVE') {
      dataList =
        partnersList?.filter(
          (result: DTBusiness_User_Invite | DTTenant_Public) =>
            result?.email &&
            !result.email?.includes('@iwarranty.co') &&
            !result?.email?.includes('@studiographene.com')
        ) ?? [];
    }

    if (partnersList?.length)
      return dataList.map(
        (result: DTBusiness_User_Invite | DTTenant_Public, i: number) => {
          return {
            id: result?.docid,
            name: result.orgName ?? '-',
            email: result?.email,
            phone: formatPhoneNumber(result?.phone),
            address: getDefaultAddress(result?.addresses ?? []),
            type: (result as DTBusiness_User_Invite)?.personas
              ? getPartnerLabelByValue(
                  (result as DTBusiness_User_Invite).personas[0],
                  t('tenantLink')
                )
              : getPartnerLabelByValue(
                  getUserTypeFromPrincipals(
                    (result as DTTenant_Public)?.principals
                  ),
                  t('tenantLink')
                ) ?? '-',
            status: (result as DTBusiness_User_Invite)?.status
              ? (result as DTBusiness_User_Invite).status
                  .charAt(0)
                  .toUpperCase() +
                (result as DTBusiness_User_Invite).status.slice(1)
              : 'Active',
            date_modified: result.tupdate
              ? result.tcreate !== result.tupdate
                ? formatUnixTime(result.tupdate, 'dd/MM/yyyy')
                : ''
              : '',
            // Only enabling editing for partners with status 'Active' for now
            edit: (result as DTBusiness_User_Invite)?.status ? (
              <div className="flex justify-end gap-2 px-2 text-right">
                <PencilSquareIcon
                  className="text-primary-900 h-[20px] w-[20px] opacity-50"
                  // onClick={() => {
                  //   handleEditModal('Edit partner', result);
                  // }}
                />
              </div>
            ) : (
              <div className="flex justify-end gap-2 px-2 text-right">
                <PencilSquareIcon
                  className="text-primary-900 h-[20px] w-[20px]"
                  onClick={() => {
                    handleEditModal('Edit partner', result);
                  }}
                />
              </div>
            ),
          };
        }
      );
    return dataList;
  };

  return (
    <>
      <div className="relative z-10 flex w-full items-center justify-between py-4">
        <div className="absolute top-[60px] right-5">
          <div>
            <ButtonIcon
              label={'Add partner'}
              iconLeft={true}
              onClick={() => handleEditModal('Add partner')}
            />
          </div>
        </div>
      </div>
      <Table
        columns={tableheaders}
        data={formatResultsForPartnersTable()}
        initialState={{
          columnPinning: { right: ['actions'] },
          showGlobalFilter: true,
        }}
        muiSearchTextFieldProps={{
          placeholder: 'Search email address or partner name',
          sx: {
            minWidth: '600px',
            borderRadius: '20px',
            backgroundColor: '#fff',
            margin: '5px 0',
            '& .MuiOutlinedInput-root': {
              '& > fieldset': { borderRadius: '10px' },
            },
          },
          variant: 'outlined',
          margin: 'none',
        }}
        useCallbackClickRow={(data: {
          shippingAddress: {
            street: string;
            city: string;
            postcode: string;
          };
          productsRegistered: string;
          phone: string;
          email: string;
          name: string;
          id: string;
        }) =>
          navigate(ROUTE_NAME.MANAGE + '/partner/' + data.id, {
            state: { docid: data.id, productName: data.name },
          })
        }
        state={{
          isLoading: !partnersList,
        }}
      />
      {isNewEditModalOpen && (
        <div>
          <ModalNewEditPartner
            handleClose={() => setIsNewEditModalOpen(false)}
            handleDelete={() => setIsConfirmationModalOpen(true)}
            partner={partnerDetails}
            modalSettings={addEditModalSettings}
          />
          {isConfirmationModalOpen && (
            <Modal
              className="h-fit w-[350px] rounded-md bg-white"
              kind={confirmationModalSettings.kind}
              settings={confirmationModalSettings.settings}
            />
          )}
        </div>
      )}
    </>
  );
}

export default PartnersTab;
