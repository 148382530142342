import { TenantType } from "@rabbit/sage/views/RegistrationsView"
import { Dispatch, SetStateAction } from "react"
import CreateNewConsumerDetails from "../forms/CreateNewConsumerDetails/CreateNewConsumerDetails"
import CreateNewCoverageOptions from "../forms/CreateNewCoverageOptions/CreateNewCoverageOptions"
import CreateNewRegistration from "../forms/CreateNewRegistration/CreateNewRegistration"
import { CreateNewRegistrationSummary } from "../forms/CreateNewRegistrationSummary/CreateNewRegistrationSummary"
import CreateNewSelectCar from "../forms/CreateNewSelectCar/CreateNewSelectCar"
import CreateNewSelectStone from "../forms/CreateNewSelectStone/CreateNewSelectStone"
import CreateNewStoneCoverageOptions from "../forms/CreateNewStoneCoverageOptions/CreateNewStoneCoverageOptions"
import { CreateNewRegistrationStoneSummary } from "../forms/CreateNewStoneRegistrationSummary/CreateNewStoneRegistrationSummary"
import NewRegistrationDetailsForm from "../forms/NewRegistrationDetailsForm/NewRegistrationDetailsForm"
import PWCreateNewCoverageOptions from "./pinnacle-warranties/PWCreateNewCoverageOptions"
import PWCreateNewRegistration from "./pinnacle-warranties/PWCreateNewRegistration"
import { PWCreateNewRegistrationSummary } from "./pinnacle-warranties/PWCreateNewRegistrationSummary"
import PWNewRegistrationDetailsForm from "./pinnacle-warranties/PWNewRegistrationDetailsForm"

interface RenderProps {
  currentStep: {
    step: number, data: any
  }
  onChangeStep: (step_in: number, data: any) => void;
  handleClose: () => void;
  tenantType: TenantType;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const RenderWarrantyIrelandFlow = ({
  onChangeStep,
  currentStep,
  handleClose,
  setIsLoading,
  tenantType
}: RenderProps) => {
  return (
    <>
      {currentStep.step === 0 && <CreateNewSelectCar
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
        setIsLoading={loading => setIsLoading(loading)}
      />}
      {currentStep.step === 1 && <CreateNewRegistration
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
      />}
      {currentStep.step === 2 && <NewRegistrationDetailsForm
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
      />}
      {currentStep.step === 3 && <CreateNewCoverageOptions
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
        setIsLoading={setIsLoading}
      />}
      {currentStep.step === 4 && <CreateNewConsumerDetails
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
        tenantType={tenantType}
      />}
      {currentStep.step === 5 && <CreateNewRegistrationSummary
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
        setIsLoading={setIsLoading}
      />}
    </>
  )
}

export const RenderNuCoverFlow = ({
  onChangeStep,
  currentStep,
  handleClose,
  setIsLoading,
  tenantType
}: RenderProps) => {
  return (
    <>
      {currentStep.step === 0 && <CreateNewSelectStone
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
        setIsLoading={loading => setIsLoading(loading)}
      />}
      {currentStep.step === 1 && <CreateNewStoneCoverageOptions
        onChange={onChangeStep}
        data={currentStep.data}
        setIsLoading={setIsLoading}
        handleClose={handleClose} />}
      {currentStep.step === 2 && <CreateNewConsumerDetails
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
        tenantType={tenantType}
      />}
      {currentStep.step === 3 && <CreateNewRegistrationStoneSummary
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
        setIsLoading={setIsLoading}
      />}
    </>
  )
}

export const RenderPinnacleWarrantiesFlow = ({
  onChangeStep,
  currentStep,
  handleClose,
  setIsLoading,
  tenantType
}: RenderProps) => {
  return (
    <>
      {currentStep.step === 0 && <CreateNewSelectCar
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
        setIsLoading={loading => setIsLoading(loading)}
      />}
      {currentStep.step === 1 && <PWCreateNewRegistration
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
      />}
      {currentStep.step === 2 && <PWNewRegistrationDetailsForm
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
      />}
      {currentStep.step === 3 && <PWCreateNewCoverageOptions
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
        setIsLoading={setIsLoading}
      />}
      {currentStep.step === 4 && <CreateNewConsumerDetails
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
        tenantType={tenantType}
      />}
      {currentStep.step === 5 && <PWCreateNewRegistrationSummary
        onChange={onChangeStep}
        data={currentStep.data}
        handleClose={handleClose}
        setIsLoading={setIsLoading}
      />}
    </>
  )
}
