import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Input,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
// Missing. Necessary?
//import { ClaimDetailsData } from '../../../molecules/claims/ClaimDetailsSection/ClaimDetailsSection';
import {
  CLAIM_STATUS_OPTIONS,
  COVERAGE_OPTIONS,
  ConsumerIssueTypeHookOrigins,
  LIST_COUNTRIES,
  ReactCaseFlowCase,
  useGetSagePartners,
  useManageConsumerIssueTypes,
  useManageFaults,
} from '@rabbit/bizproc/react';
import ClaimDetailEditor from '../../../../utils/ClaimDetailEditor';
import { FBD_Retailer_Public, FBD_Vendable } from '@rabbit/data/types';
import { fromUnixTime } from 'date-fns';
import { useAppInfo } from 'apps/sage/src/utils/helpers';
import { useTranslation } from 'react-i18next';
import { OurDateTime } from '@rabbit/utils/ts';

export interface UpdateClaimDetailsFormProps {
  claimDetails: any; // todo type
  handleClose: () => void;
  caseFlowCase: ReactCaseFlowCase;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export interface FormValuesShape {
  holding_vendable_id: string;
  consumer_holding_purchase_location: {
    country: string;
    docid: string;
  } | null;
  store_not_listed: boolean;
  consumer_holding_purchase_location_other: string;
  consumer_holding_purchase_country: string;
  consumer_holding_purchase_price: { amount: string; currency: string };
  purchase_date: Date | number; // todo:recheck right now it's a date or 0.
  status: string;
  holding_warranty_term: number;
  holding_faults: string[];
  preliminary_assessment: string;
  consumer_issue_description: string;
  consumer_issue_type_ref: string;
}

export const validationSchema = Yup.object().shape({
  holding_vendable_id: Yup.string().trim(),
  consumer_holding_purchase_location: Yup.mixed(),
  store_not_listed: Yup.boolean(),
  consumer_holding_purchase_price: Yup.mixed(),
  consumer_holding_purchase_country: Yup.string().trim(),
  purchase_date: Yup.mixed(),
  status: Yup.string(),
  holding_warranty_term: Yup.number(),
  holding_faults: Yup.array(),
  preliminary_assessment: Yup.string().trim(),
  consumer_issue_description: Yup.string().trim(),
  consumer_issue_type_ref: Yup.string().trim(),
});

export function UpdateClaimDetailsForm({
  claimDetails,
  handleClose,
  caseFlowCase,
  setLoading,
}: UpdateClaimDetailsFormProps) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const claimsFlowLite = t('CFG_CLAIMS_FLOW') === 'lite';
  const { faultList } = useManageFaults();

  const faultOptions = !faultList?.data
    ? []
    : faultList.data.map((fault) => ({
        id: fault.docid,
        value: fault.docid,
        label: fault.title,
        name: fault.title,
      }));

  const { consumerIssueTypeList } = useManageConsumerIssueTypes(
    ConsumerIssueTypeHookOrigins.SAGE
  );
  const consumerIssueTypeOptions = !consumerIssueTypeList?.data
    ? []
    : consumerIssueTypeList.data.map((issue) => ({
        id: issue.docid,
        value: issue.docid,
        label: issue.label,
      })) ?? [];

  const [isSubmitting, setIsSubmitting] = useState(false);
  const updateSubmitting = (state: boolean) => {
    setIsSubmitting(state);
    setLoading(state);
  };

  const [initialValues, setInitialValues] = useState<FormValuesShape>({
    holding_vendable_id:
      (claimDetails && claimDetails.holding_vendable_id) || '',
    consumer_holding_purchase_location: null,
    purchase_date: claimDetails?.purchase_date
      ? OurDateTime.timestampToDate(claimDetails?.purchase_date)
      : 0,
    holding_warranty_term:
      claimDetails?.holding_warranty_term?.amount ?? undefined,
    preliminary_assessment:
      (claimDetails && claimDetails.preliminary_assessment) || '',
    // fault_category: claimDetails?.fault_category ?? '',  // disabled until fault categories are implemented
    holding_faults: claimDetails?.holding_faults ?? [],
    status: caseFlowCase.GetCaseState(),
    consumer_holding_purchase_price: {
      amount: claimDetails?.consumer_holding_purchase_price?.amount || '',
      currency: claimDetails?.consumer_holding_purchase_price?.currency || '',
    },
    consumer_issue_description: claimDetails?.consumer_issue_description || '',
    consumer_issue_type_ref: claimDetails?.consumer_issue_type_ref || '',
    store_not_listed: claimDetails.consumer_holding_purchase_location_other
      ? true
      : false,
    consumer_holding_purchase_country:
      claimDetails.consumer_holding_purchase_country || '',
    consumer_holding_purchase_location_other:
      claimDetails.consumer_holding_purchase_location_other ?? '',
  });

  const [vendableLabel, setVendableLabel] = useState('Loading...');
  const [locationLabel, setLocationLabel] = useState('Loading...');

  useEffect(() => {
    const getVendableLabel = async (vendableId: string) => {
      if (!vendableId) return undefined;
      const vendable = await FBD_Vendable.get(vendableId || '');
      return vendable?.title ?? undefined;
    };

    const getRetailerLabel = async (locationId: string) => {
      if (!locationId) return '';
      const retailer = await FBD_Retailer_Public.get(locationId || '');
      const town = retailer?.address?.town || '';
      if (town) {
        return retailer?.name ? retailer?.name : '' + ' - ' + town;
      } else {
        return retailer?.name ? retailer?.name : '';
      }
    };

    (async () => {
      if (claimDetails?.holding_vendable_id) {
        if (claimDetails.holding_vendable_id !== 'SRV') {
          const vendableLabel = await getVendableLabel(
            claimDetails?.holding_vendable_id || ''
          );

          setVendableLabel(vendableLabel ?? 'Product name');
        } else {
          const holdingBrand = claimDetails?.srvProductInfo?.brand || '';
          const holdingName =
            claimDetails?.consumer_holding_name || 'Product name';
          // Concatenate brand and name with a space in between
          const fullProductName = `${holdingBrand} ${holdingName}`.trim();
          setVendableLabel(fullProductName);
        }
      } else {
        setVendableLabel('Product name');
      }

      if (claimDetails.consumer_holding_purchase_location) {
        const locationLabel = await getRetailerLabel(
          claimDetails.consumer_holding_purchase_location || ''
        );
        setLocationLabel(locationLabel ?? 'Enter store name & location');
      } else {
        setLocationLabel('Enter store name & location');
      }
    })().catch((err) => console.log(err));
  }, [
    claimDetails,
    claimDetails.consumer_holding_purchase_location,
    claimDetails.holding_vendable_id,
  ]);

  const onSubmit = () => {
    updateSubmitting(true);
  };

  if (!faultList?.data || !consumerIssueTypeList?.data)
    return <LoadingSpinner size="sm" />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, errors }) => (
        <Form>
          <div className="flex flex-col gap-4">
            {claimsFlowLite && (
              <>
                <Input
                  type="select"
                  label="Claim status"
                  name="status"
                  settings={{
                    options: CLAIM_STATUS_OPTIONS,
                    id: 'status',
                    placeholder: 'Please select an option',
                  }}
                />
                <hr className="my-4 border-t border-gray-200" />
              </>
            )}
            <div className="flex items-start gap-4">
              <div className="w-1/3 shrink-0 grow">
                <Input
                  type="autocomplete-vendable"
                  name="holding_vendable_id"
                  label="Product"
                  settings={{
                    isMulti: false,
                    id: 'holding_vendable_id',
                    placeholder: vendableLabel,
                    options: [],
                    errors: errors,
                    tenantLink: t('tenantLink'),
                    disabled: true,
                  }}
                />
              </div>
              {!claimsFlowLite && (
                // location and store not visible for lite flow -VP
                <div className="flex flex-col w-1/3 shrink-0 grow">
                  <Input
                    type="autocomplete-location-retailer"
                    name="consumer_holding_purchase_location"
                    label="Purchase location"
                    settings={{
                      id: 'consumer_holding_purchase_location',
                      placeholder: locationLabel,
                      options: [],
                      isMulti: false,
                      errors: errors,
                      disabled: values.store_not_listed,
                    }}
                  />
                  <Input
                    type="checkbox"
                    name="store_not_listed"
                    settings={{
                      checkboxLabel: 'Store is not listed',
                      checkboxLabelStyles: 'text-base text-gray-500',
                    }}
                  />
                </div>
              )}
            </div>
            {values.store_not_listed && !claimsFlowLite && (
              <div className="grid grid-cols-2 gap-4">
                <Input
                  type="text"
                  label="Purchase location"
                  name="consumer_holding_purchase_location_other"
                  settings={{
                    id: 'consumer_holding_purchase_location_other',
                    placeholder: 'Enter store name',
                  }}
                />
                <Input
                  type="select"
                  label="Purchase country"
                  name="consumer_holding_purchase_country"
                  settings={{
                    options: LIST_COUNTRIES,
                    id: 'consumer_holding_purchase_country',
                    placeholder: 'The country where the product was purchased',
                  }}
                />
              </div>
            )}
            <div className="flex items-end gap-4">
              <Input
                type="datepicker"
                name="purchase_date"
                label="Date of purchase"
                settings={{
                  id: 'purchase_date',
                  maxDate: new Date(),
                  placeholder: 'Date of purchase',
                  disabled: true,
                }}
              />
              <Input
                type="warranty_term"
                name="holding_warranty_term"
                label="Warranty term"
                settings={{
                  id: 'holding_warranty_term',
                  placeholder: '',
                  disabled: true,
                }}
              />
            </div>
            <hr className="my-4 border-t border-gray-200" />
            <div className="flex items-start gap-4">
              {/*
              // disabled until fault categories are implemented
              <Input
                type="select"
                label="Fault category"
                name="fault_category"
                settings={{
                  options: FAULT_TYPE,
                  placeholder: 'Product defect',
                  id: 'fault_category',
                  errors: errors,
                }}
              /> */}
              <Input
                type="select"
                label="Faults"
                name="holding_faults"
                settings={{
                  options: faultOptions,
                  isMulti: true,
                  id: 'holding_faults',
                  errors: errors,
                }}
              />
            </div>
            <div className="items-end grid grid-cols-2 gap-4">
              <Input
                type="select"
                label="Warranty assessment"
                name="preliminary_assessment"
                settings={{
                  options: COVERAGE_OPTIONS,
                  placeholder: 'Please select an option',
                  id: 'preliminary_assessment',
                  errors: errors,
                }}
              />
              {/*<Input
                type="currency"
                name="consumer_holding_purchase_price"
                label="Purchase price"
                settings={{
                  id: 'consumer_holding_purchase_price',
                  placeholder: 'Purchase amount',
                  currency:
                    values?.consumer_holding_purchase_price?.currency ??
                    appInfo.currency,
                }}
                />*/}
            </div>
            <div className="flex items-start">
              <Input
                type="select"
                name="consumer_issue_type_ref"
                label="Customer issue type"
                settings={{
                  isMulti: false,
                  id: 'consumer_issue_type_ref',
                  placeholder: 'Please select',
                  options: consumerIssueTypeOptions,
                  errors: errors,
                }}
              />
            </div>
            <div className="flex flex-col w-full">
              <Input
                type="rich-text"
                name="consumer_issue_description"
                label="Customer issue description"
                settings={{
                  id: 'consumer_issue_description',
                  placeholder: 'Write text here...',
                  allowSpecialCharacter: true,
                  disabled: claimDetails?.consumer_issue_description
                    ? true
                    : false,
                }}
              />
            </div>
            <div className="flex gap-4">
              <Button
                kind="primary"
                type="submit"
                loading={isSubmitting}
                disabled={errors ? false : true}
                className="bg-primary-600"
              >
                Update claim
              </Button>
              <Button
                kind="red"
                type="button"
                loading={isSubmitting}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
          {isSubmitting && caseFlowCase && (
            <ClaimDetailEditor
              caseFlowCase={caseFlowCase}
              target={'claim_details'}
              formData={{
                ...values,
                consumer_holding_purchase_location:
                  values.consumer_holding_purchase_location ?? null,
                consumer_issue_type:
                  consumerIssueTypeOptions.find(
                    (option) => option.id === values.consumer_issue_type_ref
                  )?.label ?? '',
                purchase_date:
                  values.purchase_date &&
                  typeof values.purchase_date !== 'number'
                    ? OurDateTime.dateToTimestamp(values.purchase_date)
                    : 0,
              }}
              setIsSubmitting={updateSubmitting}
              onComplete={() => {
                handleClose();
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default UpdateClaimDetailsForm;
