import {
  PencilIcon,
  TrashIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/solid';
import { DocumentModalShape, LogClaimEditShape } from './ClaimCostsSection';
import { LogForms } from '@rabbit/sage/components/organisms/claim-costs-modals/ClaimCostModal/ClaimCostModal';

const actionButtons =
  (
    setEditModal: (arg: LogClaimEditShape) => void,
    name: LogForms<any>['name']
  ) =>
  ({ cell }: any) => {
    return (
      <div className="flex cursor-pointer justify-end gap-8 text-right">
        <PencilIcon
          className="text-primary-600 h-[20px] w-[20px]"
          onClick={() => {
            setEditModal({
              data: cell.row.original.data,
              index: cell.row.original.data.index,
              name,
              remove: false,
            });
          }}
        />
        <TrashIcon
          className="h-[20px] w-[20px] text-red-700"
          onClick={() => {
            setEditModal({
              data: cell.row.original.data,
              index: cell.row.original.data.index,
              name,
              remove: true,
            });
          }}
        />
      </div>
    );
  };

const attachmentsButtons =
  (
    setDocumentModal: (opts: DocumentModalShape) => void,
    name: LogForms<any>['name']
  ) =>
  ({ cell }: any) => {
    const value = cell.getValue() || [];
    return (
      <div>
        <span
          onClick={(e) => {
            e.preventDefault();
            setDocumentModal({
              name: name,
              index: cell.row.original.data.index,
              data: cell.row.original.data,
            });
          }}
          className="flex cursor-pointer justify-start gap-2 whitespace-nowrap text-red-500"
        >
          {value.length > 0 ? (
            <>
              {value.length} {value.length > 1 ? 'files' : 'file'} uploaded
            </>
          ) : (
            <span className="text-black">Upload attachments</span>
          )}
          <ArrowTopRightOnSquareIcon className="h-4 w-4 text-black" />
        </span>
      </div>
    );
  };

export const litePartsUsedColumns = (
  setEditModal: (args: LogClaimEditShape) => void
) => [
  {
    header: 'part name',
    accessorKey: 'part_name',
    size: 30,
  },
  {
    header: 'manufacturer',
    accessorKey: 'manufacturer',
    size: 20,
  },
  {
    header: 'id',
    accessorKey: 'model_id',
    size: 20,
  },
  {
    header: 'qty',
    accessorKey: 'parts_quantity',
    size: 10,
  },
  {
    header: 'cost',
    accessorKey: 'parts_cost',
    size: 25,
  },
  {
    header: 'vat',
    accessorKey: 'parts_cost_vat',
    size: 30,
  },
  {
    header: '',
    title: 'actions',
    accessorKey: 'actions',
    enableSorting: false,
    size: 15,
    Cell: actionButtons(setEditModal, 'parts-used'),
  },
];

export const liteAdministrativeTimeColumns = (
  setEditModal: (args: any) => void,
  currencySymbol: string
) => [
  {
    header: 'team member',
    accessorKey: 'repairer_id',
    size: 20,
  },
  {
    header: 'date',
    accessorKey: 'timeStamp',
    size: 15,
  },
  {
    header: 'time',
    accessorKey: 'total_repairing_time',
    size: 20,
  },
  {
    header: `rate ${currencySymbol}/h`,
    accessorKey: 'cost',
    size: 15,
  },
  {
    header: 'vat',
    accessorKey: 'vat',
    size: 15,
  },
  {
    header: '',
    title: 'actions',
    accessorKey: 'actions',
    enableSorting: false,
    size: 15,
    Cell: actionButtons(setEditModal, 'administrative-time'),
  },
];

export const liteRepairTimeColumns = (
  setEditModal: (args: LogClaimEditShape) => void,
  currencySymbol: string
) => [
  {
    header: 'team member',
    accessorKey: 'repairer_id',
    size: 20,
  },
  {
    header: 'date',
    accessorKey: 'timeStamp',
    size: 15,
  },
  {
    header: 'time',
    accessorKey: 'total_repairing_time',
    size: 20,
  },
  {
    header: `rate ${currencySymbol}/h`,
    accessorKey: 'cost',
    size: 15,
  },

  {
    header: 'vat',
    accessorKey: 'vat',
    size: 15,
  },
  {
    header: '',
    title: 'actions',
    accessorKey: 'actions',
    enableSorting: false,
    size: 15,
    Cell: actionButtons(setEditModal, 'repair-time'),
  },
];

export const litePostageColumns = (
  setEditModal: (args: LogClaimEditShape) => void,
  setDocumentModal: (args: DocumentModalShape) => void
) => [
  {
    header: 'carrier',
    accessorKey: 'carrier',
    size: 20,
  },
  {
    header: 'date',
    accessorKey: 'date',
    size: 20,
  },
  {
    header: 'tracking number',
    accessorKey: 'tracking_number',
    size: 15,
  },
  {
    header: 'amount',
    accessorKey: 'cost',
    size: 15,
  },
  {
    header: 'vat',
    accessorKey: 'vat',
    size: 15,
  },
  {
    header: 'attachments',
    accessorKey: 'attachments',
    size: 15,
    Cell: attachmentsButtons(setDocumentModal, 'shipping'),
  },
  {
    header: '',
    title: 'actions',
    accessorKey: 'actions',
    enableSorting: false,
    size: 15,
    Cell: actionButtons(setEditModal, 'shipping'),
  },
];

export const liteTravelColumns = (
  setEditModal: (args: LogClaimEditShape) => void,
  setDocumentModal: (args: DocumentModalShape) => void
) => [
  {
    header: 'description',
    accessorKey: 'description',
    size: 20,
  },
  {
    header: 'date',
    accessorKey: 'date',
    size: 20,
  },
  {
    header: 'amount',
    accessorKey: 'cost',
    size: 15,
  },
  {
    header: 'vat',
    accessorKey: 'vat',
    size: 15,
  },
  {
    header: 'attachments',
    accessorKey: 'attachments',
    size: 15,
    Cell: attachmentsButtons(setDocumentModal, 'travel'),
  },
  {
    header: '',
    title: 'actions',
    accessorKey: 'actions',
    enableSorting: false,
    size: 15,
    Cell: actionButtons(setEditModal, 'travel'),
  },
];

export const liteOtherColumns = (
  setEditModal: (args: LogClaimEditShape) => void,
  setDocumentModal: (opts: DocumentModalShape) => void
) => [
  {
    header: 'description',
    accessorKey: 'description',
    size: 40,
  },
  {
    header: 'date',
    accessorKey: 'date',
    size: 20,
  },
  {
    header: 'amount',
    accessorKey: 'cost',
    size: 15,
  },
  {
    header: 'vat',
    accessorKey: 'vat',
    size: 15,
  },
  {
    header: 'attachments',
    accessorKey: 'attachments',
    size: 15,
    Cell: attachmentsButtons(setDocumentModal, 'other'),
  },
  {
    header: '',
    title: 'actions',
    accessorKey: 'actions',
    enableSorting: false,
    size: 15,
    Cell: actionButtons(setEditModal, 'other'),
  },
];
