import { useSendEmail } from '@rabbit/bizproc/react';
import {
  Input,
  Button,
  Modal,
  asyncValidateSchema,
} from '@rabbit/elements/shared-components';
import { useAppInfo } from '@rabbit/sage/utils/helpers';
import { Formik, Form } from 'formik';
import { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

export function InviteUserModal({ handleClose }: { handleClose: () => void }) {
  const schema = Yup.object({
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter your email address'),
  });

  const appInfo = useAppInfo();
  const { SE_Sage_InviteNewUser } = useSendEmail();

  const modalSettings = {
    title: 'Invite users',
    handleClose: () => handleClose(),
  };

  const initialValues = {
    email: undefined as any,
  };

  const onSubmit = async (values: any) => {
    setLoading(true);

    const to = values.email,
      from = appInfo.email_sender,
      template = appInfo.email_main_template,
      businessName = appInfo.name,
      firstName = 'New',
      lastName = 'User',
      link = `${window.location.origin}/manage-account/`;

    await SE_Sage_InviteNewUser(
      to,
      from,
      template,
      businessName,
      firstName,
      lastName,
      link
    );

    setLoading(false);
    toast('Invitation sent successfully!');
    handleClose();
  };

  const [loading, setLoading] = useState(false);

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      className="m-auto w-full max-w-[512px] rounded-md border"
      isLoading={loading}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validate={asyncValidateSchema(schema)}
        validateOnBlur
      >
        {({ values, errors }) => {
          return (
            <Form>
              <div className="flex flex-col gap-4 px-5 pt-5">
                <Input
                  type="email"
                  name="email"
                  label="Email*"
                  settings={{
                    placeholder: 'Please type your email',
                    hint: '*required',
                  }}
                />
                <div className="flex gap-4">
                  <Button
                    className="w-1/3"
                    kind="primary"
                    children="Send invite"
                    type="submit"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
