import { AppInfoShape } from '@rabbit/data/types';
import { Heading, Input } from '@rabbit/elements/shared-components';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { LogForms, inputTypeCurrencyOverrideOptions } from '../ClaimCostModal';
import { CaseflowInterface } from '@rabbit/sage/context/CaseflowContext';
import { OptionShape } from '@rabbit/bizproc/react';
import { CFCF_AdministrativeCostLog } from '@rabbit/bizproc/core';
const AdministrativeTimeForm = (
  appInfo: AppInfoShape,
  { caseFacts, alterCaseFacts }: CaseflowInterface,
  repairerUsers: OptionShape[],
  personaId: string,
  options?: { editMode: boolean; index: number }
): LogForms<CFCF_AdministrativeCostLog & { team_member_id: string }> => {
  return {
    name: 'administrative-time',
    schema: Yup.object({
      team_member_id: Yup.string().required('Please select a team member'),
      time_spent: Yup.string().required('Please enter the time').trim(),
      date: Yup.string().trim(),
      rate: Yup.object({
        amount: Yup.string().required('Please enter an amount'),
        currency: Yup.string().required('Please select the currency'),
      }),
      VAT: Yup.string().required('Please enter VAT').trim(),
    }),
    initialValues: {
      rate: {
        currency: appInfo.currency,
      },
    },
    onDelete: async (index, onComplete) => {
      const updatedRegistry = caseFacts?.administrative_cost_data || [];
      updatedRegistry.splice(index, 1);
      try {
        await alterCaseFacts({
          administrative_cost_data: [...updatedRegistry],
        });
        toast.success('Record deleted successfully.');
      } catch (err) {
        console.log(err);
        toast.error('Something went wrong, please try again');
      } finally {
        onComplete();
      }
    },
    onSubmit: async (values, onComplete) => {
      const delegate = repairerUsers.find(
        (user) => user.value === values.team_member_id
      );
      const record = values;
      record.team_member = {
        id: delegate?.value,
        name: delegate?.label || '',
      };
      record.date = new Date(values.date || 0).getTime();

      let updatedRegistry = [];
      if (options?.editMode) {
        updatedRegistry = caseFacts?.administrative_cost_data || [];
        updatedRegistry[options.index] = record;
      } else {
        updatedRegistry = [
          ...(caseFacts?.administrative_cost_data || []),
          record,
        ];
      }

      try {
        await alterCaseFacts({
          administrative_cost_data: [...updatedRegistry],
          ...(values.internal_comment && {
            internal_comment: {
              comment: values.internal_comment,
              author: personaId,
            },
          }),
        });
        toast.success('Claim updated successfully.');
      } catch (err) {
        console.log(err);
        toast.error('Something went wrong, please try again');
      } finally {
        onComplete();
      }
    },
    Form: () => {
      return (
        <>
          <div className="font-bold font-nunito font-lg">
            Administrative time
          </div>
          <Input
            type="select"
            name="team_member_id"
            label="Team member*"
            settings={{
              id: 'team_member',
              placeholder: 'Select a team member',
              options: repairerUsers,
            }}
          />
          <div className="grid grid-cols-2 gap-y-2 gap-x-4">
            <Input
              type="datepicker"
              name="date"
              label="Date"
              settings={{
                id: 'date',
                placeholder: 'DD/MM/YYYY',
              }}
            />
            <Input
              type="time"
              name="time_spent"
              label="Time*"
              settings={{
                id: 'time',
                placeholder: 'hh:mm',
              }}
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Input
              type="currency"
              name="rate"
              label="Rate"
              settings={{
                id: 'rate',
                placeholder: 'Rate amount',
                inputTypeCurrencyOverrideOptions,
                disableCurrency: true,
              }}
            />
            <Input
              type="text"
              name="VAT"
              label="VAT*"
              settings={{
                id: 'vat',
                placeholder: 'Amount',
                append: '%',
              }}
            />
          </div>
          <Heading kind="p" className="text-gray-500">
            *required
          </Heading>
        </>
      );
    },
  };
};

export default AdministrativeTimeForm;
