import { ClockIcon } from '@heroicons/react/24/outline';
import { formatDate, titleCase } from './helpers';

const renderStatus = (status: string) => (
  <div
    className={`inline-flex rounded-md py-[2px] px-3 ${
      status === 'voided' || status === 'expired'
        ? 'bg-red-100 text-red-800'
        : ''
    } ${status === 'active' ? 'bg-green-100 text-green-800' : ''} ${
      status === 'upcoming' ? 'bg-orange-100 text-orange-800' : ''
    }`}
  >
    {status ? titleCase(status) : '-'}
  </div>
);

export const WarrantyTableColumns = [
  {
    title: 'Warranty id',
    header: 'warranty id',
    accessorKey: 'warrantyId',
    size: 10,
  },
  {
    header: 'Customer name',
    accessorKey: 'customerName',
    size: 20,
  },
  {
    title: 'plate',
    header: 'plate',
    accessorKey: 'registrationPlate',
    size: 20,
  },
  {
    header: 'registered',
    accessorKey: 'registered',
    size: 20,
    Cell: ({ cell }: any) =>
      cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
  },
  {
    header: 'plan',
    accessorKey: 'warrantyPlan',
    size: 25,
    Cell: ({ cell }: any) =>
      cell.getValue() === 'voided' ? renderStatus('voided') : cell.getValue(),
  },
  {
    header: 'Claim limit',
    accessorKey: 'claimLimit',
    size: 20,
  },
  {
    header: 'Start',
    accessorKey: 'start',
    size: 20,
    Cell: ({ cell }: any) =>
      cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
  },
  {
    header: 'term',
    accessorKey: 'term',
    size: 90,
  },
  {
    header: 'expires',
    accessorKey: 'expires',
    size: 20,
    Cell: ({ cell }: any) =>
      cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
  },
  {
    header: 'status',
    accessorKey: 'status',
    size: 25,
    Cell: ({ cell }: any) => renderStatus(cell.getValue()),
  },
];

export const RegistrationTableColumns = {
  Default: [
    {
      title: 'Timestamp',
      header: 'Timestamp',
      Header: <ClockIcon className="h-4 w-4" />,
      accessorKey: 'timeStamp',
      size: 20,
    },
    {
      title: 'Warranty id',
      header: 'warranty id',
      accessorKey: 'warrantyId',
      size: 20,
    },
    {
      title: 'Product',
      header: 'product',
      accessorKey: 'product',
    },
    {
      header: 'model number',
      accessorKey: 'modelNum',
    },
    {
      header: 'serial number',
      accessorKey: 'serialNum',
      size: 20,
    },
    {
      header: 'purchase date',
      accessorKey: 'purchaseDate',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
    },
    {
      header: 'registered',
      accessorKey: 'registered',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
    },
    {
      header: 'expiring',
      accessorKey: 'expiring',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
    },
    {
      header: 'term',
      accessorKey: 'term',
      size: 90,
    },
    {
      header: 'extended',
      accessorKey: 'extended',
      size: 10,
    },
    // {
    //   accessorKey: 'actions',
    //   size: 15,
    //   enableColumnActions: false,
    // },
  ],
  CarColumns: [
    {
      title: 'Timestamp',
      header: 'Timestamp',
      Header: <ClockIcon className="h-4 w-4" />,
      accessorKey: 'timeStamp',
      size: 20,
    },
    {
      header: 'ID',
      accessorKey: 'warrantyId',
      size: 20,
    },
    {
      header: 'Customer name',
      accessorKey: 'customerName',
      size: 20,
    },
    {
      title: 'plate',
      header: 'plate',
      accessorKey: 'registrationPlate',
      size: 20,
    },
    {
      title: 'Make',
      header: 'make',
      accessorKey: 'make',
      size: 20,
    },
    {
      header: 'registered',
      accessorKey: 'registered',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
    },
    {
      header: 'Start',
      accessorKey: 'start',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
    },
    {
      header: 'plan',
      accessorKey: 'warrantyPlan',
      size: 25,
      Cell: ({ cell }: any) =>
        cell.getValue() === 'voided' ? renderStatus('voided') : cell.getValue(),
    },
    {
      header: 'term',
      accessorKey: 'term',
      size: 10,
    },
    {
      header: 'Claim limit',
      accessorKey: 'claimLimit',
      size: 20,
    },
    {
      title: 'Partner',
      header: 'partner',
      accessorKey: 'partner',
      size: 20,
    },
    {
      title: 'Salesperson',
      header: 'salesperson',
      accessorKey: 'salesPerson',
      size: 20,
    },
    {
      title: 'Price',
      header: 'price',
      accessorKey: 'price',
      size: 40,
    },
  ],
  TableTop: [
    {
      title: 'Timestamp',
      header: 'Timestamp',
      Header: <ClockIcon className="h-4 w-4" />,
      accessorKey: 'timeStamp',
      size: 20,
    },
    {
      title: 'id',
      header: 'id',
      accessorKey: 'id',
      size: 100,
    },
    {
      header: 'Customer name',
      accessorKey: 'customerName',
      size: 20,
    },
    {
      title: 'Product',
      header: 'product',
      accessorKey: 'product',
      size: 20,
    },
    {
      title: 'Area',
      header: 'area',
      accessorKey: 'area',
      size: 10,
    },
    {
      header: 'registered',
      accessorKey: 'registered',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
    },
    {
      header: 'Start',
      accessorKey: 'start',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() ? formatDate(new Date(cell.getValue())) : '-',
    },
    {
      header: 'service plan',
      accessorKey: 'warrantyPlan',
      size: 20,
      Cell: ({ cell }: any) =>
        cell.getValue() === 'voided' ? renderStatus('voided') : cell.getValue(),
    },
    {
      header: 'salesperson',
      accessorKey: 'salesperson',
      size: 20,
    },
    {
      header: 'term (y)',
      accessorKey: 'term',
      size: 90,
    },
    {
      title: 'Partner',
      header: 'partner',
      accessorKey: 'partner',
      size: 20,
    },
  ],
};
