// eslint-disable-next-line @typescript-eslint/no-unused-vars

// Routing
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoutes from '../utils/PrivateRoutes';
import PublicRoutes from '../utils/PublicRoutes';
import ROUTE_NAME from '../utils/url-constants';
// React Query
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
// Views
import SignupView from '../views/SignupView';
import LoginView from '../views/LoginView';
import DashboardView from '../views/DashboardView';
import LiveClaimsView from '../views/LiveClaimsView';
import AccountManagementView from '../views/AccountManagementView';
import ClaimDetailView from '../views/ClaimDetailView';
import RegistrationsView from '../views/RegistrationsView';
import VendableDetailsView from '../views/VendableDetailsView';
import PartsDetailView from '../views/PartsDetailView';
import CustomerDetailView from '../views/CustomerDetailView';
import PartnerDetailView from '../views/PartnerDetailView';
import { UserGroupView } from '../views/UserGroupView';
import PrivacyPolicyView from '../views/PrivacyPolicyView';
import TermsAndConditionsView from '../views/TermsAndConditionsView';
import ForgotPasswordView from '../views/ForgotPasswordView';
import PasswordResetView from '../views/PasswordResetView';
import RegistrationsDetailsView from '../views/RegistrationsDetailsView';
import PlatformServicesAgreementView from '../views/PlatformServicesAgreementView';
// Misc
import 'flowbite';
import '@rabbit/utils/buildinfo'; // Lets us see information about builds from within them
import { ToastContainer } from 'react-toastify';
import 'react-toastify/ReactToastify.min.css';
import { UserProviderWrapper } from '../context/UserContext';
import { ScrollToTop } from '@rabbit/bizproc/react';
import { NotFoundView } from '@rabbit/elements/shared-components';
import { ManageView } from '../views/ManageView';
import { useAppInfo } from '../utils/helpers';
import { MixMasterWrapper } from '@rabbit/mixmaster/react';

import 'react-loading-skeleton/dist/skeleton.css';

import { base } from '@rabbit/mixmaster/veils';
import { GenericLexicon } from '@rabbit/mixmaster/core';
import MyOrganizationView from '../views/MyOrganizationView';
import RegistrationsPrintSummaryView from '../views/RegistrationsPrintSummaryView';
import WarrantyOverviewView from '../views/WarrantyOverviewView';

export function App() {
  const appInfo = useAppInfo();
  const queryClient = new QueryClient();

  return (
    <div
      id="app"
      className="flex min-h-[-webkit-fill-available] w-full justify-center md:min-h-screen"
    >
      <QueryClientProvider client={queryClient}>
        <MixMasterWrapper bootstrap={base.core as GenericLexicon}>
          <BrowserRouter>
            <UserProviderWrapper>
              <ReactQueryDevtools initialIsOpen={false} />
              <Routes>
                <Route element={<PrivateRoutes />}>
                  <Route path="" element={<DashboardView />} />
                  <Route
                    path={ROUTE_NAME.CLAIMS}
                    element={<LiveClaimsView />}
                  />
                  <Route
                    path={ROUTE_NAME.MY_CLAIMS}
                    element={<LiveClaimsView />}
                  />
                  <Route
                    path={ROUTE_NAME.CLAIMS + '/:id'}
                    element={<ClaimDetailView />}
                  />
                  <Route
                    path={ROUTE_NAME.REGISTRATIONS}
                    element={<RegistrationsView />}
                  />
                  <Route
                    path={ROUTE_NAME.REGISTRATIONS + '/:id'}
                    element={<RegistrationsDetailsView />}
                  />
                  <Route
                    path={ROUTE_NAME.REGISTRATIONS + '/:id/print'}
                    element={<RegistrationsPrintSummaryView />}
                  />
                  <Route
                    path={ROUTE_NAME.WARRANTY_OVERVIEW}
                    element={<WarrantyOverviewView />}
                  />
                  <Route
                    path={ROUTE_NAME.WARRANTY_OVERVIEW + '/:id'}
                    element={<RegistrationsDetailsView />}
                  />
                  <Route
                    path={ROUTE_NAME.PRODUCTS + '/:id'}
                    element={<VendableDetailsView />}
                  />
                  <Route
                    path={ROUTE_NAME.PRODUCTS_PARTS + '/:id'}
                    element={<PartsDetailView />}
                  />
                  <Route
                    path={ROUTE_NAME.MANAGE_ACCOUNT}
                    element={<AccountManagementView />}
                  />
                  <Route
                    path={ROUTE_NAME.MY_ORGANIZATION}
                    element={<MyOrganizationView />}
                  />
                  <Route path={ROUTE_NAME.MANAGE} element={<ManageView />} />
                  <Route
                    path={ROUTE_NAME.MANAGE + '/customer/:id'}
                    element={<CustomerDetailView />}
                  />
                  <Route
                    path={ROUTE_NAME.MANAGE + '/partner/:id'}
                    element={<PartnerDetailView />}
                  />
                  <Route
                    path={ROUTE_NAME.USER_GROUP}
                    element={<UserGroupView />}
                  />
                  {/*These routes are duplicated as they can be accessed whether logged in or not.
                  This allows the navbar to be displayed with the proper data in both states.
                  And it avoids a redirect back to dashboard when logged in.
                  This can most likely be optimized but is more than good enough for the foreseeable future. - DC
                  */}
                  <Route
                    path={ROUTE_NAME.PRIVACY_POLICY}
                    element={<PrivacyPolicyView />}
                  />
                  <Route
                    path={ROUTE_NAME.TERMS_AND_CONDITIONS}
                    element={<TermsAndConditionsView />}
                  />
                </Route>
                <Route element={<PublicRoutes />}>
                  {/* USER IS A GUEST BUT WE SHOULD REDIRECT TO DASHBOARD IF LOGGED */}
                  <Route path={ROUTE_NAME.LOGIN} element={<LoginView />} />
                  <Route path={ROUTE_NAME.SIGNUP} element={<SignupView />} />
                  <Route
                    path={ROUTE_NAME.FORGOT_PASSWORD}
                    element={<ForgotPasswordView />}
                  />
                  {
                    //TODO: merge these two routes into one
                  }
                  <Route
                    path={ROUTE_NAME.CONFIRM_EMAIL}
                    element={<PasswordResetView step={1} />}
                  />
                  <Route
                    path={ROUTE_NAME.RESET_PASSWORD}
                    element={<PasswordResetView step={2} />}
                  />
                  <Route
                    path={ROUTE_NAME.TERMS_AND_CONDITIONS}
                    element={<TermsAndConditionsView />}
                  />
                  <Route
                    path={ROUTE_NAME.PLATFORM_SERVICES_AGREEMENT}
                    element={<PlatformServicesAgreementView />}
                  />
                  <Route
                    path={ROUTE_NAME.PRIVACY_POLICY}
                    element={<PrivacyPolicyView />}
                  />
                </Route>
                <Route path="*" element={<NotFoundView />} />
              </Routes>
              <ToastContainer />
            </UserProviderWrapper>
            <ScrollToTop />
          </BrowserRouter>
        </MixMasterWrapper>
      </QueryClientProvider>
    </div>
  );
}

export default App;
