import { useAppInfo, getUserPermissions } from '../utils/helpers';
import { TabsGeneric } from '@rabbit/elements/shared-components';
import InformationTab from '../components/molecules/account-management/InformationTab';
import LegalTab from '../components/molecules/account-management/LegalTab';
import UsersTab from '../components/molecules/account-management/UsersTab';
import { useTranslation } from 'react-i18next';
import { Address } from '@rabbit/data/types';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { Navigate } from 'react-router-dom';
import ROUTE_NAME from '../utils/url-constants';
import { Permissions } from '@rabbit/data/types';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AccountManagementViewProps {}

export function MyOrgnizationView(props: AccountManagementViewProps) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const tenantLink = t('tenantLink');
  const userPermissions = getUserPermissions(tenantLink, userContext);
  if (!userPermissions?.includes(Permissions.Owner))
    return <Navigate to={ROUTE_NAME.MANAGE_ACCOUNT} />;

  const getProperty = (key: string) => (t(key) !== key ? t(key) : null);
  const addresses = JSON.parse(
    getProperty('CFG_COBRAND_ADDRESSES') || '[]'
  ) as Address[];
  const tenantData = {
    logo: appInfo.logo,
    name: appInfo.name,
    userType: 'Organization',
    addresses,
  };

  const accountTabs = [
    {
      label: 'Information',
      children: <InformationTab tenant={tenantData} kind="tenant" />,
    },
    {
      label: 'Users',
      children: <UsersTab data={[]} />,
    },
    {
      label: 'Legal',
      children: <LegalTab />,
    },
  ];

  return (
    <div className="px-2">
      <div className="pb-4">
        <TabsGeneric items={accountTabs} hugTabs={true} />
      </div>
    </div>
  );
}

export default MyOrgnizationView;
