import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  Modal,
  UploadFilesForm,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  MANDRILL_TEMPLATES,
  useFileStorage,
  useSendEmail,
} from '@rabbit/bizproc/react';
import { Address, UserUploadedDocument } from '@rabbit/data/types';
import { toast } from 'react-toastify';
import { CaseflowContext } from '../../../../context/CaseflowContext';
import {
  formatAddress,
  getConsumerURL,
  useAppInfo,
} from 'apps/sage/src/utils/helpers';
import { AddressSection } from '@rabbit/sage/components/molecules/AddressSection/AddressSection';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export interface LogPostageModalProps {
  postage_registry: FormValuesShape[];
}

interface FormValuesShape {
  outbound_carrier: string;
  outbound_tracking_number: string;
  outbound_postage_cost: { amount: number; currency: string };
  outbound_address: Address | undefined;
  comment_to_customer: string;
  internal_comment: string;
}

const validationSchema = Yup.object().shape({
  comment_to_customer: Yup.string()
    .test('remove-html-tags', 'please insert a message.', (value) => {
      const div = document.createElement('div');
      div.innerHTML = value || '';
      return !value || div.textContent?.trim() !== '';
    })
    .trim()
    .required("Comments to customer can't be empty"),
  outbound_tracking_number: Yup.string()
    .trim()
    .required('Please enter the tracking number'),
  outbound_postage_cost: Yup.object({
    amount: Yup.number().required('Please enter a valid amount'),
    currency: Yup.string().trim().required('Currency is required'),
  }).required('Please enter a postage cost'),
  outbound_address: Yup.mixed().required('Please enter one or more address'),
  outbound_carrier: Yup.string()
    .trim()
    .required("Please enter the carrier's name"),
  internal_comment: Yup.string().trim(),
});

export function LogPostageModal({ postage_registry }: LogPostageModalProps) {
  const appInfo = useAppInfo();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Address | undefined>(
    undefined
  );
  const [showInternalComment, setShowInternalComment] = useState(false);
  const [additionalAddress, setAdditionalAddress] = useState<boolean>(false);

  const formikRef = useRef(null) as any;

  const {
    uploadFiles,
    uploadTaskCat,
    isUpdating,
    uploadProgress,
    deleteFile,
    deleteUnusedTempFiles,
    updateHoldingWithFiles,
    uploadedTempFiles,
    updateVendableWithFiles,
    clearFileFromHolding,
    clearFileFromVendable,
    clearFileFromState,
    clearAllTempFilesFromState,
  } = useFileStorage();

  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    operatingPersonaSingleKey,
    caseId,
    caseFlowCase,
    consumerPersonaData,
    caseActors,
  } = useContext(CaseflowContext) || {};

  const [oldAddress, setOldAddress] = useState<Address | undefined>(
    (consumerPersonaData?.address as Address[])?.length > 1
      ? (consumerPersonaData?.address as Address[])?.filter((i) => i.isDefault)
          .length > 0
        ? (consumerPersonaData?.address as Address[])?.filter(
            (i) => i.isDefault
          )[0]
        : consumerPersonaData?.address?.[0]
      : consumerPersonaData?.address?.[0]
  );

  const [uploadedProofOfPostage, setUploadedProofOfPostage] = useState(
    caseFacts?.postage_receipts
  );

  const { SE_Sage_PostageLogged } = useSendEmail();

  useEffect(() => {
    if (!selectedAddress) {
      formikRef.current.resetForm();
      return;
    }
    if (formikRef && selectedAddress) {
      formikRef.current.setFieldValue('outbound_address', selectedAddress);
    }
  }, [selectedAddress]);

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersona ||
    !operatingPersonaSingleKey ||
    !caseFacts ||
    !caseId ||
    !caseFlowCase
  )
    return <LoadingSpinner size={'xs'} />;

  const business_name = appInfo.name ?? '';

  const initialValues = {
    comment_to_customer: '',
    outbound_tracking_number: '',
    outbound_address:
      selectedAddress ||
      caseFacts.outbound_address ||
      consumerPersonaData?.address?.[0],
    outbound_postage_cost: {
      amount: 0,
      currency: appInfo.currency ?? 'AUD',
    },
    outbound_carrier: '',
    internal_comment: '',
  };

  const onSubmit = async (values: FormValuesShape) => {
    setIsSubmitting(true);
    const {
      internal_comment,
      outbound_tracking_number,
      outbound_address,
      outbound_postage_cost,
      outbound_carrier,
      comment_to_customer,
    } = values;

    const newArray = JSON.parse(JSON.stringify(postage_registry));
    newArray.push({
      outbound_tracking_number,
      outbound_address,
      outbound_postage_cost,
      outbound_carrier,
      timeStamp: Date.now(),
    });

    const to = caseFacts?.consumer_email ?? '',
      from = appInfo.email_sender,
      template = MANDRILL_TEMPLATES.BODY_POSTAGE_LOGGED,
      business_name = appInfo.name ?? '',
      claim_id = caseId,
      first_name = caseFacts?.consumer_name ?? '',
      product_name = caseFacts.consumer_holding_name ?? '',
      carrier_name = values.outbound_carrier,
      postage_customer_address =
        String(formatAddress(values.outbound_address, true)) ?? '',
      tracking_code = values.outbound_tracking_number,
      subject =
        business_name +
        ' has posted your ' +
        product_name +
        ' back to you with ' +
        carrier_name,
      link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;

    const factsToAlter = {
      ...(internal_comment && {
        internal_comment: {
          comment: internal_comment,
          author: operatingPersona,
        },
      }),
      comment_to_customer: comment_to_customer,
      outbound_tracking_number: outbound_tracking_number,
      outbound_address: outbound_address,
      outbound_postage_cost: outbound_postage_cost,
      outbound_carrier: outbound_carrier,
      postage_registry: JSON.parse(JSON.stringify(newArray)),
    };
    if (Object.keys(factsToAlter).length > 0)
      await alterCaseFacts(factsToAlter);

    await moveSpotlight(operatingPersonaSingleKey);
    executeAction('log_postage');

    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
        comment_to_customer: comment_to_customer,
        outbound_tracking_number: outbound_tracking_number,
        outbound_postage_cost: outbound_postage_cost,
        outbound_carrier: outbound_carrier,
        postage_registry: JSON.parse(JSON.stringify(newArray)),
        outbound_address: {
          line1: values.outbound_address?.line1,
          line2: values.outbound_address?.line2,
          town: values.outbound_address?.town,
          state: values.outbound_address?.state,
          postcode: values.outbound_address?.postcode,
          country: values.outbound_address?.country,
        },
      };
      if (Object.keys(factsToAlter).length > 0) {
        await alterCaseFacts(factsToAlter);
        await alterCasePublicEmail({
          context: 'postage_logged',
          to,
          from,
          subject,
          template,
          substitutions: {
            subject,
            business_name,
            claim_id,
            first_name,
            carrier_name,
            postage_customer_address,
            tracking_code,
            product_name,
            comment_to_customer,
            link_to_claim,
          },
        });
      }

      await SE_Sage_PostageLogged(
        to,
        from,
        subject,
        appInfo.email_main_template,
        business_name,
        first_name,
        claim_id,
        carrier_name,
        postage_customer_address,
        tracking_code,
        product_name,
        comment_to_customer,
        link_to_claim
      );

      setShowModal(false);
      toast.success('Claim updated successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  };

  const onProofOfPostageUpdated = async (
    files: UserUploadedDocument[],
    _added = true
  ) => {
    if (files && files.length && caseFlowCase) {
      clearAllTempFilesFromState();
      const parsedFiles = JSON.parse(JSON.stringify(files));
      caseFlowCase.Alter_Facts({
        postage_receipts: parsedFiles?.length ? parsedFiles : [],
      });
      await caseFlowCase.Commit();
      setUploadedProofOfPostage(files);
      //added && toast.success('Proof of postage added successfully');
    }
  };

  return (
    <Modal
      settings={{
        title: 'Log postage',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      isLoading={isSubmitting}
      kind="generic"
      className="max-h-[768px] w-full max-w-[1024px]"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        innerRef={formikRef}
      >
        {({ errors, values, touched, ...rest }) => (
          <Form className="mt-5 flex h-[500px] flex-col gap-3 px-4">
            <div className="flex gap-3">
              <Input
                type="text"
                label="Tracking number*"
                name="outbound_tracking_number"
                settings={{
                  id: 'outbound_tracking_number',
                  placeholder: '',
                  hint: '*required',
                }}
              />
              <Input
                type="text"
                label="Carrier*"
                name="outbound_carrier"
                settings={{
                  id: 'outbound_carrier',
                  placeholder: '',
                }}
              />
            </div>
            <UploadFilesForm
              label="Proof of postage"
              type="case"
              docId={caseId ?? ''}
              personaId={caseActors?.consumer ?? ''}
              currentFiles={uploadedProofOfPostage ?? []}
              onFilesUploaded={onProofOfPostageUpdated}
              uploadHandlers={{
                uploadedTempFiles,
                category: 'postage_receipts',
                uploadFiles,
                uploadTaskCat,
                isUpdating,
                uploadProgress,
                deleteFile,
                updateHoldingWithFiles,
                updateVendableWithFiles,
                clearFileFromHolding,
                clearFileFromVendable,
                clearFileFromState,
                deleteUnusedTempFiles,
              }}
            />
            <Input
              type="currency"
              label="Cost of postage"
              name="outbound_postage_cost"
              settings={{
                id: 'outbound_postage_cost',
                placeholder: '',
                currency:
                  values?.outbound_postage_cost?.currency ?? appInfo.currency,
              }}
            />
            <label
              className={
                'font-nunito flex flex-col gap-2 text-base font-medium' +
                (errors['outbound_address'] ? ' text-red-700' : '')
              }
              htmlFor="customer_address"
            >
              Customer address *
              <AddressSection
                onSelected={(address) => setSelectedAddress(address)}
                address={oldAddress}
                kind={!additionalAddress ? 'normal' : 'stale'}
                onAddNew={() => {
                  setAdditionalAddress(true);
                }}
                error={errors['outbound_address'] !== undefined}
              />
              {additionalAddress && (
                <AddressSection
                  onSelected={(address) => {
                    setSelectedAddress(address);
                  }}
                  address={
                    selectedAddress ? values['outbound_address'] : ({} as any)
                  }
                  onCancelNew={() => setAdditionalAddress(false)}
                  kind="added"
                  error={errors['outbound_address'] !== undefined}
                />
              )}
            </label>
            {errors['outbound_address'] && (
              <div className="font-nunito mt-2 flex items-start text-base text-red-500">
                <ExclamationCircleIcon className="h-5 w-5" />
                <span className="ml-1">
                  {errors['outbound_address'] as string}
                </span>
              </div>
            )}
            <Input
              type="rich-text"
              label="Comments to customer*"
              name="comment_to_customer"
              settings={{
                id: 'comment_to_customer',
                hint: '*required',
              }}
            />
            {!showInternalComment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setShowInternalComment(true)}
                >
                  Add internal comment
                </Button>
              </div>
            )}
            {showInternalComment && (
              <div>
                <Input
                  type="rich-text"
                  label="Internal comment"
                  name="internal_comment"
                  settings={{
                    id: 'internal_comment',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            <div className="mt-8 flex gap-8">
              <Button kind="primary" type="submit" loading={isSubmitting}>
                Log postage
              </Button>
              <Button kind="outline_red" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default LogPostageModal;
