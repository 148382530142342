import { useContext, useEffect, useMemo, useState } from 'react';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  FiltersModal,
  FilterValueShape,
  getCoverageByValue,
  getStationByKey,
  LoadingSpinner,
  Modal,
  PillTime,
  Table,
  timestampToTimeAgo,
} from '@rabbit/elements/shared-components';
import { ClockIcon, FunnelIcon, StarIcon } from '@heroicons/react/24/outline';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ROUTE_NAME from '../utils/url-constants';
import {
  useCaseFollowing,
  useGetCaseProxyList,
  //useGetRepairerPeers,
} from '@rabbit/bizproc/react';
import { DTCaseFlowPrivateProxy } from '@rabbit/data/types';
import Chart from 'react-apexcharts';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import {
  formatDataChart,
  getChartOptions,
  getUserPermissions,
  isStale,
  isWarrantyExpired,
  useAppInfo,
} from '../utils/helpers';
import {
  BellAlertIcon,
  StarIcon as StarFullIcon,
} from '@heroicons/react/20/solid';
import { UserContext } from '../context/UserContext';
import { useTranslation } from 'react-i18next';
import { Permissions } from '@rabbit/data/types';
//import AssignRepairerTableButton from '../components/atoms/AssignRepairerTableButton/AssignRepairerTableButton';

// NOTE: Assignment of repairer from the table has now been disabled due to a propensity for buggy behaviour
// and poor performance. Leaving the code commented here for a while in case we need to reenable it later -DC

export function LiveClaimsView() {
  const location = useLocation();
  const navigate = useNavigate();
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const tenantLink = t('tenantLink');

  const showAllCases = location?.pathname?.includes(ROUTE_NAME.MY_CLAIMS)
    ? false
    : true;

  const { repairerPersona, warrantorPersona } = useGetMySagePersonas();
  const repairerPersonaId = repairerPersona?.personaId || '';
  const warrantorPersonaId = warrantorPersona?.personaId || '';

  //Disabled - see note above
  //const repairerUsers = useGetRepairerPeers();
  //const [repairerUsersList, setRepairerUsersList] = useState(repairerUsers);
  const { followedCaseIds, followCase, unfollowCase } = useCaseFollowing();

  const [filters, setFilters] = useState<FilterValueShape>({
    beginDate: undefined,
    endDate: undefined,
    unassignedOnly: false,
    staleOnly: false,
    starred: false,
  });

  const {
    caseList,
    //totalCaseCount
  } = useGetCaseProxyList(
    // itemsPerPage,
    showAllCases,
    filters,
    tenantLink === 'SHELTA' ? repairerPersonaId : warrantorPersonaId,
    userContext?.user?.accessToken
  );

  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [deleteClaimOpen, setDeleteClaimOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]); //todo type this
  const [chartCategoriesData, setChartCategoriesData] = useState<any[] | null>(
    []
  ); //TODO check type
  const [chartIssuesTypeData, setChartIssuesTypeData] = useState<any[] | null>(
    []
  ); //TODO check type

  // Used for paginated data - temporarily disabled
  // const getDataFromList = () => {
  //   const dataList: DTCaseFlowPrivateProxy[] = [];

  //   caseList.data?.pages.forEach((page) => {
  //     page.docs.forEach((doc) => {
  //       dataList.push(doc.data());
  //     });
  //   });

  //   return dataList;
  // };

  const countFilters = () => {
    let count = 0;
    if (filters) {
      if (filters.beginDate || filters.endDate) count += 1;

      if (filters.unassignedOnly) count += 1;
      if (filters.staleOnly) count += 1;
      if (filters.starred) count += 1;
    }
    return count;
  };

  const getWarrantyAssessment = (result: DTCaseFlowPrivateProxy) => {
    const {
      goodwill_warranty_final,
      final_assessment,
      goodwill_warranty_initial,
      preliminary_assessment,
    } = result.facts || {};

    const current_assessment =
      goodwill_warranty_final ||
      final_assessment ||
      goodwill_warranty_initial ||
      preliminary_assessment;

    return current_assessment && current_assessment.trim() !== ''
      ? getCoverageByValue(current_assessment)?.label
      : '-';
  };

  const setupChartCategories = () => {
    const categories: { id: any; count: number; label: any }[] = [];
    const claimsList = caseList?.data;

    if (!claimsList?.length) {
      setChartCategoriesData(null);
      return;
    }

    const MISSING_CATEGORY_ID = '1';

    claimsList.forEach((claim, i) => {
      if (
        claim.facts.consumer_holding_category &&
        claim.facts.consumer_holding_category !== 'n/a' &&
        claim.facts.consumer_holding_category !== '-'
      ) {
        const existingItem = categories.find(
          (item) => item.id === claim.facts.consumer_holding_category
        );
        if (existingItem) {
          existingItem.count++;
        } else {
          categories.push({
            id: claim.facts.consumer_holding_category,
            count: 1,
            label:
              claim.facts.consumer_holding_category === '-'
                ? claim.facts.consumer_holding_category
                : claim.facts.consumer_holding_category
                    .replace(/-/g, ' ')
                    .replace(/\b\w/g, (char: string) => char.toUpperCase()), //replace dashes for empty spaces and transform to uppercase
          });
        }
      } else {
        const existingItem = categories.find(
          (item) => item.id === MISSING_CATEGORY_ID
        );
        if (existingItem) {
          existingItem.count++;
        } else {
          categories.push({
            id: MISSING_CATEGORY_ID,
            count: 1,
            label: 'Other',
          });
        }
      }
    });
    const sortedCategories = categories.sort((a, b) => b.count - a.count);

    setChartCategoriesData(
      sortedCategories.length
        ? formatDataChart(sortedCategories, 'series', 'Claims', 5)
        : null
    );
  };

  const setupChartFaultIssueType = () => {
    const issuesType: { id: any; count: number; label: any }[] = [];
    const claimsList = caseList?.data;

    if (!claimsList?.length) {
      setChartIssuesTypeData(null);
      return;
    }

    claimsList.forEach((claim, i) => {
      const existingItem = issuesType.find(
        (item) => item.label === claim.facts.consumer_issue_type
      );
      if (existingItem) {
        existingItem.count++;
      } else {
        issuesType.push({
          id: claim.facts.consumer_issue_type_ref,
          count: 1,
          label: claim.facts.consumer_issue_type ?? 'No title',
        });
      }
    });

    const sortedIssuesType = issuesType.sort((a, b) => b.count - a.count);
    setChartIssuesTypeData(
      formatDataChart(sortedIssuesType, 'series', 'Claims', 5)
    );
  };

  // Reset filters if navigating to a different page
  // useEffect(() => {
  //   setFilters({
  //     beginDate: null,
  //     endDate: null,
  //     unassignedOnly: false,
  //     staleOnly: false,
  //     starred: false,
  //   });
  // }, [location?.pathname]);

  // const onDeleteClaim = (id: any) => {
  //   setDeleteClaimOpen(true);
  // };

  const onChangeFilters = (value: FilterValueShape) => {
    setIsFilterModalOpen(false);
    setFilters(value);
  };

  const modalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: 'You are about to delete this claim. You will lose all of the information. Are you sure you want to continue?',
      primaryButtonText: 'Yes',
      outlineButtonText: 'No, cancel',
      handleClose: () => setDeleteClaimOpen(false),
      handlePrimaryClick: async () =>
        console.log('delete --> selectedRowToDelete'),
      handleOutlineClick: () => setDeleteClaimOpen(false),
    },
  };

  const columns = useMemo(
    () => [
      {
        header: 'Spotlight',
        Header: () => <BellAlertIcon className="h-4 w-4" />,
        accessorKey: 'spotlight',
        size: 30,
        Cell: ({ cell }: any) => {
          return (
            cell.getValue() && (
              <div className="flex items-center px-2">
                <div className="h-[10px] w-[10px] rounded-full bg-red-500"></div>
              </div>
            )
          );
        },
      },
      {
        header: 'Timestamp',
        Header: () => <ClockIcon className="h-4 w-4" />,
        accessorKey: 'timeStamp',
        size: 10,
        Cell: ({ cell }: any) => {
          return (
            <PillTime
              label={cell.getValue() ? timestampToTimeAgo(cell.getValue()) : ''}
              className={'bg-gray-300 text-gray-900'}
            />
          );
        },
      },
      {
        header: 'In warranty',
        accessorKey: 'inWarranty',
        size: 20,
        Cell: ({ cell }: any) => {
          return (
            <div
              className={`font-nunito flex h-[25px] w-fit items-center justify-center gap-1.5 rounded-md py-1 px-3 text-gray-900 ${
                cell.getValue() === 'Yes' ? 'bg-green-100' : 'bg-red-100'
              }`}
            >
              <p className="text-sm font-medium">{cell.getValue()}</p>
            </div>
          );
        },
      },
      {
        header: 'customer name',
        accessorKey: 'customerName',
        size: 150,
      },
      // {
      //   header: 'claim date',
      //   accessorKey: 'claimDate',
      //   size: 20,
      // },
      {
        header: 'claim status',
        accessorKey: 'claimStatus',
        size: 150,
      },
      {
        header: 'product',
        accessorKey: 'product',
        size: 200,
      },
      {
        header: 'reported fault',
        accessorKey: 'fault',
        size: 20,
      },
      {
        header: 'assessment',
        accessorKey: 'assessment',
        size: 20,
      },
      {
        header: 'outcome',
        accessorKey: 'outcome',
        size: 20,
      },
      // Disabled - see note above
      // {
      //   header: 'assigned to',
      //   accessorKey: 'assignTo',
      //   size: 15,
      //   Cell: ({ cell }: any) => {
      //     return (
      //       <AssignRepairerTableButton
      //         repairer_name={cell.getValue()}
      //         repairer_id={cell.row.original.delegate_repairer_id}
      //         repairerUsers={repairerUsersList}
      //         case_proxy_id={cell.row.original.link}
      //       />
      //     );
      //   },
      // },
      {
        header: '',
        accessorKey: 'actions',
        size: 15,
        enableColumnActions: false,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          const { isStarred, id } = value || {};
          return (
            <div className="flex items-center justify-center">
              {isStarred ? (
                <StarFullIcon
                  className="h-5 w-5 cursor-pointer"
                  onClick={async () => {
                    await unfollowCase(id);
                  }}
                />
              ) : (
                <StarIcon
                  className="h-5 w-5 cursor-pointer"
                  onClick={async () => {
                    await followCase(id);
                  }}
                />
              )}
              {/* <TrashIcon
                className="h-5 w-5 cursor-pointer"
                onClick={() => {
                  setDeleteClaimOpen(!deleteClaimOpen);
                  // onDeleteRegistration(result.docid);
                }}
              /> */}
            </div>
          );
        },
      },
    ],
    [followCase, unfollowCase]
  );

  useEffect(() => {
    const formatResultsForClaimsTable = () => {
      const dataList = caseList?.data ?? [];

      const getProductName = (result: DTCaseFlowPrivateProxy) => {
        if(result?.facts?.holding_vendable_id === 'SRV'){
          return result?.facts?.srvProductInfo?.brand + ' ' + result?.facts?.srvProductInfo?.title;
        }else return result?.facts?.consumer_holding_name ?? '-';
      };

      if (caseList.isSuccess && caseList?.data?.length > 0)
        return dataList
          .filter((result: DTCaseFlowPrivateProxy) => {
            if (filters.staleOnly) {
              return (
                isStale(result.tupdate, appInfo) &&
                result.case_state !== 'closed'
              );
            }
            return true;
          })
          .filter((result: DTCaseFlowPrivateProxy) => {
            if (filters.starred && followedCaseIds.length) {
              return result?.docid && followedCaseIds.includes(result?.docid);
            }
            return true;
          })
          .map((result: DTCaseFlowPrivateProxy, i: number) => ({
            docid: result.docid,
            timeStamp: result?.tcreate,
            customerName: result?.facts.consumer_name,
            link: result?.docid,
            // claimDate: result?.facts?.case_time_created
            //   ? formatUnixTime(result?.facts?.case_time_created, 'dd/MM/yyyy')
            //   : '-',
            spotlight:
              result?.case_spotlight?.[0]?.[0] === 'R' ||
              result?.actor_chat_unread
                ? true
                : false,
            product: getProductName(result),
            fault: result?.facts?.consumer_issue_type ?? '-',
            claimStatus: getStationByKey(result.case_state).label ?? '-',
            assessment: getWarrantyAssessment(result),
            inWarranty: isWarrantyExpired(
              result?.facts.purchase_date,
              result?.facts.holding_warranty_term
            ),
            outcome: result?.facts?.claim_outcome ?? '-',
            // Disabled, see note above
            //assignTo: result?.facts?.delegate_repairer_name ?? 'Unassigned',
            delegate_repairer_id: result?.facts?.delegate_repairer_id ?? 0,
            actions:
              result?.docid && followedCaseIds.includes(result?.docid)
                ? { isStarred: true, id: result.docid }
                : { isStarred: false, id: result.docid },
            stale: isStale(result.tupdate, appInfo),
            extraData: {
              modelNumber: result.facts?.consumer_holding_serial_number,
            },
          }));
    };

    const formattedResults = formatResultsForClaimsTable();

    if (formattedResults && formattedResults?.length > 0) {
      console.log('claimsList', caseList?.data);
      console.log(formattedResults, 'formattedResults');
      setTableData(formattedResults);
    } else {
      setTableData([]);
    }

    setupChartFaultIssueType();
    setupChartCategories();
  }, [caseList?.data, caseList?.isSuccess, filters, followedCaseIds.length]);

  // Disabled - see note above
  // useEffect(() => {
  //   if (repairerUsers) setRepairerUsersList(repairerUsers);
  // }, [repairerUsers]);

  useEffect(() => {
    setFilters({
      beginDate: undefined,
      endDate: undefined,
      unassignedOnly: false,
      staleOnly: false,
      starred: false,
    });
  }, [showAllCases]);

  return (
    <>
      <div className="mb-5 grid grid-cols-2 gap-5">
        <CardWrapperWithHeader title="Categories">
          {chartCategoriesData?.length ? (
            <Chart
              options={getChartOptions(chartCategoriesData)}
              series={chartCategoriesData}
              type="bar"
              height={'250px'}
            />
          ) : chartCategoriesData === null ? (
            'No data available to display.'
          ) : (
            <LoadingSpinner size="xs" />
          )}
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title="Reported faults">
          {chartIssuesTypeData?.length ? (
            <Chart
              options={getChartOptions(chartIssuesTypeData)}
              series={chartIssuesTypeData}
              type="bar"
              height={'250px'}
            />
          ) : chartIssuesTypeData === null ? (
            'No data available to display.'
          ) : (
            <LoadingSpinner size="xs" />
          )}
        </CardWrapperWithHeader>
      </div>
      <div className="relative z-10 flex w-full items-center justify-between py-4">
        <div className="absolute top-[60px] right-5">
          <ButtonIcon
            label={'Filters'}
            iconLeft={true}
            Icon={FunnelIcon}
            onClick={() => setIsFilterModalOpen(true)}
            kind={'bgLightGreen'}
            count={countFilters()}
          />
        </div>
        {isFilterModalOpen && (
          <div className="absolute top-28 right-0 z-10">
            <FiltersModal
              page={
                location?.pathname?.includes(ROUTE_NAME.MY_CLAIMS)
                  ? 'my-claims'
                  : 'claims'
              }
              handleClose={() => setIsFilterModalOpen(false)}
              data={filters}
              onChange={onChangeFilters}
            />
          </div>
        )}
      </div>
      <div className="relative z-0">
        <Table
          data={tableData}
          columns={columns}
          //manualFiltering
          muiTableBodyRowProps={({ row }: any) => ({
            className: row.original.stale
              ? '!bg-gray-50 !hover:bg-gray-50'
              : 'relative z-0 hover:z-10',
          })}
          initialState={{
            columnPinning: { right: ['actions'] },
            sorting: [{ id: 'timeStamp', desc: true }],
            showGlobalFilter: true,
            //pagination: { pageSize: 5, pageIndex: 1 }
          }}
          muiSearchTextFieldProps={{
            placeholder: 'Search by warranty ID or product name',
          }}
          useCallbackClickRow={(data: {
            link: any;
            docid: string;
            product: string;
            extraData: any;
          }) =>
            //TODO: remove Link and set back claimId
            navigate(ROUTE_NAME.CLAIMS + '/' + data.link, {
              state: {
                docid: data.docid,
                link: data.link,
                productName: data.product,
                extraData: data.extraData,
              },
            })
          }
          state={{
            isLoading: caseList.isLoading,
          }}
        />
      </div>
      {deleteClaimOpen && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={modalSettings.kind}
          settings={modalSettings.settings}
        />
      )}
    </>
  );
}

export default LiveClaimsView;
