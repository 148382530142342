import { UploadedFileCategories } from '@rabbit/elements/shared-types';
import { SageFileUploader } from '../upload-wrapper/SageFileUploader';
import { Button, LoadingSpinner } from '@rabbit/elements/shared-components';
import { useContext, useEffect } from 'react';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';
import {
  CaseFlow_Utils_RegisterFileUpload,
  FileStorageContext,
} from '@rabbit/bizproc/react';

interface ClaimPopAndEvidenceUploadProps {
  handleClose: () => void;
  shouldCleanupOnUnmount?: boolean;
}

function ClaimPoPAndEvidenceUpload({
  handleClose,
  shouldCleanupOnUnmount,
}: ClaimPopAndEvidenceUploadProps) {
  const context = useContext(CaseflowContext);
  const { caseFlowCase, caseFacts, caseActors, alterCaseFacts } = context || {};

  const {
    uploadQueueState,
    moveCompletedUploadsToAttached,
    updateHoldingWithFiles,
    unusedCompletedCleanup,
    setShouldRefetch,
  } = useContext(FileStorageContext) || {};

  // Remove uploads that haven't been saved (IF told to do so)
  useEffect(() => {
    return () => {
      if (shouldCleanupOnUnmount) unusedCompletedCleanup?.();
    };
  }, []);

  if (
    !caseFlowCase ||
    !caseFacts ||
    !caseActors ||
    !alterCaseFacts ||
    !caseFacts?.consumer_holding ||
    !caseFacts.consumer_claim_evidence ||
    !caseFacts.consumer_proof_of_purchase ||
    !uploadQueueState ||
    !moveCompletedUploadsToAttached ||
    !updateHoldingWithFiles ||
    !setShouldRefetch
  ) {
    return <LoadingSpinner size="md" />;
  }

  return (
    <>
      <SageFileUploader
        label="Proof of purchase"
        identifiers={{
          category: UploadedFileCategories.ConsumerProofPurchase,
          docType: { docid: caseFacts?.consumer_holding, type: 'case' },
          personaId: caseActors?.consumer ?? '',
        }}
        alterCaseFacts={alterCaseFacts}
        accepts={['image/*', '.pdf']}
        shouldAutoUpdateDocs={false}
        currentFiles={caseFacts?.consumer_proof_of_purchase ?? []}
      />
      <SageFileUploader
        label="Supporting photos"
        identifiers={{
          category: UploadedFileCategories.ConsumerCaseEvidence,
          docType: { docid: caseFacts?.consumer_holding, type: 'case' },
          personaId: caseActors?.consumer ?? '',
        }}
        alterCaseFacts={alterCaseFacts}
        accepts={['image/*', 'video/*', '.pdf']}
        currentFiles={caseFacts?.consumer_claim_evidence ?? []}
      />
      <div className="mt-4 flex w-full gap-2">
        <Button
          kind="primary"
          onClick={async () =>
            await CaseFlow_Utils_RegisterFileUpload(caseFlowCase, {
              uploadQueueState,
              moveCompletedUploadsToAttached,
              setShouldRefetch,
              updateHoldingWithFiles,
            })
          }
        >
          Save
        </Button>
        <Button kind="red" onClick={handleClose}>
          Close
        </Button>
      </div>
    </>
  );
}

export default ClaimPoPAndEvidenceUpload;
