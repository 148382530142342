import { Form, Formik, FormikProps } from 'formik';
import {
  Button,
  getCountryByLabel,
  Input,
  InputTypeSelectAddress,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { Address } from '@rabbit/data/types';
import { useAppInfo } from '@rabbit/sage/utils/helpers';
import { SelectAddressShape } from '@rabbit/elements/shared-types';
import { LIST_COUNTRIES } from '@rabbit/bizproc/react';

const validationSchema = Yup.object().shape({
  line1: Yup.string().trim().required('Please enter an address.'),
  line2: Yup.string().trim(),
  town: Yup.string().trim().required('Please enter a city / suburb.'),
  state: Yup.string().trim().required('Please enter a state / territory.'),
  postcode: Yup.string().trim().required('Please enter a post code.'),
  country: Yup.string().trim().required('Please enter a country.'),
});

export function ModalEditAddAddress({
  handleClose,
  address,
  handleSubmit,
}: {
  handleClose: () => void;
  address: Address | undefined;
  handleSubmit: (address: Address) => void;
}) {
  const appInfo = useAppInfo();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formikRef = useRef<FormikProps<Address>>(null);

  const initialValues: Address = {
    line1: address?.line1 || '',
    line2: address?.line2 || '',
    town: address?.town || '',
    state: address?.state || '',
    postcode: address?.postcode || '',
    country: appInfo.country || '',
  };

  const handleSelectorChange =
    (props: FormikProps<Address>) => async (address?: SelectAddressShape) => {
      if (address) {
        // props.resetForm();
        await props.setFieldValue('line1', address.line_1);
        await props.setFieldValue('line2', address.line_2);
        await props.setFieldValue('town', address.locality);
        await props.setFieldValue('state', address.province_name);
        await props.setFieldValue('postcode', address.postal_code);
        await props.setFieldValue(
          'country',
          getCountryByLabel(address.country_name)?.value
        );
        props.setErrors({});
      }
    };

  useEffect(() => {
    void (async () => {
      if (address?.line1 && formikRef.current) {
        await formikRef.current.setFieldValue('line1', address?.line1);
        await formikRef.current.setFieldValue('line2', address?.line2);
        await formikRef.current.setFieldValue('town', address?.town);
        await formikRef.current.setFieldValue('state', address?.state);
        await formikRef.current.setFieldValue('postcode', address?.postcode);
        await formikRef.current.setFieldValue('country', address?.country);
      } else {
        await formikRef.current?.setFieldValue('country', appInfo?.country);
        formikRef.current?.setErrors({});
      }
    })();
  }, [formikRef.current]);

  const handleFormSubmit = async (values: Address) => {
    setIsSubmitting(true);
    await handleSubmit({ ...address, ...values });
    setIsSubmitting(false);
    handleClose();
  };

  return (
    <Modal
      kind="generic"
      settings={{
        title: address ? 'Edit address' : 'Add address',
        handleClose,
      }}
      isLoading={isSubmitting}
      className="m-auto w-[724px] rounded-md border bg-white"
    >
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={false}
        innerRef={formikRef}
      >
        {(props) => {
          return (
            <Form className="mt-2 flex w-full flex-col gap-4 px-5">
              <Input
                type="select"
                label="Country"
                name="country"
                settings={{
                  options: LIST_COUNTRIES,
                  placeholder: 'Country',
                  values: address?.country,
                }}
              />
              <div>
                <InputTypeSelectAddress
                  errors={Object.values(props.errors).join(' ')}
                  onChange={handleSelectorChange(props)}
                  country={props.values.country || appInfo.country}
                />
              </div>
              <div>
                <Input
                  type="text"
                  label="Address line"
                  name="line1"
                  settings={{
                    placeholder: 'Address line 1',
                  }}
                />
              </div>
              <div>
                <Input
                  type="text"
                  label="Address line 2"
                  name="line2"
                  settings={{
                    placeholder: 'Address line 2',
                  }}
                />
              </div>
              <div className="flex gap-3">
                <Input
                  type="text"
                  label="City/Suburb"
                  name="town"
                  settings={{ placeholder: 'City ' }}
                />
                <Input
                  type="text"
                  label="State/Territory"
                  name="state"
                  settings={{
                    placeholder: 'State',
                  }}
                />
              </div>
              <div className="flex gap-3">
                <Input
                  type="text"
                  label="Post code"
                  name="postcode"
                  settings={{
                    id: 'postcode',
                    placeholder: 'Post code',
                  }}
                />
              </div>
              <div className="grid grid-cols-2 gap-5 pt-4 sticky bottom-0 bg-white">
                <Button kind="primary" type="submit" children="Save" />
                <Button kind="red" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
