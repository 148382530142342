import { Button } from '@rabbit/elements/shared-components';
import {
  Address,
  DTConsumer_Private,
  DTConsumer_Public,
} from '@rabbit/data/types';
import { useState } from 'react';
import { printAddress } from '../../../utils/helpers';
import { ModalEditAddAddress } from '../ModalEditAddAddress/ModalEditAddAddress';

export const ModalNewEditCustomerAddress = ({
  customerDetails: defaultCustomerDetails,
  onSelected,
  selected,
  addresses: defaultAddresses,
  handleClose,
}: {
  customerDetails: DTConsumer_Private | null;
  onSelected: (addresses: Address[], selected: Address) => void;
  handleClose: () => void;
  selected: Address | undefined;
  addresses: Address[];
}) => {
  const [customerDetails, setCustomerDetails] = useState<any>(
    defaultCustomerDetails
  );
  const address =
    defaultAddresses.length > 0 ? defaultAddresses : customerDetails?.address;
  const [addresses, setAddresses] = useState<Address[]>(() =>
    address ? (Array.isArray(address) ? address : [address]) : []
  );
  const [selectedAddress, setSelectedAddress] = useState<Address | undefined>(
    selected
  );
  const [showModal, setShowModal] = useState(false);

  const handleAddressEdit = (newAddress: Address) => {
    setSelectedAddress(newAddress);
    setAddresses([...addresses, newAddress]);
    onSelected([...addresses, newAddress], newAddress);
  };

  const selectAddress = (address: Address) => {
    setSelectedAddress(address);
    onSelected(addresses, address);
    handleClose();
  };

  const handleAddAddress = () => {
    setShowModal(true);
  };

  return (
    <div className="flex flex-col gap-4 p-5 pb-1">
      {addresses.length === 0 && (
        <div className="rounded-lg border border-gray-200 bg-gray-100 px-4 py-2">
          <span className="italic text-gray-400">No address to display</span>
        </div>
      )}
      {addresses.map((address: Address, index: number) => (
        <div
          className={`flex cursor-pointer items-center gap-4 rounded-lg border border-gray-300 p-4 hover:bg-gray-50 ${
            selectedAddress?.line1 === address.line1 ? 'border-green-500' : ''
          }`}
          onClick={() => {
            addresses.forEach((address) => (address.isDefault = false));
            address.isDefault = true;
            selectAddress(address);
          }}
        >
          <div
            className={`h-4 w-4 rounded-lg border border-gray-400 ${
              selectedAddress?.line1 === address.line1
                ? 'border-[4px] border-green-600'
                : ''
            }`}
          ></div>
          {printAddress(address)}
        </div>
      ))}
      <div>
        <Button kind="outline" onClick={handleAddAddress}>
          Add address
        </Button>
      </div>
      {showModal && (
        <ModalEditAddAddress
          address={undefined}
          handleClose={() => setShowModal(false)}
          handleSubmit={handleAddressEdit}
        />
      )}
    </div>
  );
};
