import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Input,
  InputTypeSelectAddress,
  getCountryByLabel,
} from '@rabbit/elements/shared-components';
import { useEffect, useRef, useState } from 'react';
import { CONTACT_OPTIONS, LIST_COUNTRIES } from '@rabbit/bizproc/react';
import { SelectAddressShape } from '@rabbit/elements/shared-types';
import { useAppInfo } from '@rabbit/sage/utils/helpers';
import { NewClaimModalSteps } from '../../ModalNewClaim/ModalNewClaim';

export const validationSchema = Yup.object().shape({
  consumer_first_name: Yup.string()
    .required('Please enter a first name')
    .matches(
      /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
      'First name must start with a capital letter and contain only letters.'
    ),
  consumer_last_name: Yup.string()
    .trim()
    .required('Please enter a last name')
    .matches(
      /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
      'Last name must start with a capital letter and contain only letters.'
    ),
  consumer_email: Yup.string()
    .email('Please enter a valid email address')
    .required("Email can't be empty"),
  consumer_telephone: Yup.string()
    .min(6, 'Phone number must be at least 6 characters')
    .required('Please enter a telephone'),
  consumer_preferred_contact: Yup.array()
    .min(1, 'Please select at least one preferred contact method')
    .typeError('Please select a preferred contact method')
    .required('Please select a preferred contact method'),
  consumer_address: Yup.mixed(),
});

export interface CreateNewClaimFormProps {
  handleClose: () => void;
  onChange: (step: NewClaimModalSteps, data: any) => void;
  data: any;
}

interface ConsumerDataShape {
  consumer_first_name: string;
  consumer_last_name: string;
  consumer_email: string;
  consumer_telephone: string;
  consumer_telephone_e164: string;
  consumer_preferred_contact: string[];
  consumer_address: {
    postcode: string;
    line1: string;
    line2: string;
    town: string;
    state: string;
    country: string;
  };
}

export function CreateNewClaimForm(props: CreateNewClaimFormProps) {
  const { onChange, handleClose } = props;
  const appInfo = useAppInfo();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAddress, setSelectedAddress] =
    useState<SelectAddressShape | null>({} as SelectAddressShape);

  const formikRef = useRef(null) as any; //TODO

  const initialValues: ConsumerDataShape = {
    consumer_address: {
      country: appInfo.country ?? '',
    },
  } as any;

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    const data = {
      ...values,
      consumer_telephone:
        values.consumer_telephone_e164 ?? values.consumer_telephone,
    };
    onChange('add-product', data);
  };

  useEffect(() => {
    if (!selectedAddress) {
      formikRef.current.resetForm();
      return;
    }
    if (formikRef && selectedAddress?.postal_code) {
      formikRef.current.setFieldValue(
        'consumer_address.line1',
        selectedAddress.line_1
      );
      formikRef.current.setFieldValue(
        'consumer_address.line2',
        selectedAddress.line_2
      );
      formikRef.current.setFieldValue(
        'consumer_address.town',
        selectedAddress.locality
      );
      formikRef.current.setFieldValue(
        'consumer_address.state',
        selectedAddress.province_name
      );
      formikRef.current.setFieldValue(
        'consumer_address.postcode',
        selectedAddress.postal_code
      );
      formikRef.current.setFieldValue(
        'consumer_address.country',
        getCountryByLabel(selectedAddress.country_name ?? '')?.value ?? ''
      );
    }
  }, [selectedAddress]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnBlur={false}
      innerRef={formikRef}
    >
      {(props) => (
        <Form className="flex-col gap-3 p-5">
          {/* Input autocomplete consumer - disabled until feature is fully implemented */}
          {/* <div className="flex mb-4">
            <Input
              type="autocomplete-consumer"
              name="consumer" // consumerid?
              label="User*"
              settings={{
                id: 'consumer',
                placeholder: 'Enter an email to start searching',
                hint: '*required',
              }}
            />
          </div> */}
          <div className="flex gap-4">
            <Input
              type="text"
              name="consumer_first_name"
              label="First name*"
              settings={{
                id: 'consumer_first_name',
                placeholder: 'First name',
                hint: '*required',
              }}
            />
            <Input
              type="text"
              name="consumer_last_name"
              label="Last name*"
              settings={{
                id: 'consumer_last_name',
                placeholder: 'Last name',
                hint: '*required',
              }}
            />
          </div>
          <div className="mt-4 flex">
            <Input
              type="email"
              name="consumer_email"
              label="Email*"
              settings={{
                id: 'consumer_email',
                placeholder: 'example@example.com',
                hint: '*required',
              }}
            />
          </div>
          <div className="mt-4 flex items-start gap-4">
            <Input
              type="phone"
              name="consumer_telephone"
              label="Phone number*"
              settings={{
                id: 'consumer_telephone',
                placeholder: 'Phone number',
                hint: '*required',
              }}
            />
            <Input
              type="select"
              name="consumer_preferred_contact"
              label="Preferred contact method*"
              settings={{
                isMulti: true,
                id: 'consumer_preferred_contact',
                placeholder: 'Please select',
                options: CONTACT_OPTIONS,
                errors: props.errors,
                hint: '*required',
              }}
            />
          </div>
          <div className="mt-4">
            <div className="mb-2">
              <label className="font-nunito text-base font-medium">
                Customer address
              </label>
            </div>
            <Input
              type="select"
              label="Country"
              name="consumer_address.country"
              settings={{
                options: LIST_COUNTRIES,
              }}
            />
            <InputTypeSelectAddress
              onChange={(address) => setSelectedAddress(address)}
              errors={props.errors['consumer_address']}
              country={props.values?.consumer_address?.country}
            />
          </div>
          <div className="mt-4">
            <Input
              type="text"
              label="Address line"
              name="consumer_address.line1"
              settings={{}}
            />
          </div>
          <div className="mt-4">
            <Input
              type="text"
              label="Address line 2"
              name="consumer_address.line2"
              settings={{}}
            />
          </div>
          <div className="mt-4 flex gap-3">
            <Input
              type="text"
              label="City/Suburb"
              name="consumer_address.town"
              settings={{}}
            />
            <Input
              type="text"
              label="State/Territory"
              name="consumer_address.state"
              settings={{}}
            />
          </div>
          <div className="mt-4 flex gap-3">
            <Input
              type="text"
              label="Post code"
              name="consumer_address.postcode"
              settings={{
                id: 'zip_code',
                placeholder: '',
              }}
            />
          </div>
          <div className="mt-5 flex w-full gap-4">
            <Button kind="primary" type="submit" loading={isSubmitting}>
              Continue{' '}
            </Button>
            <Button kind="outline_red" type="submit" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateNewClaimForm;
