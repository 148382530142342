import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { getConsumerURL, useAppInfo } from 'apps/sage/src/utils/helpers';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { MANDRILL_TEMPLATES, useSendEmail } from '@rabbit/bizproc/react';

export interface ContactCustomerModalProps {
  messageCustomer?: boolean;
}

interface FormValueShape {
  comment_to_customer: string;
  internal_comment: string;
}

const initialValues: FormValueShape = {
  comment_to_customer: '',
  internal_comment: '',
};

const validationSchema = Yup.object().shape({
  comment_to_customer: Yup.string()
    .trim()
    .required('Please enter a message')
    .test('remove-html-tags', 'Please insert a message.', (value) => {
      const div = document.createElement('div');
      div.innerHTML = value || '';
      return !value || div.textContent?.trim() !== '';
    }),
  internal_comment: Yup.string().trim(),
});

export function ContactCustomerModal({
  messageCustomer,
}: ContactCustomerModalProps) {
  const appInfo = useAppInfo();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);

  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    consumerPersonaData,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersona ||
    !consumerPersonaData ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const { SE_Sage_Blank } = useSendEmail();

  const business_name = appInfo.name ?? '';

  const onSubmit = async (values: FormValueShape) => {
    const { internal_comment, comment_to_customer } = values;
    setIsSubmitting(true);

    messageCustomer
      ? executeAction('message_customer')
      : executeAction('contact_customer_generic');

    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
        comment_to_customer: comment_to_customer,
      };
      const to = caseFacts?.consumer_email ?? consumerPersonaData.email,
        from = appInfo.email_sender,
        template = MANDRILL_TEMPLATES.BODY_BLANK,
        business_name = appInfo.name ?? '',
        claim_id = caseId,
        first_name = caseFacts?.consumer_name ?? '',
        product_name = caseFacts.consumer_holding_name ?? '',
        subject = 'New message from ' + business_name + ' - ' + product_name,
        link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;

      if (Object.keys(factsToAlter).length > 0) {
        await alterCaseFacts(factsToAlter);
        await alterCasePublicEmail({
          context: 'message_customer',
          from,
          to,
          subject,
          template,
          substitutions: {
            subject,
            business_name,
            claim_id,
            first_name,
            product_name,
            comment_to_customer,
            link_to_claim,
          },
        });
      }
      await SE_Sage_Blank(
        to,
        from,
        subject,
        appInfo.email_main_template,
        business_name,
        claim_id,
        first_name,
        product_name,
        comment_to_customer,
        link_to_claim
      );

      setShowModal(false);
      toast.success('Email successfully sent to user.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  };

  return (
    <Modal
      settings={{
        title: 'Contact customer',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      kind="generic"
      isLoading={isSubmitting}
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors }) => {
          return (
            <Form className="flex flex-col px-4 mt-5">
              <Input
                type="rich-text"
                label="Comments to customer*"
                name="comment_to_customer"
                settings={{
                  id: 'comment_to_customer',
                  hint: '*required',
                }}
              />
              {!showInternalComment && (
                <div className="mt-4">
                  <Button
                    kind="outline"
                    type="button"
                    className="w-full"
                    onClick={() => setShowInternalComment(true)}
                  >
                    Add internal comment
                  </Button>
                </div>
              )}
              {showInternalComment && (
                <div className="mt-3">
                  <Input
                    type="rich-text"
                    label="Internal comment"
                    name="internal_comment"
                    settings={{
                      id: 'internal_comment',
                      placeholder: '',
                      allowSpecialCharacter: true,
                    }}
                  />
                </div>
              )}
              <div className="flex mt-8 gap-8">
                <Button kind="primary" type="submit" loading={isSubmitting}>
                  Notify customer
                </Button>
                <Button
                  kind="outline_red"
                  type="submit"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ContactCustomerModal;
