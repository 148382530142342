import {
  Button,
  ButtonIcon,
  FiltersModal,
  formatUnixTime,
  Heading,
  Modal,
  Table,
} from '@rabbit/elements/shared-components';
import { useGetRepairerPeers } from '@rabbit/bizproc/react';
import imagePlaceholder from '../../../assets/images/profile-placeholder.png';
import { FunnelIcon, PlusIcon } from '@heroicons/react/24/solid';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { DTRepairer_Public } from '@rabbit/data/types';
import { useState } from 'react';
import { InviteUserModal } from './InviteUserModal/InviteUserModal';
import { EditUserModal } from './EditUserModal/EditUserModal';
import { useTranslation } from 'react-i18next';

interface AccountTeamMembersListItemShape {
  name: string;
  email: string;
  accountType: 'Admin' | 'Standard';
  isActive: boolean;
}

interface FormValues {
  beginDate?: Date | undefined;
  endDate?: Date | undefined;
}

export interface UsersTabProps {
  data: AccountTeamMembersListItemShape[];
  onClick?: () => void;
}

const tableheaders = [
  {
    header: 'name',
    accessorKey: 'name',
    size: 20,
    Cell: ({
      row,
    }: {
      row: {
        original: {
          imageUrl: string;
          name: string;
          email: string;
        };
      };
    }) => (
      <div className="flex items-center gap-6">
        <div className="flex gap-[10px]">
          <img
            src={row.original.imageUrl || imagePlaceholder}
            alt="user profile photo"
            width={'44px'}
            height={'44px'}
            className="h-[44px] w-[44px]"
          />
          <div className="flex flex-col">
            <p>{row.original.name}</p>
            <p className="font-nunito text-[14px] text-gray-600">
              {row.original.email}
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    header: 'role',
    accessorKey: 'role',
    size: 15,
  },
  {
    header: 'last active',
    accessorKey: 'lastActive',
    size: 15,
  },
  {
    header: 'status',
    accessorKey: 'status',
    size: 20,
  },
  {
    header: 'hourly rate',
    accessorKey: 'hourlyRate',
    size: 20,
  },
  {
    header: '',
    title: 'actions',
    accessorKey: 'actions',
    size: 15,
    Cell: ({ renderedCellValue: options }: any) => {
      return (
        <div className="text-primary-900 flex justify-center gap-1 text-right">
          <PencilSquareIcon
            className="h-[20px] w-[20px] opacity-50"
            // onClick={() => {
            //   options.setSelectedUser(options.repairer);
            //   options.setOpenEditUserModal(true)
            // }}
          />
          <TrashIcon
            className="h-[20px] w-[20px] opacity-50"
            //onClick={() => options.setIsConfirmationModalOpen(true)}
          />
        </div>
      );
    },
  },
];

export function UsersTab({ data, onClick }: UsersTabProps) {
  const { t } = useTranslation();
  const tenantLink = t('tenantLink');
  const { repairerPeer, repairerPeerLoading } = useGetRepairerPeers();
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<
    DTRepairer_Public | undefined
  >(undefined);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const repairers = repairerPeerLoading || tenantLink !== 'SHELTA'
    ? []
    : repairerPeer.map((repairer) => ({
        name: repairer.name,
        imageUrl: repairer.photoUrl,
        email: repairer.email,
        role: 'Repairer',
        lastActive: formatUnixTime(
          new Date(repairer.tupdate).getTime(),
          'dd MMMM Y'
        ),
        status: 'Active',
        hourlyRate: '100/hr',
        actions: {
          setOpenEditUserModal,
          repairer,
          setSelectedUser,
          setIsConfirmationModalOpen,
        },
      }));

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState<FormValues>();

  const onChangeFilters = (value: {
    beginDate?: Date | undefined;
    endDate?: Date | undefined;
  }) => {
    setIsFilterModalOpen(false);
    setFilters(value);
  };

  const confirmationModalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: 'You are about to delete this user from your organisation. Are you sure you want to continue?',
      primaryButtonText: 'Yes',
      outlineButtonText: 'No, cancel',
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () => {
        // TODO: Add delete user implementation
        console.log('delete --> selectedRowToDelete ', selectedUser);
      },
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  return (
    <>
      <div className="flex flex-col items-center gap-2">
        <Heading kind="h2">Users</Heading>
        <p className="font-nunito text-lg text-gray-500">
          Manage access and permissions for all the users in organisation
        </p>
      </div>
      <div className="mt-8 w-full">
        <div className="relative z-10 flex w-full items-center justify-between py-4">
          <div className="absolute top-[60px] right-5">
            <div className="flex gap-4">
              <ButtonIcon
                label={'Filters'}
                iconLeft={true}
                Icon={FunnelIcon}
                onClick={() => setIsFilterModalOpen(true)}
                kind={'bgLightGreen'}
                count={
                  (filters && filters.beginDate) || (filters && filters.endDate)
                    ? 1
                    : 0
                }
              />
              <Button
                icon={<PlusIcon />}
                onClick={() => setOpenInviteModal(true)}
                kind="primary"
                size="sm"
                disabled
              >
                Invite user
              </Button>
            </div>
            {isFilterModalOpen && (
              <div className="top-15 absolute right-0">
                <FiltersModal
                  page={'dashboard'}
                  handleClose={() => setIsFilterModalOpen(false)}
                  data={filters}
                  onChange={onChangeFilters}
                />
              </div>
            )}
          </div>
        </div>
        <Table
          columns={tableheaders}
          data={repairers}
          initialState={{
            showGlobalFilter: true,
          }}
          muiSearchTextFieldProps={{
            placeholder: 'Search by name or email address',
          }}
          state={{
            isLoading: repairerPeerLoading,
          }}
        />
      </div>
      {openInviteModal && (
        <InviteUserModal handleClose={() => setOpenInviteModal(false)} />
      )}
      {openEditUserModal && (
        <EditUserModal
          handleClose={() => setOpenEditUserModal(false)}
          user={selectedUser}
        />
      )}
      {isConfirmationModalOpen && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={confirmationModalSettings.kind}
          settings={confirmationModalSettings.settings}
        />
      )}
    </>
  );
}

export default UsersTab;
