import { PencilIcon } from '@heroicons/react/24/outline';
import {TablePartnerPricing, isNegativeOne} from '../../molecules/TablePartnerPricing/TablePartnerPricing';

import {
  CardWrapperWithHeader,
  ButtonIcon,
  LoadingSpinner,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import { useEffect, useState } from 'react';
import { ModalAddWarrantyPlan } from '../ModalAddWarrantyPlan/ModalAddWarrantyPlan';
import { ModalManageWarrantyPlan } from '../ModalManageWarrantyPlan/ModalManageWarrantyPlan';
import {
  DTWarranty_Offer,
  TenantLink,
  WarrantorLink,
  DeciderTableValues,
} from '@rabbit/data/types';
import { useManageWarrantyOffers, useSageAPI } from '@rabbit/bizproc/react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { WarrantyOfferFullDataShape } from '@rabbit/elements/shared-types';

export interface PartnerTablePricingProps {
  partnerTenantLink: TenantLink;
  warrantorLink: WarrantorLink;
}
export interface PricePlansShape {
  title: string;
  tableData: any[];
  originalOffer: WarrantyOfferFullDataShape;
  docid: string;
}

function PartnerTablePricing({
  partnerTenantLink,
  warrantorLink,
}: PartnerTablePricingProps) {
  const { getPartnerTenantData } = useSageAPI();
  const {
    availableOffers,
    isLoading,
    setShouldRefresh,
    getAllWarrantorTemplates,
    setValueByIndex,
    saveLookupTableValues,
    disableWarrantyOffer,
    createWarrantyOfferForPartner,
    generateRiskCategories,
  } = useManageWarrantyOffers(warrantorLink, partnerTenantLink);

  const [pricePlans, setPricePlans] = useState([] as PricePlansShape[]);
  const [addPricePlan, setAddPricePlan] = useState(false);
  const [isAddingPlan, setIsAddingPlan] = useState(false);
  const [managePlans, setManagePlans] = useState(false);
  const [labourRates, setLabourRates] = useState<{
    currency: string;
    default: number;
    tier1: number;
    tier2: number;
    tier3: number;
  }>();
  const { t } = useTranslation();

  const [POLICY_TYPES_OPTIONS, setPolicyTypeOptions] = useState([]);
  const [riskCategoriesData, setRiskCategoriesData] = useState<{
    categories: Array<{ label: string; value: string }>;
    showRiskCategoryDropdown: boolean;
    defaultRiskCategory: string | null;
  }>({ categories: [], showRiskCategoryDropdown: true, defaultRiskCategory: null });

  const convertToTableData = (tables: DeciderTableValues[]) => {
    const tableData = tables.map((table: any, index: number) => {
      const numOfRows = table.dimensionLengths[1];
      
      // Determine which columns should be included
      const includedColumns = Object.keys(table.values).map(key => {
        const column = table.values[key as keyof typeof table.values];
        return Array.isArray(column) && column.some(value => !isNegativeOne(value));
      });
      
      return Array.from({ length: numOfRows }).map((_: any, rowIndex: number) => {
        const newRow: { id: number; duration: any; [key: string]: any } = {
          id: index * Object.keys(table.values).length + rowIndex,
          duration: table.dimensionDetail[1].indices[rowIndex].label,
        };
        
        Object.keys(table.values).forEach((key, colIndex) => {
          if (includedColumns[colIndex]) {
            const columnkey = table.dimensionDetail[0].indices[colIndex].value;
            const value = table.values[key as keyof typeof table.values];
            if (Array.isArray(value)) {
              newRow[columnkey] = String(value[rowIndex]);
            }
          }
        });
        
        return newRow;
      });
    });
    return tableData[0];
  };

  useEffect(() => {
    void getAllWarrantorTemplates().then((res) => {
      res.forEach((offer: any) => {
        setPolicyTypeOptions((prev: any) => {
          if (!prev.some((option: any) => option.value === offer.docid)) {
            return [...prev, { label: offer.title, value: offer.docid }];
          }
          return prev;
        });
      });
    });
  }, []);

  useEffect(() => {
    void (async () => {
      const res: any = await getPartnerTenantData({
        partnerTenant: partnerTenantLink,
        userTenant: t('tenantLink'),
      });
      const labourRates = res?.data?.private?.partnerSettings?.find(
        (i: any) => i.labourRates
      ).labourRates;
      if (labourRates) {
        setLabourRates(labourRates);
      }
    })();
  }, []);

  useEffect(() => {
    if (availableOffers.length) {
      const plans = availableOffers
        .filter((plan) => plan.offer.enabled)
        .map((plan) => ({
          title: plan.offer.title,
          tableData: convertToTableData(plan.lookupTables),
          originalOffer: plan,
          docid: plan.offer.docid,
        }));
      setPricePlans(plans);
      console.log('plans: ', plans);
    }
  }, [
    availableOffers,
    availableOffers.length,
    availableOffers.map((plan) => plan.offer.enabled).join(','),
  ]);

  useEffect(() => {
    if (warrantorLink) {
      const riskCategoriesInfo = generateRiskCategories(warrantorLink);
      setRiskCategoriesData(riskCategoriesInfo);
    }
  }, [warrantorLink]);

  console.log('availableOffers: ', availableOffers);

  const disableOffer = (offer: DTWarranty_Offer) => {
    disableWarrantyOffer(offer)
      .then(() => {
        toast.success('Offer successfully removed.');
        setManagePlans(false);
      })
      .catch((e) => toast.error('Error disabling offer.'));
  };

  const createOffer = async (
    templateLink: string,
    lookupKey: string,
    offerTitle: string
  ) => {
    const lookupKeys = [lookupKey];
    setIsAddingPlan(true);
    try {
      const res = await createWarrantyOfferForPartner(
        templateLink,
        offerTitle,
        lookupKeys
      );
      if (res) {
        if ('message' in res && res.message) toast.info(res.message);
        else toast.success('Offer created.');
        setAddPricePlan(false);
        setIsAddingPlan(false);
      }
    } catch (e) {
      console.log('e: ', e);
      toast.error('Error creating offer.');
      setIsAddingPlan(false);
    }
  };

  const renderTitleValue = ({
    title,
    value,
  }: {
    title: string;
    value: string | number;
  }) => {
    return (
      <div className="font-nunito flex flex-col gap-0">
        <div className="text-sm text-gray-500">{title}</div>
        <div className="text-black">{value}</div>
      </div>
    );
  };

  const labourRatesMap = [
    {
      title: 'Default',
      value: labourRates?.default
        ? getCurrencyFormat(labourRates?.default, labourRates.currency) + '/h'
        : '-',
    },
    {
      title: 'Tier 1',
      value: labourRates?.tier1
        ? getCurrencyFormat(labourRates?.tier1, labourRates?.currency) + '/h'
        : '-',
    },
    {
      title: 'Tier 2',
      value: labourRates?.tier2
        ? getCurrencyFormat(labourRates?.tier2, labourRates?.currency) + '/h'
        : '-',
    },
    {
      title: 'Tier 3',
      value: labourRates?.tier3
        ? getCurrencyFormat(labourRates?.tier3, labourRates?.currency) + '/h'
        : '-',
    },
  ];

  if (isLoading) return <LoadingSpinner size="sm" />;

  return (
    <div className="flex flex-col gap-6">
      {labourRates && (
        <CardWrapperWithHeader title="Labour rates">
          <div className="grid grid-cols-4">
            {labourRatesMap.map((lr) => renderTitleValue(lr))}
          </div>
        </CardWrapperWithHeader>
      )}
      <CardWrapperWithHeader
        title={'Warranty price plan'}
        canCollapse={false}
        smaller={true}
        noPadding={false}
        headerRight={
          <div className="flex gap-2">
            {pricePlans?.length > 0 && (
              <ButtonIcon
                type="primary"
                label="Manage warranty price plan"
                onClick={() => setManagePlans(true)}
                iconLeft={true}
                Icon={PencilIcon}
              />
            )}
            <ButtonIcon
              type="primary"
              label="Add warranty price plan"
              onClick={() => setAddPricePlan(true)}
              iconLeft={true}
            />
          </div>
        }
      >
        <div className="flex flex-col gap-4">
          {pricePlans.map((pricing, index) => {
            return pricing.tableData ? (
              <TablePartnerPricing
                // DON'T USE INDEX AS KEY!!1, this can lead to all sorts of nasty bugs
                // including the one where values would appear pre filled on a new table -DC
                // see: https://react.dev/learn/rendering-lists#why-does-react-need-keys for more info
                key={pricing.docid}
                data={pricing}
                onCellChanged={setValueByIndex}
                onSaveChanges={saveLookupTableValues}
              />
            ) : (
              <LoadingSpinner size="sm" />
            );
          })}
        </div>
        {addPricePlan && (
        <ModalAddWarrantyPlan
          handleClose={() => setAddPricePlan(false)}
          POLICY_TYPES_OPTIONS={POLICY_TYPES_OPTIONS}
          RISK_CATEGORIES_OPTIONS={riskCategoriesData.categories}
          showRiskCategoryDropdown={riskCategoriesData.showRiskCategoryDropdown}
          defaultRiskCategory={riskCategoriesData.defaultRiskCategory}
          onAddPlan={createOffer}
          setShouldRefresh={setShouldRefresh}
          isLoading={isAddingPlan}
        />
      )}
        {managePlans && (
          <ModalManageWarrantyPlan
            handleClose={() => setManagePlans(false)}
            plans={pricePlans}
            onRemovePlan={disableOffer}
          />
        )}
      </CardWrapperWithHeader>
    </div>
  );
}

export default PartnerTablePricing;
