import { useEffect, useState } from 'react';
import {
  ButtonIcon,
  LoadingSpinner,
  Modal,
  ProductsAndPartsFilters,
  SearchBar,
  Table,
} from '@rabbit/elements/shared-components';
import ModalAddEditItem from '../../organisms/ModalNewEditProduct/ModalAddEditItem';
import {
  FunnelIcon,
  PencilSquareIcon,
  PlusIcon,
} from '@heroicons/react/24/solid';
import { useManageFaults } from '@rabbit/bizproc/react';

export const tableheaders = [
  {
    header: 'fault',
    accessorKey: 'fault',
    maxSize: 100,
    muiTableBodyCellProps: {
      className: '',
    },
  },
  {
    header: 'fault category',
    accessorKey: 'faultCategory',
  },
  {
    title: 'actions',
    header: '',
    accessorKey: 'actions',
    size: 15,
  },
];

// Mock data, remove later
const faultsFilters = [
  {
    checkboxName: 'item1',
    label: 'Product defect',
    count: 56,
  },
  {
    checkboxName: 'item2',
    label: 'Water damage',
    count: 97,
  },
  {
    checkboxName: 'item3',
    label: 'Accidental damage',
    count: 234,
  },
];

interface AppliedFilterShape {
  category: string;
  query: string;
}

export default function FaultsTab() {
  const { faultList } = useManageFaults();
  const faultListData = faultList?.data;
  const [formattedFaults, setFormattedFaults] = useState<any[]>([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<'edit' | 'new'>('new');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [itemDetails, setItemDetails] = useState<any>();

  const handleEditModal = (itemDetails?: any) => {
    setItemDetails(itemDetails || null);
    setModalMode(itemDetails ? 'edit' : 'new');
    setEditModalOpen(true);
  };

  useEffect(() => {
    if (faultListData) {
      const formattedFaultsArr = faultListData.map((fault) => {
        return {
          docid: fault.docid,
          fault: fault.title,
          faultCategory: fault.category?.[0] ?? '-',
          details: fault.description,
          actions: (
            <div className="text-center">
              <ButtonIcon
                label={'Edit Item'}
                kind={'bgLightGreen'}
                iconLeft={true}
                Icon={PencilSquareIcon}
                onClick={() => handleEditModal(fault)}
              />
            </div>
          ),
        };
      });
      setFormattedFaults(formattedFaultsArr);
    } else {
      setFormattedFaults([]);
    }
  }, [faultListData]);

  const modalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: 'You are about to delete this fault. Are you sure you want to continue?',
      primaryButtonText: 'Yes',
      outlineButtonText: 'No, cancel',
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () =>
        console.log('delete --> selectedRowToDelete'),
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  return (
    <div className="flex flex-col">
      <div className="relative flex w-full items-center justify-between py-4">
        <div className="absolute right-5 top-[60px] flex w-full justify-end space-x-4">
          <div className="relative z-10">
            {/* // Disabled until fault categories are implemented */}
            {/* <div>
              <ButtonIcon
                label={'Filters'}
                iconLeft={true}
                Icon={FunnelIcon}
                kind={'bgLightGreen'}
                onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
              />
            </div> */}
            {/* {isFilterModalOpen && (
              <div className="absolute right-0 top-10 z-20">
                <ProductsAndPartsFilters
                  title={'Fault category'}
                  items={faultsFilters}
                  onChange={() => void 0}
                  activeCategory={'faults'}
                />
              </div>
            )} */}
          </div>
          <div className="z-10">
            <ButtonIcon
              label={'New fault'}
              Icon={PlusIcon}
              iconLeft={true}
              onClick={() => handleEditModal()}
            />
          </div>
        </div>
      </div>
      <Table
        columns={tableheaders}
        data={formattedFaults}
        initialState={{ showGlobalFilter: true }}
        state={{
          isLoading: faultList.isLoading,
        }}
        muiSearchTextFieldProps={{
          placeholder: 'Search by fault or fault category',
        }}
      />
      {editModalOpen && (
        <div>
          <ModalAddEditItem
            handleClose={() => setEditModalOpen(false)}
            handleDelete={() => setIsConfirmationModalOpen(true)}
            itemDetails={itemDetails}
            kind="faults"
            title={modalMode === 'edit' ? 'Edit fault' : 'New fault'}
            mode={modalMode}
          />
        </div>
      )}
      {isConfirmationModalOpen && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={modalSettings.kind}
          settings={modalSettings.settings}
        />
      )}
    </div>
  );
}
