import Chart from 'react-apexcharts';
import {
  CardWrapperWithHeader,
  chartServiceLevels,
  chartNumberOfClaims,
  chartCommonFaults,
  chartRegistrations,
  chartCategories,
  chartCasesPerTeam,
  chartRepairCosts,
  chartProducts,
  chartNewWarrantyRegistrations,
  chartNewClaimsRegistrations,
  chartAvgResponse,
  chartSaleToFaultRatio,
  chartWarrantyCostsStatus,
  Heading,
  ButtonIcon,
  FiltersModal,
} from '@rabbit/elements/shared-components';
import DashboardStats from '../components/molecules/DashboardStats/DashboardStats';
import MapChart from '../utils/MapChart';
import CardWithTitleAndChart from '../components/molecules/CardWithTitleAndChart/CardWithTitleAndChart';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { useContext, useState } from 'react';
import { UserContext } from '../context/UserContext';
import { useTranslation } from 'react-i18next';
import { getUserPermissions } from '../utils/helpers';
import { Permissions } from '@rabbit/data/types';
import { Navigate } from 'react-router-dom';
import ROUTE_NAME from '../utils/url-constants';

/* eslint-disable-next-line */
export interface DashboardProps {}

export interface FormValues {
  beginDate?: Date | undefined;
  endDate?: Date | undefined;
}

const dealerRegistrations = [
  {
    score: 57,
    store: 'USA',
    rate: 10,
  },
  {
    score: 46,
    store: 'UK',
    rate: 8,
  },
  {
    score: 34,
    store: 'Europe',
    rate: 15,
  },
];

const dealerClaims = [
  {
    score: 113,
    store: 'USA',
    rate: 20,
  },
  {
    score: 97,
    store: 'UK',
    rate: 7,
  },
  {
    score: 76,
    store: 'Europe',
    rate: 11,
  },
];

export function DashboardView(props: DashboardProps) {
  const { t } = useTranslation();
  const contextValues = useContext(UserContext);
  const tenantLink = t('tenantLink');
  const userPermissions = getUserPermissions(tenantLink, contextValues);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState<FormValues>();

  if (
    t('tenantLink') !== 'DEMO' &&
    !userPermissions?.includes(Permissions.DashboardView)
  )
    return <Navigate to={ROUTE_NAME.MANAGE_ACCOUNT} />;

  const onChangeFilters = (value: {
    beginDate?: Date | undefined;
    endDate?: Date | undefined;
  }) => {
    setIsFilterModalOpen(false);
    setFilters(value);
  };

  return (
    <>
      <div className="relative z-10 my-5 flex justify-end">
        <div className="relative z-10">
          <div>
            <ButtonIcon
              label={'Filters'}
              iconLeft={true}
              Icon={FunnelIcon}
              onClick={() => setIsFilterModalOpen(true)}
              kind={'bgLightGreen'}
              count={
                (filters && filters.beginDate) || (filters && filters.endDate)
                  ? 1
                  : 0
              }
            />
          </div>
          {isFilterModalOpen && (
            <div className="top-15 absolute right-0">
              <FiltersModal
                page={'dashboard'}
                handleClose={() => setIsFilterModalOpen(false)}
                data={filters}
                onChange={onChangeFilters}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mb-5 grid grid-cols-3 gap-5">
        <CardWithTitleAndChart
          score={'+8'}
          subtitle={'New warranty registrations'}
          rate={10}
        >
          <Chart
            options={chartNewWarrantyRegistrations.options}
            series={chartNewWarrantyRegistrations.series}
            type="line"
          />
        </CardWithTitleAndChart>
        <CardWithTitleAndChart
          score={'+1'}
          subtitle={'New claim registrations'}
          rate={-6}
        >
          <Chart
            options={chartNewClaimsRegistrations.options}
            series={chartNewClaimsRegistrations.series}
            type="line"
          />
        </CardWithTitleAndChart>
        <CardWithTitleAndChart
          score={'2 days'}
          subtitle={'Avg response Time'}
          rate={10}
        >
          <Chart
            options={chartAvgResponse.options}
            series={chartAvgResponse.series}
            type="line"
          />
        </CardWithTitleAndChart>
        <DashboardStats
          title={'Product Registrations'}
          items={dealerRegistrations}
        />
        <DashboardStats title={'Dealer Claims'} items={dealerClaims} />
        <div className="flex flex-col gap-3 bg-gray-50 p-4">
          <Heading kind={'h3'}>Regional Hotspot</Heading>
          <MapChart />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-5">
        <CardWrapperWithHeader title="Open claims">
          <Chart
            options={chartNumberOfClaims.options}
            series={chartNumberOfClaims.series}
            type="line"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title="Sale to fault ratio">
          <Chart
            options={chartSaleToFaultRatio.options}
            series={chartSaleToFaultRatio.series}
            type="line"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title="Product registrations this month">
          <Chart
            options={chartProducts.options}
            series={chartProducts.series}
            type="bar"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title="Categories">
          <Chart
            options={chartCategories.options}
            series={chartCategories.series}
            type="bar"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title="Registrations by retailer">
          <Chart
            options={chartRegistrations.options}
            series={chartRegistrations.series}
            type="bar"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title="Faults">
          <Chart
            options={chartCommonFaults.options}
            series={chartCommonFaults.series}
            type="bar"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title="Service levels - Claim created to first response">
          <Chart
            options={chartServiceLevels.options}
            series={chartServiceLevels.series}
            type="donut"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title="Open / Closed cases per team member">
          <Chart
            options={chartCasesPerTeam.options}
            series={chartCasesPerTeam.series}
            type="bar"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title="Repair Costs" headerRight={<span className="font-bold text-lg">$175k</span>}>
          <Chart
            options={chartRepairCosts.options}
            series={chartRepairCosts.series}
            type="line"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title="Warranty costs by warranty status" headerRight={<span className="font-bold text-lg">$672k</span>}>
          <Chart
            options={chartWarrantyCostsStatus.options}
            series={chartWarrantyCostsStatus.series}
            type="bar"
          />
        </CardWrapperWithHeader>
      </div>
    </>
  );
}

export default DashboardView;
