import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { useAppInfo } from 'apps/sage/src/utils/helpers';
import { toast } from 'react-toastify';
import { PartValuesShape } from 'apps/sage/types';
import classNames from 'classnames';

export interface PartsUsedModalProps {
  index?: number | null;
  data?: PartValuesShape | null;
  remove?: boolean | null;
}

const validationSchema = Yup.object().shape({
  part_name: Yup.string().required('Please enter a part name').trim(),
  model_id: Yup.string().required('Please enter a model id').trim(),
  parts_quantity: Yup.string().required('Please enter part quantity').trim(),
  parts_cost: Yup.object()
    .shape({
      amount: Yup.string().required('Please enter a part cost').trim(),
      currency: Yup.string(),
    })
    .required('Please enter a part cost'),
});

export function PartsUsedModal({ index, remove, data }: PartsUsedModalProps) {
  const appInfo = useAppInfo();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: PartValuesShape = {
    id: data?.id ?? '',
    part_name: data?.part_name ?? '',
    model_id: data?.model_id ?? '',
    parts_quantity: data?.parts_quantity ?? '',
    parts_cost: data?.parts_cost ?? { amount: '', currency: '' },
  };

  const {
    caseFacts,
    alterCaseFacts,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !moveSpotlight ||
    !operatingPersona ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const onDelete = async () => {
    setIsSubmitting(true);
    const updatedRegistry = caseFacts.repair_work_registry?.map((registry) => {
      if (registry.parts_used_for_repair) {
        registry.parts_used_for_repair = registry.parts_used_for_repair.filter(
          (part: any) => part?.id !== data?.id
        );
      }
      return registry;
    });
    try {
      await alterCaseFacts({ repair_work_registry: updatedRegistry });
      toast.success('Claim updated successfully.');
      setShowModal(false);
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = async (values: PartValuesShape) => {
    setIsSubmitting(true);
    const updatedRegistry = caseFacts.repair_work_registry?.map((registry) => {
      if (registry.parts_used_for_repair) {
        registry.parts_used_for_repair = registry.parts_used_for_repair.map(
          (part: any) => {
            if (part.id === values.id) {
              return {
                ...part,
                part_name: values.part_name,
                model_id: values.model_id,
                parts_quantity: values.parts_quantity,
                parts_cost: values.parts_cost,
              };
            }
            return part;
          }
        );
      }
      return registry;
    });
    try {
      await alterCaseFacts({ repair_work_registry: updatedRegistry });
      toast.success('Claim updated successfully.');
      setShowModal(false);
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      settings={{
        title: 'Parts used',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      kind="generic"
      className={classNames('max-h-[768px] w-full max-w-[1024px]', {
        'max-w-[500px]': remove,
      })}
      isLoading={isSubmitting}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors }) => {
          return (
            <Form className="mt-5 flex flex-col gap-3 px-4">
              {remove ? (
                <div>Are you sure you want to remove this part?</div>
              ) : (
                <>
                  <div className="flex gap-3">
                    <Input
                      type="text"
                      name="part_name"
                      settings={{
                        id: 'part_name',
                        placeholder: 'Part name',
                        hint: '*required',
                      }}
                    />
                    <Input
                      type="text"
                      label=""
                      name="model_id"
                      settings={{
                        id: 'model_id',
                        placeholder: 'Model ID',
                        hint: '*required',
                      }}
                    />
                  </div>
                  <div className="flex gap-3">
                    <Input
                      type="text"
                      name="parts_quantity"
                      settings={{
                        id: 'parts_quantity',
                        placeholder: 'Quantity',
                        hint: '*required',
                      }}
                    />
                    <Input
                      type="currency"
                      name="parts_cost"
                      settings={{
                        placeholder: 'Per unit',
                        currency: appInfo.currency,
                        hint: '*required',
                      }}
                    />
                  </div>
                </>
              )}
              <div className="mt-4 flex gap-3">
                {remove ? (
                  <Button
                    kind="red"
                    type="submit"
                    onClick={onDelete}
                    disabled={isSubmitting}
                  >
                    Delete
                  </Button>
                ) : (
                  <Button kind="primary" type="submit" disabled={isSubmitting}>
                    Save
                  </Button>
                )}
                <Button
                  kind="outline_red"
                  type="submit"
                  onClick={() => setShowModal(false)}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default PartsUsedModal;
