import { LoadingSpinner } from '@rabbit/elements/shared-components';
import { Navigate, useLocation } from 'react-router-dom';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import { CaseflowProviderWrapper } from '../context/CaseflowContext';

import ClaimDetailViewTemplate from '../components/templates/ClaimDetailViewTemplate/ClaimDetailViewTemplate';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { getUserPermissions } from '../utils/helpers';
import { Permissions, PersonaIdTypeSplitter } from '@rabbit/data/types';
import ROUTE_NAME from '../utils/url-constants';

export function ClaimDetailView() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const tenantLink = t('tenantLink');
  const CFG_OPERATING_PERSONA = t('CFG_OPERATING_PERSONA');
  const userPermissions = getUserPermissions(tenantLink, userContext);

  const location = useLocation();
  const caseId = location.pathname.split('/')[2].split('_')[0];

  const { warrantorPersona, repairerPersona } = useGetMySagePersonas();
  if (
    !userPermissions?.includes(Permissions.CasesEdit) &&
    !userPermissions?.includes(Permissions.CasesView) &&
    !userPermissions?.includes(Permissions.Owner)
  )
    return <Navigate to={ROUTE_NAME.MANAGE_ACCOUNT} />;

  if (!warrantorPersona?.personaId && !repairerPersona?.personaId)
    return <LoadingSpinner size="md" />;

  const getOperatingPersonaId = () => {
    const isAdmin = userContext?.accessToken?.admin;
    const personaId = CFG_OPERATING_PERSONA === 'R' ? repairerPersona?.personaId : warrantorPersona?.personaId;
    return isAdmin ? `${CFG_OPERATING_PERSONA}${PersonaIdTypeSplitter}${tenantLink}` : personaId;
  };

  const operatingPersonaId = getOperatingPersonaId();

  if (operatingPersonaId)
  {
    return (
      <CaseflowProviderWrapper
        caseId={caseId}
        operatingPersona={operatingPersonaId}
      >
        <ClaimDetailViewTemplate />
      </CaseflowProviderWrapper>
    );  
  };

  return <LoadingSpinner size="md" />;
}

export default ClaimDetailView;
