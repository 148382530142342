import {
  ShadowContainer,
  Heading,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { NavLink, Navigate } from 'react-router-dom';
import ROUTE_NAME from '../utils/url-constants';
import AuthForm from '../components/templates/AuthForm/AuthForm';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppInfo } from '../utils/helpers';

function SignupView() {
  const appInfo = useAppInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [authErrorMsg, setAuthErrorMsg] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const type: string | 'internal' | 'c' = queryParams.get('c') || 'internal';

  if (appInfo.environment === 'LIVE' && (!token || token !== 'aXcteC1zZw=='))
    return <Navigate to="/" />;

  if (isLoading) return <LoadingSpinner extraClasses={'mt-2'} size={'lg'} />;

  return (
    <ShadowContainer>
      <div className="px-8 py-6">
        <div className="mb-5 mt-4">
          <Heading kind="h2">
            {type === 'internal'
              ? 'Create account'
              : 'Create your partner account for ' + appInfo.name}
          </Heading>
        </div>
        {type === 'internal' ? (
          <AuthForm
            kind="Signup"
            setIsLoading={setIsLoading}
            authErrorMsg={authErrorMsg}
            setAuthErrorMsg={setAuthErrorMsg}
          />
        ) : (
          <AuthForm
            kind="Partner-Signup"
            setIsLoading={setIsLoading}
            authErrorMsg={authErrorMsg}
            setAuthErrorMsg={setAuthErrorMsg}
          />
        )}
        <div>
          <p className="font-nunito mx-auto my-3 text-center text-sm">
            By continuing, you agree to iWarranty's
            <br />
            <a
              href={ROUTE_NAME.TERMS_AND_CONDITIONS}
              className="text-gray-500 hover:text-gray-700"
              target="_blank"
              rel="noreferrer"
            >
              Terms & conditions
            </a>
            &nbsp;and&nbsp;
            <a
              href={ROUTE_NAME.PRIVACY_POLICY}
              className="text-gray-500 hover:text-gray-700"
              target="_blank"
              rel="noreferrer"
            >
              Privacy policy
            </a>
          </p>
        </div>
        <div className="font-nunito my-4 text-base">
          Already have an account? &nbsp;
          <NavLink
            to={ROUTE_NAME.LOGIN}
            className="text-primary-600"
            reloadDocument
          >
            Login here
          </NavLink>
        </div>
      </div>
    </ShadowContainer>
  );
}

export default SignupView;
