import { useEffect, useState } from 'react';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  LoadingSpinner,
  Table,
} from '@rabbit/elements/shared-components';
import ModalAddEditItem from '../components/organisms/ModalNewEditProduct/ModalAddEditItem';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { DTVendable } from '@rabbit/data/types';
import { SearchVendablesByDocid } from '@rabbit/search/cherchons';
import { useLocation } from 'react-router-dom';
import {
  formatVendableCategoriesToString,
  formatVendableDataForEditModal,
} from '@rabbit/bizproc/react';
import mockImage1 from '../../src/assets/images/subframe.png';
import mockImage2 from '../../src/assets/images/powerline.png';
import mockImage3 from '../../src/assets/images/grips.png';
import { formatAndGetLastCategory } from '../utils/helpers';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VendableDetailsViewProps {}

export function VendableDetailsView(props: VendableDetailsViewProps) {
  const location = useLocation();
  const { t } = useTranslation();
  const vendableDocid = location.pathname.split('/')[2];
  const [vendableData, setVendableData] = useState<DTVendable>();
  const [isNewEditModalOpen, setIsNewEditModalOpen] = useState(false);
  const [isNewPartModalOpen, setIsNewPartModalOpen] = useState(false);
  const [isEditFaultModalOpen, setIsEditFaultModalOpen] = useState(false);
  const [isEditPartModalOpen, setIsEditPartModalOpen] = useState(false);
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);

  useEffect(() => {
    getProductDetails(vendableDocid).catch((err) => console.log(err));
  }, [vendableDocid]);

  const getProductDetails = async (docid: string) => {
    const facetFilters = [];
    facetFilters.push({
      name: 'mfr',
      value: `M:${t('tenantLink')}`,
    });

    const options = {
      hitsPerPage: 25,
      page: 0,
      facetFilters,
    };
    const data = await SearchVendablesByDocid(docid, options);

    setVendableData(data.items[0]);
  };

  if (!vendableData) return <LoadingSpinner size="md" extraClasses="mt-16" />;

  return (
    <div className="flex flex-col gap-5">
      <CardWrapperWithHeader
        title={'Product information'}
        // headerRight={
        //   <ButtonIcon
        //     iconLeft={true}
        //     label={'Edit Product'}
        //     kind={'bgLightGreen'}
        //     onClick={() => setIsEditProductModalOpen(true)}
        //     Icon={ArrowTopRightOnSquareIcon}
        //   />
        // }
      >
        {isEditProductModalOpen && (
          <ModalAddEditItem
            handleClose={() => setIsEditProductModalOpen(false)}
            handleDelete={() => console.log('')}
            handleSubmit={() => void 0}
            kind="products"
            title={'Edit product'}
            itemDetails={formatVendableDataForEditModal(vendableData)}
          />
        )}
        <div className="grid grid-cols-5 gap-10">
          {renderData('Brand', `${vendableData.brand}`)}
          {renderData('Model number', `${vendableData.mpn}`)}
          {renderData(
            'Category',
            `${formatAndGetLastCategory(vendableData.category)}`
          )}
          {vendableData?.manual?.[0] && (
            <div className="flex flex-col">
              <p className="font-nunito text-sm text-gray-500">User Manual</p>
              <a
                href={vendableData.manual[0]}
                className="font-nunito no mt-2.5 text-base underline"
              >
                Link
              </a>
            </div>
          )}
        </div>
      </CardWrapperWithHeader>
      {import.meta.env.VITE_DEMO ? (
        <CardWrapperWithHeader
          noPadding={true}
          title={'Faults'}
          headerRight={
            <div className="flex gap-2.5">
              <div>
                <ButtonIcon
                  iconLeft={true}
                  label={'Link existing fault to product'}
                  kind={'bgLightGreen'}
                  onClick={() => setIsEditFaultModalOpen(true)}
                  Icon={ArrowTopRightOnSquareIcon}
                />
                {isEditFaultModalOpen && (
                  <ModalAddEditItem
                    handleClose={() => setIsEditFaultModalOpen(false)}
                    handleDelete={() => console.log('')}
                    handleSubmit={() => void 0}
                    kind="products"
                    title={'Edit product'}
                    itemDetails={{}}
                  />
                )}
              </div>
              <div>
                <ButtonIcon
                  iconLeft={true}
                  label={'Create new fault'}
                  onClick={() => setIsNewEditModalOpen(true)}
                />
                {isNewEditModalOpen && (
                  <ModalAddEditItem
                    handleClose={() => setIsNewEditModalOpen(false)}
                    handleSubmit={() => void 0}
                    handleDelete={() => console.log('')}
                    kind="faults"
                    title={'New fault'}
                  />
                )}
              </div>
            </div>
          }
        >
          <Table columns={faultHeaders} data={faultContent} inner={true} />
        </CardWrapperWithHeader>
      ) : null}

      {import.meta.env.VITE_DEMO ? (
        <CardWrapperWithHeader
          noPadding={true}
          title={'Parts breakdown'}
          headerRight={
            <div className="flex gap-2.5">
              <div className="">
                <ButtonIcon
                  iconLeft={true}
                  label={'Link existing part to product'}
                  kind={'bgLightGreen'}
                  onClick={() => setIsEditPartModalOpen(true)}
                  Icon={ArrowTopRightOnSquareIcon}
                />
                {isEditPartModalOpen && (
                  <ModalAddEditItem
                    handleSubmit={() => void 0}
                    handleClose={() => setIsEditPartModalOpen(false)}
                    handleDelete={() => console.log('')}
                    kind="products"
                    title={'Edit product'}
                    itemDetails={{}}
                  />
                )}
              </div>
              {}
              <div className="">
                <ButtonIcon
                  iconLeft={true}
                  label={'Create new part'}
                  onClick={() => setIsNewPartModalOpen(true)}
                />
                {isNewPartModalOpen && (
                  <ModalAddEditItem
                    handleSubmit={() => void 0}
                    handleClose={() => setIsNewPartModalOpen(false)}
                    handleDelete={() => console.log('')}
                    kind="parts"
                    title={'New part'}
                  />
                )}
              </div>
            </div>
          }
        >
          <Table
            columns={partsHeaders}
            data={partsContent}
            inner={true}
          ></Table>
        </CardWrapperWithHeader>
      ) : null}
    </div>
  );
}

export default VendableDetailsView;

export const faultHeaders = [
  {
    header: 'fault',
    accessorKey: 'fault',
  },
  {
    header: 'fault category',
    accessorKey: 'faultCategory',
  },
  {
    header: 'fault occurences for product',
    accessorKey: 'faultOccurences',
  },
];

export const faultContent = [
  {
    fault: 'Subframe crack RHS',
    faultCategory: 'Product defect',
    faultOccurences: '15',
  },
  {
    fault: 'Subframe crack LHS',
    faultCategory: 'Product defect',
    faultOccurences: '12',
  },
  {
    fault: 'Subframe crack Unilateral',
    faultCategory: 'Product defect',
    faultOccurences: '18',
  },
  {
    fault: 'Battery drain',
    faultCategory: 'Product defect',
    faultOccurences: '13',
  },
];

export const partsHeaders = [
  {
    accessorKey: 'partImage',
    header: 'Part Image',
  },
  {
    accessorKey: 'partName',
    header: 'part name',
  },
  {
    header: 'part number',
    accessorKey: 'partNumber',
  },
  {
    header: 'brand',
    accessorKey: 'brand',
  },
  {
    header: 'part cost',
    accessorKey: 'partCost',
  },
];

export const partsContent = [
  {
    partImage: <img src={mockImage1} alt="productImg"></img>,
    partName: 'Subframe',
    partNumber: 'sub_v85_11',
    brand: 'Moto Guzzi',
    partCost: '£279',
  },
  {
    partImage: <img src={mockImage2} alt="productImg"></img>,
    partName: 'Powerline 12V 5Ah',
    partNumber: 'PTX5L-BS',
    brand: 'Powerline',
    partCost: '£18',
  },
  {
    partImage: <img src={mockImage3} alt="productImg"></img>,
    partName: 'V85 Grips 2022',
    partNumber: '2022_v85_grip1',
    brand: 'Moto Guzzi',
    partCost: '£4',
  },
];

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

/* ----------------------------- Render helpers ----------------------------- */

const renderData = (label: string, value: any) => {
  let renderValue = value;
  if (value === 'undefined') renderValue = '-';
  else if (value && value !== 'undefined' && label === 'Phone number')
    renderValue = `+${value}`;
  return (
    <div>
      <p className="mb-[10px] text-sm text-gray-500">{label}</p>
      <div className="overflow-hidden text-ellipsis font-medium">
        {renderValue ?? '-'}
      </div>
    </div>
  );
};
