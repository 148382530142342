
import * as Yup from 'yup';
import { Button, Heading, formatUnixTime, getClaimLimit, getCurrencyFormat, getFormattedDate, getIncreasedLabourRate } from '@rabbit/elements/shared-components';
import { VEHICLE_CATEGORY_OPTIONS } from '@rabbit/bizproc/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { getUnixTime } from 'date-fns';
import { titleCase, useAppInfo } from '@rabbit/sage/utils/helpers';
import { MileageUnit } from '@rabbit/data/types';
import { useState } from 'react';

export const validationSchema = Yup.object().shape({
  registrationNo: Yup.string()
    .trim()
    .min(5, 'Enter a valid registration plate')
    .required('Enter a registration plate'),
});

export interface NewClaimVehicleSummaryProps {
  handleClose: () => void;
  onChange: any; //TODO
  setIsLoading: (loading: boolean) => void;
  data: any;
}

export function NewClaimVehicleSummary(props: NewClaimVehicleSummaryProps) {
  const { onChange, handleClose, setIsLoading, data } = props;
  const { t } = useTranslation();
  const appInfo = useAppInfo();

  const CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT = t('CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT') === "true";

  const currentWarranty = data?.warranties?.[0];
  const productInfo = data?.holding?.self_registration?.srvInfo?.productInfo;
  const consumer = data?.consumer;

  let expiredWarning = false;
  if(currentWarranty && currentWarranty?.endDate) {
    const expiryDate = new Date(currentWarranty?.endDate);
    const today = new Date();
    if(expiryDate < today) {
      expiredWarning = true;
    }
  };

  const [expand, setExpand] = useState(false);

  const defaultMileageUnit = t('CFG_COBRAND_MILEAGE_UNIT', 'km') as MileageUnit;

  const carRecord = [
    // {
    //   label: 'Registration Plate',
    //   value: String(productInfo?.registrationNo).toUpperCase(),
    // },
    // { label: 'Make', value: productInfo?.make },
    // { label: 'Model', value: productInfo?.model },
    { label: 'Version', value: productInfo?.version },
    { label: 'Body', value: productInfo?.body || '-' },
    {
      label: 'Registration date',
      value: productInfo?.regDate
        ? getFormattedDate(productInfo?.regDate)
        : '-',
    },
    { label: 'Engine cc', value: productInfo?.engineCc || '-' },
    { label: 'Colour', value: productInfo?.colour || '-' },
    { label: 'Fuel', value: productInfo?.fuel || '-' },
    { label: 'Transmission', value: productInfo?.transmission || '-' },
    { label: 'Drive type', value: productInfo?.driveType || '-' },
    { label: 'Year of manufacture', value: productInfo?.yearOfManufacture || '-' },
    { label: 'VIN', value: productInfo?.chassisNo || '-' },
    { label: 'Engine number', value: productInfo?.engineNo || '-' },
    {
      label: 'Current mileage',
      value: productInfo?.mileage
        ? Number(productInfo?.mileage).toLocaleString(appInfo.country) +
        productInfo?.mileageUnit || defaultMileageUnit
        : '-',
    },
    {
      label: 'Last service date',
      value: productInfo?.last_service_date
        ? formatUnixTime(
            getUnixTime(new Date(productInfo?.last_service_date)),
            'dd/MM/yyyy'
          )
        : '-',
    },
    {
      label: 'MOT date expiration',
      value: productInfo?.tech_check_date
        ? formatUnixTime(
            getUnixTime(new Date(productInfo?.tech_check_date)),
            'dd/MM/yyyy'
          )
        : '-',
    },
    {
      label: 'Commercial vehicle',
      value: productInfo?.commercialVehicle ? titleCase(productInfo?.commercialVehicle) : '-',
    },
    {
      label: 'Vehicle Category',
      value: productInfo?.vehicleCategory
        ? VEHICLE_CATEGORY_OPTIONS.find((i) => i.value === productInfo?.vehicleCategory)
            ?.label
        : '-',
    },
    {
      label: 'Purchase price',
      value: productInfo?.purchasePrice
        ? getCurrencyFormat(
          productInfo?.purchasePrice.amount,
          productInfo?.purchasePrice.currency
          )
        : '-',
    },
  ];

  const labourRate = getIncreasedLabourRate(currentWarranty, appInfo.currency);

  return (
    <div className="flex flex-col gap-4 px-5 pt-4">
    {/* Customer car */}
    <div>
      <Heading kind="h4" className="mb-2">
        Customer details
      </Heading>
      <div className="font-nunito flex flex-col rounded-md border border-gray-300 p-4 gap-1">
        <p><span className="text-gray-500">Name:</span> {consumer?.firstName} {consumer?.lastName}</p>
        <p><span className="text-gray-500">Email:</span> {consumer?.consumerEmail}</p>
        {consumer?.address && (
          <p><span className="text-gray-500">Address:</span> {' '}
            {consumer?.address?.postcode}{' '}
            {consumer?.address?.line1}, {consumer?.address?.line2}{' '}
            {consumer?.address?.town} {consumer?.address?.state}{' '}
            {consumer?.address?.country}
          </p>
        )}
        {consumer?.phoneNumber && <p><span className="text-gray-500">Phone:</span> +{consumer?.phoneNumber}</p>}
      </div>
    </div>

      {/* Selected car */}
      <div>
        <Heading kind="h4" className="mb-2">
          Vehicle details
        </Heading>
        <div className="flex flex-col gap-4">
          <div
            className={'font-nunito rounded-md border border-gray-300 p-4'}
            onClick={() => 0}
          >
            <div
              className="grid items-center gap-4"
              onClick={() => setExpand(!expand)}
            >
              {/* <div className="h-[64px] rounded-md bg-red-700">
                <img
                  src={productPlaceholder}
                  className="h-[64px] w-[64px] object-cover"
                />
              </div> */}
              <div className="flex h-fit cursor-pointer items-center justify-between">
                <div className='flex flex-col gap-1'>
                  <p>
                    <span className="text-gray-500">Make:</span> {productInfo?.make || productInfo?.brand}
                  </p>
                  <p>
                    <span className="text-gray-500">Model:</span> {productInfo?.model} {productInfo?.version}
                  </p>
                  <p>
                    <span className="text-gray-500">Registration plate:</span> <span className="uppercase">{productInfo?.registrationNo}</span>
                  </p>
                </div>
                <ChevronDownIcon
                  className={
                    'relative h-6 w-6 text-gray-900' +
                    (expand ? ' rotate-180' : '')
                  }
                />
              </div>
            </div>
            {expand && (
              <div className="mt-6 grid grid-cols-3 gap-4">
                {carRecord.map((record, index) => (
                  <div key={'record' + index} className="flex flex-col">
                    <span className="font-sm text-gray-500">
                      {record?.label}
                    </span>
                    <div className="font-medium text-black">
                      {record?.value}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    
      {/* Warranty info */}
      <div>
        <Heading kind="h4" className="mb-2">
          Warranty plan
        </Heading>
        <div className="flex flex-col gap-4">
          <div
            className={'font-nunito rounded-md border border-gray-300 p-4 flex flex-col gap-2'}
          >
            <div className="flex gap-2">
              <span className="text-black font-semibold">{currentWarranty?.templateTitle}</span>
              {expiredWarning && <span className="text-xs text-red-700 bg-red-100 flex items-center py-1 px-2 rounded capitalize">Expired</span> }
            </div>
            <div>
              <span className="text-gray-500">Start date:</span> {getFormattedDate(currentWarranty?.startDate)}
            </div>
            <div>
              <span className="text-gray-500">Term:</span> {currentWarranty?.decider?.stipulated?.duration?.label}
            </div>
            <div>
              <span className="text-gray-500">Claim limit:</span> {getClaimLimit(currentWarranty, appInfo.currency, CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT)}
            </div>
            {labourRate?.length ? (
              <div>
                <span className="text-gray-500">Labour rate:</span> {labourRate}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="sticky bottom-0 -mb-2 flex w-full gap-4 bg-white py-4">
        <Button
          kind="primary"
          type="submit"
          onClick={() => onChange(2, data)}
        >
          Continue
        </Button>
        <Button kind="red" type="submit" onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default NewClaimVehicleSummary;
