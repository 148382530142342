import {
  Button,
  Input,
  PasswordValidationPopover,
  asyncValidateSchema,
  handlePopoverData,
} from '@rabbit/elements/shared-components';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import {
  NavLink,
  Navigate,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import * as Yup from 'yup';
import { firebaseAuth } from '@rabbit/firebase/adapter-react';
import { toast } from 'react-toastify';
import ROUTE_NAME from 'apps/sage/src/utils/url-constants';

interface PWDRNewPasswordProps {
  onChangeStep: (data: string) => void;
  data: any; //todo
}

// Formik
export interface FormValuesShape {
  password: string;
  confirm_password: string;
}

const initialValues: FormValuesShape = {
  password: '',
  confirm_password: '',
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password should be 8 or more characters long')
    .matches(/(?=.*[a-z])/, 'Please enter at least 1 lowercase letter')
    .matches(/(?=.*[A-Z])/, 'Please enter at least 1 uppercase letter')
    .matches(/^(?=.*[0-9])/, 'Please enter at least 1 number')
    .required('Please enter your password'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Please confirm your password'),
});

function PWDRNewPassword({ onChangeStep, data }: PWDRNewPasswordProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [authErrorMsg, setAuthErrorMsg] = useState('');
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get('email');
  const oobCode = searchParams.get('oobCode');
  const navigate = useNavigate();

  useEffect(() => {
    if (email && oobCode) {
      verifyPasswordResetCode(firebaseAuth, oobCode).catch((err) => {
        console.log(err);
        navigate('/');
      });
    }
  }, [email, oobCode]);

  const onSubmit = async (values: FormValuesShape) => {
    if (email && oobCode && values.password) {
      confirmPasswordReset(firebaseAuth, oobCode, values.password)
        .then(() => {
          navigate('/');
          toast.success('Password updated successfully.');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={asyncValidateSchema(validationSchema)}
      validateOnBlur
      onSubmit={onSubmit}
    >
      {({ errors, values }) => {
        console.log(errors);
        return (
          <Form className="font-nunito flex flex-col gap-5">
            <p className="font-nunito text-base">
              Please enter your new password
            </p>
            <Input
              type="password"
              name="password"
              label="Password"
              settings={{
                placeholder: 'Please type your password',
                id: 'password',
                popoverTrigger: 'hover',
                popoverPlacement: 'right',
                popoverTarget: 'popover-password',
                hint: '*required',
              }}
            />
            <Input
              type="password"
              name="confirm_password"
              label="Confirm Password"
              settings={{
                placeholder: 'Please type your password',
                id: 'confirm_password',
                hint: '*required',
              }}
            />
            <PasswordValidationPopover
              id="popover-password"
              errors={handlePopoverData(errors.password, values.password)}
            />

            <Button
              kind="primary"
              type="submit"
              disabled={Object.keys(errors).length > 0 || isLoading}
              loading={isLoading}
            >
              Submit
            </Button>
            <div className="font-nunito mt-4 text-base">
              <NavLink
                to={ROUTE_NAME.LOGIN}
                className="text-primary-600"
                reloadDocument
              >
                Back to Login
              </NavLink>
            </div>
            {authErrorMsg && (
              <p className="font-nunito text-sm text-red-500">{authErrorMsg}</p>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default PWDRNewPassword;
