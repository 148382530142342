import { useEffect, useState } from 'react';
import {
  ButtonIcon,
  LoadingSpinner,
  Modal,
  ProductsAndPartsFilters,
  SearchBar,
  Table,
} from '@rabbit/elements/shared-components';
import ModalAddEditItem from '../../organisms/ModalNewEditProduct/ModalAddEditItem';
import {
  FunnelIcon,
  PencilSquareIcon,
  PlusIcon,
} from '@heroicons/react/24/solid';
import {
  ConsumerIssueTypeHookOrigins,
  useManageConsumerIssueTypes,
  useManageFaults,
} from '@rabbit/bizproc/react';
import { DTConsumerIssueType } from '@rabbit/data/types';

export const tableheaders = [
  {
    header: 'label',
    accessorKey: 'label',
    maxSize: 100,
    muiTableBodyCellProps: {
      className: '',
    },
  },
  {
    header: 'details',
    accessorKey: 'details',
  },
  {
    header: 'enabled',
    accessorKey: 'enabled',
  },
  {
    title: 'actions',
    header: '',
    accessorKey: 'actions',
    size: 15,
  },
];

export default function IssueTypesTab() {
  const { consumerIssueTypeList } = useManageConsumerIssueTypes(
    ConsumerIssueTypeHookOrigins.SAGE_MANAGE
  );
  const [formattedIssueTypes, setFormattedIssueTypes] = useState<any[]>([]); // todo needs to be typed
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<'edit' | 'new'>('new');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [itemDetails, setItemDetails] = useState<DTConsumerIssueType | null>();

  const handleEditModal = (itemDetails?: DTConsumerIssueType) => {
    setItemDetails(itemDetails || null);
    setModalMode(itemDetails ? 'edit' : 'new');
    setEditModalOpen(true);
  };

  //console.log('consumerIssueTypeList in tab', consumerIssueTypeList); //@TEST_REMOVE_LATER
  useEffect(() => {
    if (consumerIssueTypeList.isSuccess && consumerIssueTypeList?.data) {
      const formattedIssueTypeArr = consumerIssueTypeList?.data.map(
        (issueType) => {
          return {
            docid: issueType.docid,
            label: issueType.label,
            enabled: issueType.enabled ? 'Yes' : 'No',
            details: issueType.description,
            actions: (
              <div className="text-center">
                <ButtonIcon
                  label={'Edit Item'}
                  kind={'bgLightGreen'}
                  iconLeft={true}
                  Icon={PencilSquareIcon}
                  onClick={() => handleEditModal(issueType)}
                />
              </div>
            ),
          };
        }
      );
      setFormattedIssueTypes(formattedIssueTypeArr);
    } else {
      setFormattedIssueTypes([]);
    }
  }, [consumerIssueTypeList?.isSuccess, consumerIssueTypeList?.data]);

  const modalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: 'You are about to delete this issue type. Are you sure you want to continue?',
      primaryButtonText: 'Yes',
      outlineButtonText: 'No, cancel',
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () =>
        console.log('delete --> selectedRowToDelete'),
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  return (
    <div className="flex flex-col">
      <div className="relative flex w-full items-center justify-between py-4">
        <div className="absolute right-5 top-[60px] flex w-full justify-end space-x-4">
          <div className="z-10">
            <ButtonIcon
              label={'New issue type'}
              Icon={PlusIcon}
              iconLeft={true}
              onClick={() => handleEditModal()}
            />
          </div>
        </div>
      </div>
      <Table
        columns={tableheaders}
        data={formattedIssueTypes}
        initialState={{ showGlobalFilter: true }}
        state={{
          isLoading: consumerIssueTypeList.isLoading,
        }}
        muiSearchTextFieldProps={{
          placeholder: 'Search by issue type',
        }}
      />
      {editModalOpen && (
        <div>
          <ModalAddEditItem
            handleClose={() => setEditModalOpen(false)}
            handleDelete={() => setIsConfirmationModalOpen(true)}
            itemDetails={itemDetails}
            kind="issueTypes"
            title={modalMode === 'edit' ? 'Edit issue type' : 'New issue type'}
            mode={modalMode}
          />
        </div>
      )}
      {isConfirmationModalOpen && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={modalSettings.kind}
          settings={modalSettings.settings}
        />
      )}
    </div>
  );
}
