import {
  Modal,
} from '@rabbit/elements/shared-components';
import { useEffect, useMemo, useState } from 'react';
import { useGetWarrantyDetails } from '@rabbit/bizproc/react';
import { PersonaIdTypeSplitter, PersonaTypeSingleLetter, VehicleInfo } from '@rabbit/data/types';
import EditWarrantyPurchaseDetails from '../EditWarrantyPurchaseDetailsForm/EditWarrantyPurchaseDetails';
import EditWarrantyOfferDetails from '../EditWarrantyOfferDetails/EditWarrantyOfferDetails';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

export interface EditWarrantyPWProps {
  warrantyLink: string;
  handleClose: () => void;
};

export function EditWarrantyPW({
  warrantyLink,
  handleClose
}: EditWarrantyPWProps) {
  const { t } = useTranslation();
  const tenantLink = t('tenantLink');
  const warrantyDetails = useGetWarrantyDetails(warrantyLink);
  const { warranty, consumer } = warrantyDetails ?? { warranty: null };
  const [productInfo, setProductInfo] = useState<VehicleInfo | undefined>(null as unknown as VehicleInfo);
  const [step, setStep] = useState(0);

  const partnerRetailerLink = useMemo(() => {
    const repairerPrefix = PersonaTypeSingleLetter.Repairer + PersonaIdTypeSplitter;
    const retailerPrefix = PersonaTypeSingleLetter.Retailer + PersonaIdTypeSplitter;
    return warranty?.retailerLink?.replace(repairerPrefix, '').replace(retailerPrefix, '');
  }, [warranty?.retailerLink]);

  const onNextStep = (values: VehicleInfo) => {
    setProductInfo(values);
  };

  useEffect(() => {
    if(productInfo) setStep(1);
  }, [productInfo]);

  const handlePreviousStep = () => {
    setStep(0);
    setProductInfo(undefined);
  };

  if(partnerRetailerLink === tenantLink) {
    //KEEP for debugging -VP
    console.log('DEBUG: partnerRetailerLink equals tenantLink, so cannot edit warranties');
  };

  return (
    <Modal
      kind="generic"
      settings={{
        title: 'Edit warranty',
        handleClose: handleClose,
        childrenClassName: 'overflow-visible'
      }}
      isLoading={false}
      className="m-auto max-w-[724px] w-full rounded-md border bg-white"
    >
      { tenantLink === partnerRetailerLink ? <div className='p-5'>This user cannot edit warranties.</div> : 
        <div className="p-5">
          <div className="flex gap-1 mb-4 items-center font-caudex text-lg">
            <span className="font-bold cursor-pointer" onClick={handlePreviousStep}>Purchase details</span>
            <span className={step === 0 ? 'opacity-40' : ''}><ChevronRightIcon width={16} height={16} /></span>
            <span className={step === 0 ? 'opacity-40' : 'font-bold'}>Warranty details</span>
          </div>
        {step === 0 && warranty && <EditWarrantyPurchaseDetails handleClose={handleClose} warranty={warranty} nextStep={onNextStep}/> }
        {step === 1 && warranty && productInfo && consumer && <EditWarrantyOfferDetails handleClose={handleClose} warranty={warranty} productInfo={productInfo} consumer={consumer} /> }
        </div>
      }
    </Modal>
  );
}

export default EditWarrantyPW;