import { BL_Warranty } from '@rabbit/bizproc/core';
import {
  DTHoldingProxy,
  DeciderOutputDecided,
  TableCover,
  WarrantyStatus,
} from '@rabbit/data/types';
import {
  CardWrapperWithHeader,
  formatUnixTime,
  getCurrencyFormat,
  getWarrantyTerm,
} from '@rabbit/elements/shared-components';
import {
  formatDate,
  renderWarrantyRemaining,
  useAppInfo,
} from '@rabbit/sage/utils/helpers';
import { OurDateTime } from '@rabbit/utils/ts';
import Skeleton from 'react-loading-skeleton';

export function NuCoverWarrantyDetailsSection({
  allHoldingsList,
  holding_proxy,
  holdingId,
}: {
  allHoldingsList?: DTHoldingProxy[];
  holding_proxy?: DTHoldingProxy;
  holdingId: string;
}) {
  const warrantyInfo = BL_Warranty.getLatestWarranty(holding_proxy?.warranties);
  const decidedOutput: DeciderOutputDecided | undefined =
    warrantyInfo?.decider?.decided;
  const appInfo = useAppInfo();

  const getTermExpired = (expiryDate: number) => {
    return expiryDate < OurDateTime.nowUTCTimestamp() ? 'Yes' : 'No';
  };

  return (
    <>
      <CardWrapperWithHeader title={'Service plan details'}>
        {!allHoldingsList || !holdingId || !holding_proxy ? (
          <div>
            <div className="font-nunito mb-[30px] grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={4} />
              </div>
            </div>
          </div>
        ) : (
          <div className="font-nunito mb-[30px] grid grid-cols-5 gap-8">
            {renderData(
              'ID',
              warrantyInfo?.warrantyLink?.slice(0, 5).toUpperCase()
            )}
            {renderData(
              'Service plan type',
              warrantyInfo?.templateTitle ? warrantyInfo?.templateTitle : '-'
            )}
            {renderData(
              'Area',
              (
                holding_proxy.self_registration?.srvInfo
                  ?.productInfo as TableCover
              )?.surfaceAreaValue + 'm²'
            )}
            {holding_proxy?.register_time &&
              renderData(
                'Registration date',
                formatUnixTime(holding_proxy.register_time, 'dd/MM/yyy')
              )}
            {renderData(
              'Start date',
              warrantyInfo?.startDate
                ? formatDate(new Date(warrantyInfo.startDate))
                : '-'
            )}
            {renderData(
              'Expiry date',
              warrantyInfo?.status === WarrantyStatus.VOIDED
                ? 'Voided'
                : warrantyInfo?.endDate
                ? formatDate(new Date(warrantyInfo.endDate))
                : '-'
            )}
            {renderData(
              'Service plan term',
              getWarrantyTerm(holding_proxy) || '-'
            )}
            {renderData(
              'Term remaining',
              holding_proxy
                ? renderWarrantyRemaining(holding_proxy, false)
                : '-'
            )}
            {renderData(
              'Term expired',
              warrantyInfo?.endDate
                ? getTermExpired(warrantyInfo?.endDate)
                : '-'
            )}
            {renderData('Extended service plan', 'Yes')}
            {renderData('Partner', warrantyInfo?.retailerName || '-')}
            {renderData('Salesperson', warrantyInfo?.salesRep || '-')}
            {renderData(
              'Price',
              decidedOutput
                ? getCurrencyFormat(
                    decidedOutput.warrantyPrice as number,
                    appInfo.currency
                  ) + ' + GST'
                : '-'
            )}
          </div>
        )}
      </CardWrapperWithHeader>
      {decidedOutput?.sealingPrice ? (
        <CardWrapperWithHeader title={'Extra services'}>
          <div className="font-nunito grid grid-cols-5 gap-8">
            {decidedOutput?.sealingPrice &&
              renderData(
                'Sealing price',
                getCurrencyFormat(
                  decidedOutput.sealingPrice as number,
                  appInfo.currency
                ) + ' + GST'
              )}
          </div>
        </CardWrapperWithHeader>
      ) : null}
    </>
  );
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

/* ----------------------------- Render helpers ----------------------------- */

const renderData = (label: string, value: any) => {
  let renderValue = value;
  if (value === 'undefined') renderValue = '-';
  else if (value && value !== 'undefined' && label === 'Phone number')
    renderValue = `+${value}`;
  return (
    <div>
      <div className="mb-[10px] text-sm text-gray-500">{label}</div>
      <div className="overflow-hidden text-ellipsis font-medium">
        {renderValue ?? '-'}{' '}
      </div>
    </div>
  );
};
