import classNames from 'classnames';
import { initPopovers } from 'flowbite';
import { useEffect } from 'react';

export interface PasswordValidationPopoverProps {
  id: string;
  errors: string[];
  extraClasses?: string;
}

const dynamicStyles = {
  wrapperBase:
    'invisible absolute z-10 inline-block w-72 rounded-lg border border-gray-200 bg-white text-sm font-light text-gray-500 opacity-0 shadow-sm transition-opacity duration-300',
  checksPassedNone: 'bg-gray-200',
  checksPassedPartial: 'bg-orange-300',
  checksPassedComplete: 'bg-primary-900',
};

export function PasswordValidationPopover({
  id,
  extraClasses,
  errors,
}: PasswordValidationPopoverProps) {
  const isLongEnough = errors?.indexOf('tooShort') === -1;
  const hasNumber = errors?.indexOf('noNum') === -1;
  const hasUpperAndLower =
    errors?.indexOf('noUpper') === -1 && errors?.indexOf('noLower') === -1;
  const hasErrors = errors?.indexOf('noErr') === -1;
  const hasPass = errors?.indexOf('noPass') === -1;

  const checksPassed = () => {
    let cPassed = 0;
    if (!hasPass) return 0;
    if (!hasErrors) return 3;
    if (isLongEnough) cPassed += 1;
    if (hasNumber) cPassed += 1;
    if (hasUpperAndLower) cPassed += 1;

    return cPassed;
  };

  useEffect(() => {
    initPopovers();
  }, []);

  return (
    <div
      data-popover
      id={id}
      role="tooltip"
      className={`${dynamicStyles.wrapperBase} ${extraClasses}`}
    >
      <div className="space-y-2 p-3">
        <div className="font-nunito text-base font-medium text-gray-900">
          Your password must contain:
        </div>
        <div className="grid grid-cols-3 gap-2">
          <div
            className={classNames('h-1', {
              [`${dynamicStyles.checksPassedNone}`]: checksPassed() === 0,
              [`${dynamicStyles.checksPassedPartial}`]:
                checksPassed() >= 1 && checksPassed() !== 3,
              [`${dynamicStyles.checksPassedComplete}`]: checksPassed() === 3,
            })}
          ></div>
          <div
            className={classNames('h-1', {
              [`${dynamicStyles.checksPassedNone}`]: checksPassed() === 0,
              [`${dynamicStyles.checksPassedPartial}`]:
                checksPassed() >= 2 && checksPassed() !== 3,
              [`${dynamicStyles.checksPassedComplete}`]: checksPassed() === 3,
            })}
          ></div>
          <div
            className={classNames('h-1', {
              [`${dynamicStyles.checksPassedNone}`]: checksPassed() === 0,
              [`${dynamicStyles.checksPassedComplete}`]: checksPassed() === 3,
            })}
          ></div>
        </div>
        <ul>
          <li className="font-nunito mb-1 flex items-center text-sm text-gray-500">
            {hasPass && isLongEnough ? <CheckMark /> : <Cross />} 8 or more
            characters
          </li>
          <li className="font-nunito mb-1 flex items-center text-sm text-gray-500">
            {hasPass && hasUpperAndLower ? <CheckMark /> : <Cross />}
            Upper & lower case letters
          </li>
          <li className="font-nunito flex items-center text-sm text-gray-500">
            {hasPass && hasNumber ? <CheckMark /> : <Cross />}
            Numbers
          </li>
        </ul>
      </div>
      <div data-popper-arrow></div>
    </div>
  );
}

export default PasswordValidationPopover;

// Icons
const CheckMark = () => {
  return (
    <svg
      className="mr-2 h-4 w-4 text-green-400"
      aria-hidden="true"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

const Cross = () => {
  return (
    <svg
      className="mr-2 h-4 w-4 text-gray-300"
      aria-hidden="true"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
