import { Security_FindPeers } from '@rabbit/bizproc/client';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import {
  DTRepairer_Public,
  FBD_Repairer_Public,
  Permissions,
} from '@rabbit/data/types';
import { useEffect, useState } from 'react';

export interface OptionShape {
  id: string;
  value: string;
  label: string;
}

export function useGetRepairerPeers() {
  const { repairerPersona } = useGetMySagePersonas() || {};
  const [repairerPeerLoading, setRepairerPeerLoading] = useState(true);
  const [repairerPeer, setRepairerPeer] = useState<DTRepairer_Public[]>([]);

  useEffect(() => {
    const filterPeers = async (idArr: string[]) => {
      //Kick this off with the user's own id
      const promises: Promise<DTRepairer_Public | null>[] = [
        FBD_Repairer_Public.get(repairerPersona?.personaId ?? ''),
      ];
      idArr.forEach((id) => {
        promises.push(FBD_Repairer_Public.get(id));
      });

      const peerData = await Promise.all(promises);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const environment = import.meta.env.VITE_FIREBASE_MODE;
      const result =
        environment === 'LIVE'
          ? peerData.map((item) => {
              // If email ends in @iwarranty.co or @studiographene.com, don't include it
              if (
                item?.email &&
                !item?.email?.includes('@iwarranty.co') &&
                !item?.email?.includes('@studiographene.com')
              ) {
                return item;
              } else return null;
            })
          : peerData;

      return result;
    };

    if (
      repairerPersona?.personaId &&
      repairerPersona.repairer_private?.principals?.[0]
    ) {
      (async () => {
        const peers = await Security_FindPeers(repairerPersona.personaId, [
          Permissions.CasesEdit,
        ]);

        const filteredPeers = await filterPeers(peers);
        const optionsArr: DTRepairer_Public[] = [];
        filteredPeers.forEach((item) => {
          if (item) optionsArr.push(item);
        });

        setRepairerPeer(optionsArr);
        setRepairerPeerLoading(false);
      })().catch((err) => console.log(err));
    }
  }, [
    repairerPersona?.repairer_private?.principals?.[0],
    repairerPersona?.personaId,
  ]);

  return { repairerPeer, repairerPeerLoading };
}
