import { AppInfoShape } from '@rabbit/data/types';
import { Input } from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { LogForms } from '../ClaimCostModal';
import { OptionShape, useGetRepairerPeers } from '@rabbit/bizproc/react';
import { CaseflowInterface } from '@rabbit/sage/context/CaseflowContext';
import { CF_RepairTimeItem } from 'apps/sage/types';
import { CFCF_RepairTimeLog } from '@rabbit/bizproc/core';

const RepairTimeForm = (
  appInfo: AppInfoShape,
  { caseFacts, alterCaseFacts }: CaseflowInterface,
  repairerUsers: OptionShape[],
  personaId: string,
  option?: { editMode: true; index: number }
): LogForms<CFCF_RepairTimeLog & { team_member_id: string }> => ({
  name: 'repair-time',
  initialValues: {
    rate: { currency: appInfo.currency },
  },
  schema: Yup.object().shape({
    team_member_id: Yup.string().required('Please select a team member').trim(),
    rate: Yup.object().shape({
      amount: Yup.string(),
      currency: Yup.string(),
    }),
    date: Yup.string(),
    time_spent: Yup.string().required('Please choose time'),
    VAT: Yup.string().required('Please enter VAT'),
  }),
  onDelete: async (index, onComplete) => {
    const updatedRegistry = caseFacts?.repair_time_data || [];
    updatedRegistry.splice(index, 1);
    try {
      await alterCaseFacts({ repair_time_data: [...updatedRegistry] });
      toast.success('Record deleted successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    } finally {
      onComplete();
    }
  },
  onSubmit: async (values, onComplete) => {
    const delegate = repairerUsers.find(
      (user) => user.value === values.team_member_id
    );
    const record = values;
    record.team_member = {
      id: delegate?.value,
      name: delegate?.label || '',
    };
    record.date = new Date(values.date || 0).getTime();
    let updatedRegistry;
    if (option?.editMode) {
      updatedRegistry = caseFacts?.repair_time_data || [];
      updatedRegistry[option.index] = record;
    } else {
      updatedRegistry = [...(caseFacts?.repair_time_data || []), record];
    }

    try {
      await alterCaseFacts({
        repair_time_data: [...updatedRegistry],
        ...(values.internal_comment && {
          internal_comment: {
            comment: values.internal_comment,
            author: personaId,
          },
        }),
      });
      toast.success('Claim updated successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    } finally {
      onComplete();
    }
  },
  Form: () => {
    return (
      <>
        <Input
          type="select"
          label="Team member*"
          name="team_member_id"
          settings={{
            id: 'team_member',
            placeholder: 'Please select an option',
            options: repairerUsers,
          }}
        />
        <div className="grid grid-cols-2 gap-4">
          <Input
            type="datepicker"
            label="Date"
            name="date"
            settings={{
              id: 'date',
              placeholder: 'Select a date',
            }}
          />
          <Input
            type="time"
            label="Time*"
            name="time_spent"
            settings={{
              id: 'time',
            }}
          />
          <Input
            type="currency"
            label="Rate"
            name="rate"
            settings={{
              id: 'rate',
              disableCurrency: true,
            }}
          />
          <Input
            type="text"
            name="VAT"
            label="VAT*"
            settings={{
              placeholder: 'VAT',
              append: '%',
            }}
          />
        </div>
      </>
    );
  },
});

export default RepairTimeForm;
