import { useManageSageProfile } from '@rabbit/bizproc/react';
import {
  Input,
  Button,
  Modal,
  asyncValidateSchema,
} from '@rabbit/elements/shared-components';
import { DTRepairer_Public } from '@rabbit/data/types';
import { UserContext } from '@rabbit/sage/context/UserContext';
import { Formik, Form } from 'formik';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

interface FormValuesShape {
  current_password: string;
  new_password: string;
  retype_password: string;
}

const initialValues: FormValuesShape = {
  current_password: '',
  new_password: '',
  retype_password: '',
};

const schema = Yup.object().shape({
  current_password: Yup.string()
    .min(8, 'Password should be 8 or more characters long')
    .matches(/(?=.*[a-z])/, 'Please enter at least 1 lowercase letter')
    .matches(/(?=.*[A-Z])/, 'Please enter at least 1 uppercase letter')
    .matches(/^(?=.*[0-9])/, 'Please enter at least 1 number')
    .required('Please type your current password'),
  new_password: Yup.string()
    .min(8, 'Password should be 8 or more characters long')
    .matches(/(?=.*[a-z])/, 'Please enter at least 1 lowercase letter')
    .matches(/(?=.*[A-Z])/, 'Please enter at least 1 uppercase letter')
    .matches(/^(?=.*[0-9])/, 'Please enter at least 1 number')
    .required('Please enter a new password'),
  retype_password: Yup.string()
    .min(8, 'Password should be 8 or more characters')
    .matches(/(?=.*[a-z])/, 'Please enter at least 1 lowercase letter')
    .matches(/(?=.*[A-Z])/, 'Please enter at least 1 uppercase letter')
    .matches(/^(?=.*[0-9])/, 'Please enter at least 1 number')
    .required('Please confirm your password')
    .oneOf([Yup.ref('new_password')], 'Passwords must match'),
});

export function ChangePasswordModal({
  handleClose,
  user,
}: {
  handleClose: () => void;
  user?: DTRepairer_Public;
}) {
  const contextValues = useContext(UserContext);
  const handleSignOut = contextValues?.handleSignout;
  const { updatePassword } = useManageSageProfile();
  const modalSettings = {
    title: 'Change password',
    handleClose: () => handleClose(),
  };

  const onSubmit = async (values: FormValuesShape) => {
    setLoading(true);
    try {
      await updatePassword(values.current_password, values.new_password);
      toast.success('Password updated successfully.');
      setLoading(false);
      handleClose();
      handleSignOut && handleSignOut();
    } catch (err: any) {
      setLoading(false);
      if (err && err.message && err.message.match('wrong-password')) {
        toast.error('Incorrect password, try again');
      } else toast.error("Couldn't update password.");
    }
  };

  const [loading, setLoading] = useState(false);

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      className="m-auto w-full max-w-[720px] rounded-md border"
      isLoading={loading}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validate={asyncValidateSchema(schema)}
        validateOnBlur
      >
        {({ values, errors }) => {
          return (
            <Form>
              <div className="flex flex-col gap-4 px-5 pt-4">
                <Input
                  type="password"
                  name="current_password"
                  label="Current password*"
                  settings={{
                    placeholder: 'Enter your current password',
                    hint: '*required',
                  }}
                />
                <Input
                  type="password"
                  name="new_password"
                  label="New password*"
                  settings={{
                    popoverTarget: 'popover-password',
                    popoverPlacement: 'bottom',
                    popoverTrigger: 'hover',
                    placeholder: 'Enter your new password',
                    hint: '*required',
                  }}
                />
                <Input
                  type="password"
                  name="retype_password"
                  label="Retype password*"
                  settings={{
                    placeholder: 'Retype your new password',
                    hint: '*required',
                  }}
                />
                <div className="flex gap-4">
                  <Button
                    kind="primary"
                    children="Save"
                    type="submit"
                    onClick={() => 0}
                  />
                  <Button
                    kind="red"
                    children="Cancel"
                    type="button"
                    onClick={() => handleClose()}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
