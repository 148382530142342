import { useState } from 'react';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  Table,
} from '@rabbit/elements/shared-components';
import ModalAddEditItem from '../components/organisms/ModalNewEditProduct/ModalAddEditItem';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { useAppInfo } from '../utils/helpers';

export const faultHeaders = [
  {
    header: 'fault',
    accessorKey: 'fault',
  },
  {
    header: 'fault category',
    accessorKey: 'faultCategory',
  },
  {
    header: 'fault occurences for part',
    accessorKey: 'faultOccurences',
  },
];

export const commonFaultsContent = [
  {
    fault: 'Broken base',
    faultCategory: 'Product defect',
    faultOccurences: '90',
  },
  {
    fault: 'Broken base',
    faultCategory: 'Product defect',
    faultOccurences: '90',
  },
  {
    fault: 'Broken base',
    faultCategory: 'Product defect',
    faultOccurences: '90',
  },
  {
    fault: 'Broken base',
    faultCategory: 'Product defect',
    faultOccurences: '90',
  },
];

export const partsHeaders = [
  {
    accessorKey: 'productImage',
    header: 'Product Image',
  },
  {
    accessorKey: 'productName',
    header: 'product name',
  },
  {
    header: 'model number',
    accessorKey: 'modelNumber',
  },
  {
    header: 'category',
    accessorKey: 'category',
  },
  {
    header: 'user manual',
    accessorKey: 'userManual',
  },
];

export const productsContent = [
  {
    productImage: (
      <img src={'https://picsum.photos/72/63'} alt="productImg"></img>
    ),
    productName: 'Coolum 220cm Square',
    modelNumber: 'ASSDDDDD',
    category: 'Beach Umbrellas',
    userManual: 'Link',
  },
  {
    productImage: (
      <img src={'https://picsum.photos/72/63'} alt="productImg"></img>
    ),
    productName: 'Coolum 220cm Square',
    modelNumber: 'ASSDDDDD',
    category: 'Beach Umbrellas',
    userManual: 'Link',
  },
  {
    productImage: (
      <img src={'https://picsum.photos/72/63'} alt="productImg"></img>
    ),
    productName: 'Coolum 220cm Square',
    modelNumber: 'ASSDDDDD',
    category: 'Beach Umbrellas',
    userManual: 'Link',
  },
  {
    productImage: (
      <img src={'https://picsum.photos/72/63'} alt="productImg"></img>
    ),
    productName: 'Coolum 220cm Square',
    modelNumber: 'ASSDDDDD',
    category: 'Beach Umbrellas',
    userManual: 'Link',
  },
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PartsDetailViewProps {}

export function PartsDetailView(props: PartsDetailViewProps) {
  const appInfo = useAppInfo();
  const [isNewEditModalOpen, setIsNewEditModalOpen] = useState(false);
  const [isNewPartModalOpen, setIsNewPartModalOpen] = useState(false);
  const [isEditFaultModalOpen, setIsEditFaultModalOpen] = useState(false);
  const [isEditPartModalOpen, setIsEditPartModalOpen] = useState(false);
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const business_name = appInfo.name ?? '';

  return (
    <div className="flex flex-col gap-4">
      <CardWrapperWithHeader
        title={'Part information'}
        headerRight={
          <ButtonIcon
            iconLeft={true}
            label={'Edit part'}
            kind={'bgLightGreen'}
            onClick={() => setIsEditProductModalOpen(true)}
            Icon={ArrowTopRightOnSquareIcon}
          />
        }
      >
        {isEditProductModalOpen && (
          <ModalAddEditItem
            handleSubmit={() => void 0}
            handleClose={() => setIsEditProductModalOpen(false)}
            handleDelete={() => console.log('')}
            kind="parts"
            title={'Edit part'}
            itemDetails={{}}
          />
        )}
        <div className="grid grid-cols-4 gap-10">
          <div className="flex flex-col">
            <p className="font-nunito text-sm text-gray-500">Part Number</p>
            <p className="font-nunito mt-2.5 text-base">ASSDDDDD</p>
          </div>
          <div className="flex flex-col">
            <p className="font-nunito text-sm text-gray-500">Brand</p>
            <p className="font-nunito mt-2.5 text-base">{business_name}</p>
          </div>
          <div className="flex flex-col">
            <p className="font-nunito text-sm text-gray-500">
              Product variants
            </p>
            <p className="font-nunito mt-2.5 text-base">4</p>
          </div>
          <div className="flex flex-col">
            <p className="font-nunito text-sm text-gray-500">Price</p>
            <p className="font-nunito mt-2.5 text-base">$120</p>
          </div>
        </div>
      </CardWrapperWithHeader>

      <CardWrapperWithHeader
        noPadding={true}
        title={'Faults'}
        headerRight={
          <div className="flex gap-2.5">
            <div>
              <ButtonIcon
                iconLeft={true}
                label={'Link existing fault to part'}
                kind={'bgLightGreen'}
                onClick={() => setIsEditFaultModalOpen(true)}
                Icon={ArrowTopRightOnSquareIcon}
              />
              {isEditFaultModalOpen && (
                <ModalAddEditItem
                  handleSubmit={() => void 0}
                  handleClose={() => setIsEditFaultModalOpen(false)}
                  handleDelete={() => console.log('')}
                  kind="parts"
                  title={'Edit part'}
                  itemDetails={{}}
                />
              )}
            </div>
            <div>
              <ButtonIcon
                iconLeft={true}
                label={'Add fault'}
                onClick={() => setIsNewEditModalOpen(true)}
              />
              {isNewEditModalOpen && (
                <ModalAddEditItem
                  handleSubmit={() => void 0}
                  handleClose={() => setIsNewEditModalOpen(false)}
                  handleDelete={() => console.log('')}
                  kind="faults"
                  title={'New fault'}
                />
              )}
            </div>
          </div>
        }
      >
        <Table columns={faultHeaders} data={commonFaultsContent} inner={true} />
      </CardWrapperWithHeader>

      <CardWrapperWithHeader
        noPadding={true}
        title={'Product variants'}
        headerRight={
          <div className="flex gap-2.5">
            <div>
              <ButtonIcon
                iconLeft={true}
                label={'Link existing product to part'}
                kind={'bgLightGreen'}
                onClick={() => setIsEditPartModalOpen(true)}
                Icon={ArrowTopRightOnSquareIcon}
              />
              {isEditPartModalOpen && (
                <ModalAddEditItem
                  handleSubmit={() => void 0}
                  handleClose={() => setIsEditPartModalOpen(false)}
                  handleDelete={() => console.log('')}
                  kind="parts"
                  title={'Edit part'}
                  itemDetails={{}}
                />
              )}
            </div>
            <div>
              <ButtonIcon
                iconLeft={true}
                label={'Add product'}
                onClick={() => setIsNewPartModalOpen(true)}
              />
              {isNewPartModalOpen && (
                <ModalAddEditItem
                  handleSubmit={() => void 0}
                  handleClose={() => setIsNewPartModalOpen(false)}
                  handleDelete={() => console.log('')}
                  kind="parts"
                  title={'New part'}
                />
              )}
            </div>
          </div>
        }
      >
        <Table columns={partsHeaders} data={productsContent} inner={true} />
      </CardWrapperWithHeader>
    </div>
  );
}

export default PartsDetailView;
