import { useContext, useState } from 'react';
import { CaseflowContext } from '../../../context/CaseflowContext';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import ContactCustomerModal from '../../organisms/case-flow-modals/ContactCustomerModal/ContactCustomerModal';
import { EnvelopeIcon, PencilIcon } from '@heroicons/react/24/solid';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import ROUTE_NAME from '../../../utils/url-constants';
import { formatAddress } from 'apps/sage/src/utils/helpers';
import Skeleton from 'react-loading-skeleton';
import { Address } from '@rabbit/data/types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ModalNewEditCustomer } from '../../organisms/ModalNewEditCustomer/ModalNewEditCustomer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerDetailsCardProps {}

export function CustomerDetailsCard(props: CustomerDetailsCardProps) {
  const context = useContext(CaseflowContext);
  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const { t } = useTranslation();
  const claimsFlowLite = t('CFG_CLAIMS_FLOW') === 'lite';
  const { setModalSettings, setShowModal, consumerPersonaData } = context || {};

  const modalSettingsOptions = {
    contact_customer_generic: {
      kind: 'generic' as const,
      settings: {
        title: 'Contact customer for more info',
        primaryButtonText: 'Notify customer',
        secondaryButtonText: 'Cancel',
      },
      children: <ContactCustomerModal />,
    },
  };

  const renderDetail = (label: string, value: string, showArrow = false) => {
    return (
      <div className="">
        <p className="text-sm text-gray-500">{label}</p>
        <div className="mt-1 flex gap-2">
          <p className="overflow-hidden text-ellipsis text-base">{value}</p>
          {showArrow && (
            <a
              href={`${ROUTE_NAME.MANAGE}/customer/${consumerPersonaData?.docid}`}
              rel="noreferrer"
            >
              <ArrowTopRightOnSquareIcon className="text-primary-900 h-5 w-5 cursor-pointer" />
            </a>
          )}
        </div>
      </div>
    );
  };

  const renderAddress = () => {
    const address =
      (consumerPersonaData?.address as Address[])?.length > 1
        ? (consumerPersonaData?.address as Address[])?.filter(
            (i) => i.isDefault
          ).length > 0
          ? (consumerPersonaData?.address as Address[])?.filter(
              (i) => i.isDefault
            )
          : consumerPersonaData?.address
        : consumerPersonaData?.address;
    return (
      <div>
        <p className="mt-2.5 text-sm text-gray-500">Address</p>
        <div className="overflow-hidden text-ellipsis text-base">
          {address?.[0] ? formatAddress(address?.[0]) : '-'}
        </div>
      </div>
    );
  };

  return (
    <CardWrapperWithHeader
      title="Customer details"
      headerRight={
        <div className="flex gap-4">
          {claimsFlowLite ? (
            <>
              <ButtonIcon
                type="primary"
                label={``}
                Icon={EnvelopeIcon}
                onClick={() => {
                  if (setModalSettings && setShowModal) {
                    setModalSettings(
                      modalSettingsOptions.contact_customer_generic
                    );
                    setShowModal(true);
                  }
                }}
              />
              <ButtonIcon
                type="secondary"
                kind="bgLightGreen"
                label={``}
                Icon={PencilIcon}
                onClick={() => setEditCustomerModal(true)}
              />
            </>
          ) : (
            <ButtonIcon
              type="primary"
              label={`Contact customer`}
              Icon={EnvelopeIcon}
              iconLeft
              onClick={() => {
                if (setModalSettings && setShowModal) {
                  setModalSettings(
                    modalSettingsOptions.contact_customer_generic
                  );
                  setShowModal(true);
                }
              }}
            />
          )}
        </div>
      }
    >
      {!consumerPersonaData ? (
        <div className="font-nunito flex flex-col gap-3">
          <Skeleton count={2} containerClassName="max-w-[150px]" />
          <Skeleton count={2} containerClassName="max-w-[200px]" />
          <Skeleton count={2} containerClassName="max-w-[170px]" />
          <Skeleton count={4} containerClassName="max-w-[220px]" />
        </div>
      ) : (
        <>
          <div className="font-nunito flex flex-col gap-3">
            {renderDetail(
              'Name',
              consumerPersonaData?.fullname ?? '',
              consumerPersonaData?.fullname ? true : false
            )}
            {renderDetail('Email', consumerPersonaData?.email ?? '-')}
            {renderDetail(
              'Phone number',
              consumerPersonaData?.phone
                ? `+ ${consumerPersonaData?.phone}`
                : '-'
            )}
            {renderAddress()}
          </div>
          {editCustomerModal && (
            <div>
              <ModalNewEditCustomer
                handleClose={() => setEditCustomerModal(false)}
                handleDelete={() => {}}
                customerDetails={consumerPersonaData}
                modalSettings={{
                  title: 'Edit customer',
                  handleClose: () => setEditCustomerModal(false),
                }}
              />
            </div>
          )}
        </>
      )}
    </CardWrapperWithHeader>
  );
}

export default CustomerDetailsCard;
